<template>
  <div>
    <p class="recipe-form__subtitle">
      {{ capitalize($i18n.t('elements.category.text.choose')) }}
    </p>
    <SemanticSelect
       v-model="recipeCategories"
       :options="categories"
       :placeholder="$i18n.t('elements.category.text.index')"
       :multiple="true"
       :disabled="locked"
       select-class="ui multiple search dropdown pointing top left custom-dropdown recipe-form__dropdown recipe-from__dropdown--categories recipe-form__dropdown--big selection"
       />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { inflection, api } from './mixins'
import SemanticSelect from './SemanticSelect.vue'

export default{
  mixins: [inflection, api],
  components: { SemanticSelect },
  data () {
    return {
      recipeCategories: null,
      locked: false
    }
  },
  watch: {
    recipeCategories: {
      handler: 'updateCategories',
      deep: true
    }
  },
  computed: {
    ...mapGetters(['recipeData', 'categories'])
  },
  methods: {
    updateCategories (newVal, oldVal) {
      if (!oldVal) { return }

      const updateParams = {
        association: {
          model_name: 'Category',
          ids_list: newVal
        }
      }

      this.locked = true
      this.sendRequest('put', `${this.recipePath}/update_association`, updateParams)
      .then( () => this.locked = false )
      .catch( () => this.locked = false )
    }
  },
  created () {
    this.recipeCategories = (this.recipeData.categories || []).map( (c) => c.id )
  }
}
</script>

<style lang="stylus" scoped>
</style>
