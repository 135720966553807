<template>
  <input
    type="text"
    placeholder="Rechercher une recette"
    class="prompt general navigation"
    v-model="model"
  />
</template>

<script>
import { debounce } from 'lodash'

export default {
  props: {
    delay: {
      type: Number,
      default: 500,
    },
    value: {},
  },
  data() {
    return {
      model: this.value,
      unwatch: undefined,
    }
  },
  mounted() {
    this.watch()
  },
  watch: {
    delay() {
      this.watch()
    },
    value(value) {
      this.model = value
    },
  },
  methods: {
    watch() {
      if (this.unwatch) this.unwatch()

      this.unwatch = this.$watch(
        'model',
        debounce((v) => this.$emit('input', v), this.delay)
      )
    },
  },
}
</script>

<style lang="scss" scoped></style>
