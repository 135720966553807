import Vue from 'vue'

// Shared I18n from rails app
Vue.prototype.$i18n = window.I18n
// Support for semantic-ui components
import SuiVue from 'semantic-ui-vue'
Vue.use(SuiVue)
// Setup for ajax requests
import axios from 'axios'
import VueAxios from 'vue-axios'

const csrfToken = document.querySelector('meta[name=csrf-token]').content

axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
axios.defaults.headers.common['Accept'] = 'application/json'
Vue.use(VueAxios, axios)

// WeeklyMenu
import WeeklyMenuModal from './WeeklyMenuModal'

$(document).ready(() => {
  const el = $('#weekly-menu-modal')[0]

  if (el) {
    let weeklyMenuModal = new Vue({
      el,
      render: (h) => h(WeeklyMenuModal),
    })

    window.weeklyMenuModal = weeklyMenuModal

    // Trigger weeklyMenuModal
    $('.js-weekly-menu-modal-trigger').on('click', (e) => {
      const recipeData = $(e.currentTarget).data('recipe')

      let $modal = window.weeklyMenuModal.$children[0]

      $modal.recipe = recipeData
      $modal.open = true
    })
  }
})
