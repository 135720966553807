<template>
  <div class="shopping-list" :class="{ loading }">
    <div class="list-actions">
      <div class="action-btns">
        <a
          :href="printUrl"
          target="_blank"
          class="ui button custom-button custom-button--filaire round-icon mr-1"
          :disabled="loading"
        >
          <i class="icon gd-print" />
        </a>
        <a
          href="javascript:void(0)"
          class="ui button custom-button custom-button--filaire round-icon"
          :disabled="loading"
          @click="sendModal = true"
        >
          <i class="icon gd-send" style="margin-left: -2px !important" />
        </a>
      </div>
      <button
        @click="removeList"
        :disabled="loading"
        class="ui button custom-button custom-button--filaire"
        :class="{ loading }"
      >
        <i class="trash alternate outline icon"></i>
        Supprimer
      </button>
    </div>
    <div class="list-title">
      <input
        v-model.lazy="data.title"
        :disabled="loading"
        type="text"
        class="custom-title custom-title--h2"
        placeholder="Nouvelle liste (supermarché, traiteur, etc.)"
      />
    </div>
    <div class="list-content">
      <draggable
        :list="data.shopping_list_categories"
        :group="`${list.id}-categories`"
        :delay="200"
        :delay-on-touch-only="true"
        class="ui two column stackable grid doubling-xs"
      >
        <div
          class="list-category column"
          v-for="category in data.shopping_list_categories"
          :key="category.id"
        >
          <div class="list-category-title">
            <input
              type="text"
              v-model.lazy="category.title"
              placeholder="Rayon"
              :class="{
                striked:
                  category.shopping_list_items.length &&
                  !category.shopping_list_items.filter((i) => {
                    return !i.checked
                  }).length,
              }"
            />
            <i class="gd-move icon" />
          </div>
          <div class="list-category-items">
            <draggable
              :list="category.shopping_list_items"
              :group="`${list.id}-items`"
              :delay="200"
              :delay-on-touch-only="true"
            >
              <div
                class="list-category-item"
                v-for="item in category.shopping_list_items"
                :key="item.id"
              >
                <div class="ui checkbox">
                  <input type="checkbox" v-model="item.checked" />
                  <label />
                </div>
                <input
                  v-model.lazy="item.description"
                  type="text"
                  class="item-input"
                  :class="{ striked: item.checked }"
                  placeholder="Ingrédient"
                  @focus="focused = true"
                  @blur="focused = false"
                />
                <i class="fa fa-times" @click="$set(item, '_destroy', true)" />
                <i class="gd-move icon" />
              </div>
            </draggable>
          </div>
          <div class="list-category-footer">
            <form @submit.prevent="addItem(category.id)" class="item-add">
              <button
                type="submit"
                class="custom-popup__title primary-color bold"
              >
                <i class="gdm-nav-ma-cuisine icon" />
              </button>
              <input
                v-model="newItems[category.id]"
                type="text"
                class="item-input"
                placeholder="Ingrédient"
                required
              />
              <button
                type="submit"
                class="custom-popup__title primary-color bold"
              >
                Ajouter
              </button>
            </form>
          </div>
        </div>
      </draggable>
    </div>
    <div class="list-footer">
      <form @submit.prevent="addCategory" class="item-add">
        <button type="submit" class="custom-popup__title primary-color bold">
          <i class="gdm-nav-ma-cuisine icon" />
        </button>
        <input
          v-model="newCategory"
          type="text"
          class="item-input"
          placeholder="Ajoutez un rayon"
          required
        />
        <button type="submit" class="custom-popup__title primary-color bold">
          Ajouter
        </button>
      </form>
    </div>
    <sui-modal v-model="sendModal">
      <sui-modal-header class="d-flex justify-between no-border transparent">
        <div>Envoyer la recette par email</div>
        <i class="icon close" @click="closeSendModal" />
      </sui-modal-header>
      <sui-modal-content>
        <sui-modal-description>
          <form @submit.prevent="sendByEmail" class="ui form" ref="emailForm">
            <div class="ui field" :class="{ error: emailError }">
              <label class="label">Email</label>
              <input
                v-model="emailField"
                type="email"
                placeholder="Entrez une adresse email"
                required
              />
            </div>
          </form>
        </sui-modal-description>
      </sui-modal-content>
      <sui-modal-actions class="no-border transparent">
        <div
          @click="closeSendModal"
          class="ui deny button custom-button custom-button--filaire"
        >
          Annuler
        </div>
        <sui-button
          class="ui positive button custom-button"
          :disabled="!emailField || emailError"
          @click="sendByEmail"
        >
          Envoyer
        </sui-button>
      </sui-modal-actions>
    </sui-modal>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
const EMAIL_REGEX = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

export default {
  components: {
    draggable,
  },
  props: {
    list: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data: { ...this.list },
      loading: false,
      newItems: {},
      newCategory: '',
      sendModal: false,
      emailField: '',
    }
  },
  computed: {
    emailError() {
      return this.emailField && !EMAIL_REGEX.test(this.emailField)
    },
    printUrl() {
      return `/shopping_lists/${this.list.uuid}/print_pdf`
    },
    sendUrl() {
      return `/shopping_lists/${this.list.uuid}/send_by_email`
    },
    apiUrl() {
      return `/shopping_lists/${this.list.id}`
    },
    _title() {
      return this.data.title
    },
    _categories() {
      return this.data.shopping_list_categories
    },
  },
  methods: {
    closeSendModal() {
      this.emailField = ''
      this.sendModal = false
    },
    async sendByEmail() {
      if (this.emailField && !this.emailError) {
        await this.$http.post(this.sendUrl, { email: this.emailField })

        window.globalFlashMessage(
          'success',
          `La liste a été envoyée à ${this.emailField}.`
        )
        this.closeSendModal()
      }
    },
    removeList() {
      this.loading = true
      this.$http.delete(this.apiUrl).then((_response) => {
        this.$emit('delete', this.list.id)
        this.loading = false
      })
    },
    addCategory() {
      if (!this.newCategory) {
        return
      }

      this.loading = true
      this.$http
        .post(`${this.apiUrl}/add_category`, {
          category: {
            title: this.newCategory,
          },
        })
        .then((response) => {
          this.data = { ...response.data }
          this.newCategory = ''
          window.globalFlashMessage('success', 'Modification enregistrée.')
          this.$nextTick(() => {
            this.loading = false
          })
        })
        .catch((error) => {
          this.loading = false
          window.globalFlashMessage('error', "Une erreur s'est produite.")
        })
    },
    addItem(categoryId) {
      if (!this.newItems[categoryId]) {
        return
      }

      this.loading = true
      this.$http
        .post(`${this.apiUrl}/add_item`, {
          item: {
            description: this.newItems[categoryId],
            shopping_list_category_id: categoryId,
          },
        })
        .then((response) => {
          this.data = { ...response.data }
          this.newItems[categoryId] = ''
          window.globalFlashMessage('success', 'Modification enregistrée.')
          this.$nextTick(() => {
            this.loading = false
          })
        })
        .catch((error) => {
          this.loading = false
          window.globalFlashMessage('error', "Une erreur s'est produite.")
        })
    },
    updateContent() {
      if (!this.loading) {
        this.loading = true
        this.$http
          .put(`${this.apiUrl}/update_content`, {
            shopping_list_content: {
              shopping_list_categories: this._categories,
            },
          })
          .then((response) => {
            this.data = { ...response.data }
            window.globalFlashMessage('success', 'Modification enregistrée.')
            this.$nextTick(() => {
              this.loading = false
            })
          })
          .catch((error) => {
            this.loading = false
            window.globalFlashMessage('error', "Une erreur s'est produite.")
          })
      }
    },
  },
  watch: {
    _categories: {
      handler: 'updateContent',
      deep: true,
    },
    _title(newValue, oldValue) {
      this.loading = true
      this.$http
        .put(this.apiUrl, { shopping_list: { title: newValue } })
        .then(() => (this.loading = false))
        .catch(() => {
          this.loading = false
          this.data.title = oldValue
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.mr-1 {
  margin-right: 10px !important;
}
.shopping-list {
  border-radius: 4px;
  box-shadow: 0 2px 44px 0 rgb(0 0 0 / 16%);
  background-color: white;
  padding: 2rem;
  margin-bottom: 20px;
  &.loading {
    opacity: 0.5;
  }
  .list-actions {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    .round-icon {
      margin: 0;
      padding: 4px 0 !important;
      width: 34px;
      height: 34px;
      border-radius: 50%;
      vertical-align: bottom;
      font-size: 26px;
      .icon {
        margin: 0 !important;
      }
    }
  }
  .list-title {
    input {
      width: 100%;
      padding: 5px;
      border: none;
      border-bottom: 2px solid #e83b47;
      font-size: 1.6rem;
      &:focus {
        outline: none;
        border-bottom: 2px dotted #e83b47;
      }
    }
  }
  .list-content {
    .list-category {
      .gd-move {
        cursor: pointer;
        color: #e83b47;
        margin-left: 15px;
      }
      .list-category-title {
        display: flex;
        align-items: center;
        input {
          width: 100%;
          font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
          text-transform: uppercase;
          color: #e83b47;
          font-weight: bold;
          padding: 5px;
          margin-top: 10px;
          margin-bottom: 10px;
          border: none;
          &.striked {
            text-decoration: line-through;
          }
          &:focus {
            text-decoration: none;
            outline: none;
            border-bottom: 1px dotted #e83b47;
          }
        }
      }
    }
    .list-category-items {
      .list-category-item {
        margin-bottom: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .checkbox {
          margin-left: 5px;
        }
        .item-input {
          color: #262626;
          width: 100%;
          border: none;
          border-bottom: 1px dashed gray;
          font-style: italic;
          font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
          padding: 5px;
          margin-right: 15px;
          margin-left: 5px;
          &.striked {
            text-decoration: line-through;
          }
          &:focus {
            text-decoration: none;
            border: none;
            outline: none;
            border-bottom: 1px dashed #e83b47;
          }
        }
        i {
          color: #6e6e6e;
          cursor: pointer;
        }
      }
    }
  }
  .list-footer,
  .list-category-footer {
    margin-top: 40px;
    .item-add {
      display: flex;
      justify-content: space-between;
      align-items: center;
      button {
        border: none;
        background: white;
        &:focus {
          border: none;
          outline: none;
          border-bottom: 1px dashed #e83b47;
        }
      }
      .custom-popup__title {
        font-size: 1.4rem;
        cursor: pointer;
      }
      .item-input {
        font-size: 1.3rem;
        color: #262626;
        width: 100%;
        border: none;
        border-bottom: 1px dashed gray;
        font-style: italic;
        font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
        padding: 5px;
        margin-right: 15px;
        margin-left: 5px;
        &:focus {
          border: none;
          outline: none;
          border-bottom: 1px dashed #e83b47;
        }
      }
    }
  }
  .list-category-footer {
    margin-top: 10px;
  }
}
</style>
