<template>
  <div id="headlines" class="tab-pane">
    <div class="panel panel-default">
      <div class="panel-heading">
        <div class="heading">
          <h4 class="box-title">
            À la une en ce moment
            <i v-if="loading" class="fa fa-fw fa-spinner fa-spin"></i>
          </h4>
          <button
            :disabled="!additionEnabled"
            @click.prevent="addHeadline"
            class="btn btn-sm btn-success"
          >
            <i class="fa fa-fw fa-plus-square"></i> Ajouter
          </button>
        </div>
      </div>
      <div class="panel-body">
        <draggable
          v-model="headlines"
          @change="updateList"
          :disabled="!additionEnabled"
        >
          <headline-field
            v-for="headline in headlines"
            :key="headline.id || headline.tmpId"
            :headline="headline"
            :page-id="page.id"
            @removeHeadline="handleRemove"
            @created="saveHeadline"
          />
        </draggable>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import HeadlineField from './HeadlineField.vue'

export default {
  components: { draggable, HeadlineField },
  props: {
    page: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      loading: false,
      headlines: [],
    }
  },
  computed: {
    additionEnabled() {
      if (this.loading) {
        return false
      }

      const tmpHeadlines = this.headlines.filter((headline) => {
        return !!headline.tmpId
      })

      return !tmpHeadlines.length
    },
    orderedList() {
      return this.headlines.map((headline, index) => {
        return { ...headline, order: this.headlines.length - index }
      })
    },
  },
  methods: {
    saveHeadline(record) {
      const index = this.headlines.findIndex((headline) => {
        return headline.tmpId === record.tmpId
      })

      this.$set(this.headlines, index, { ...record, tmpId: null })
    },
    addHeadline() {
      if (!this.additionEnabled) {
        return
      }
      this.headlines.unshift({
        id: null,
        url: '',
        content: '',
        image: null,
        tmpId: [...Array(16)].map(() => Math.random().toString(36)[2]).join(''),
      })
    },
    handleRemove(removedHeadline) {
      let newHeadlines = this.headlines.filter((headline) => {
        if (removedHeadline.id) {
          return headline.id != removedHeadline.id
        } else {
          return headline.tmpId != removedHeadline.tmpId
        }
      })

      this.headlines = newHeadlines
    },
    updateList() {
      this.loading = true
      this.$http
        .put(`/back/club_home_pages/${this.page.id}/headlines`, { headlines: this.orderedList })
        .then((response) => {
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
        })
    },
  },
  created() {
    this.loading = true
    this.$http
      .get(`/back/club_home_pages/${this.page.id}/headlines`)
      .then((response) => {
        this.headlines = response.data
        this.loading = false
      })
      .catch((error) => {
        this.loading = false
      })
  },
}
</script>

<style lang="stylus" scoped>
.heading
  display: flex
  justify-content: space-between
  align-items: center
  width: 100%
</style>
