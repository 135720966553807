<template>
  <div id="more_info" class="tab-pane">
    <div class="panel panel-default">
      <div class="panel-heading">
        <h4 class="box-title">
          En savoir plus
        </h4>
      </div>
      <div class="panel-body">
        <info-field
          v-for="(info, index) of data"
          :key="index"
          :info="info"
          :index="index"
          :page-id="page.id"
          @update="update"
        />
      </div>
    </div>
  </div>
</template>

<script>
import InfoField from './Info'

export default {
  components: {
    InfoField,
  },
  props: {
    page: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data: [...this.page.more_information],
    }
  },
  methods: {
    update(info) {
      this.data = [...info]
    },
  },
}
</script>

<style lang="stylus" scoped></style>
