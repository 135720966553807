const RECIPE = {
  bake_time: null,
  cold_time: null,
  difficulty: null,
  freezing_time: null,
  is_drafted: true,
  is_icookable: false,
  is_private: false,
  people: null,
  prepare_time: null,
  price: null,
  sleep_time: null,
  slug: null,
  title: null,
}

export default {
  RECIPE
}
