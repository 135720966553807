<template>
  <div class="ui container m-b-1">
    <div class="page-header m-b-3">
      <h1 class="custom-title custom-title--h1 primary-color">
        <i class="gd-shopping-cart gdm-icon icon" />
        <br />
        Mes listes de courses
      </h1>
      <button
        @click="addList"
        class="ui button custom-button custom-button--icon m-t-1"
      >
        <i class="gdm-nav-ma-cuisine icon" />
        Créer une liste de courses
      </button>
    </div>
    <div v-if="shoppingLists.length" class="shopping-lists">
      <div
        class="column element-item transition metal shopping-list-item"
        v-for="list in shoppingLists"
        :key="list.id"
      >
        <shopping-list :list="list" @delete="removeList" />
      </div>
    </div>
    <div v-else>
      <h3 class="text-center custom-text">Il n'y a aucune liste à afficher</h3>
    </div>
  </div>
</template>

<script>
import ShoppingList from './ShoppingList'

export default {
  components: {
    ShoppingList,
  },
  data() {
    return {
      shoppingLists: $('#shopping-lists-data').data('lists'),
    }
  },
  methods: {
    addList() {
      this.$http
        .post('/shopping_lists', { shopping_list: { title: '' } })
        .then((response) => {
          this.shoppingLists = [response.data, ...this.shoppingLists]
        })
    },
    removeList(listId) {
      this.shoppingLists = this.shoppingLists.filter(
        (list) => list.id != listId
      )
    },
  },
}
</script>

<style lang="scss">
input:checked ~ label {
  &:after {
    color: #e83b47 !important;
  }
}
</style>
