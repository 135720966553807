<template>
  <div class="steps">
    <Step
       v-for="step in group.steps"
       :key="step.id"
       :group="group"
       :step="step"
    />
    <div class="links">
      <div class="steps-bottom">
        <button
          @click.prevent="addStep"
          :class="{ loading: loadingNewStep }"
          class="ui button custom-button custom-button--icon m-r-0"
        >
          <i class="gdm-nav-ma-cuisine icon"></i>
          {{ capitalize($i18n.t('elements.recipe.buttons.add_step')) }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { inflection, api } from './mixins'
import Step from './Step.vue'

export default{
  props: ['group'],
  mixins: [inflection, api],
  components: { Step },
  data () {
    return {
      loadingNewStep: false
    }
  },
  methods: {
    addStep () {
      const count = this.group.steps.length + 1

      const stepName = this.$i18n.t('elements.step.text.drafted_step', {
        number: count
      })

      const stepParams = { step: { description: stepName } }

      this.loadingNewStep = true
      this.sendRequest('post', `${this.recipePath}/groups/${this.group.id}/steps`, stepParams)
      .then( (response) => {
        this.$store.dispatch('updateRecipe', response.data)
        this.loadingNewStep = false
      })
      .catch( () => this.loadingNewStep = false )
    }
  }
}
</script>

<style lang="stylus" scoped>
</style>
