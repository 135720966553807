<template>
  <div class="section-white">
    <h2 class="profile_top-title">
      <i class="gd-ohra" />
      {{ $i18n.t('elements.user.text.ohra_mold') }}
    </h2>

    <p class="little-sub-text">
      {{ $i18n.t('elements.user.text.ohra_text') }}
    </p>

    <div class="field img-mold">
      <checkbox
        :label="$i18n.t('elements.user.text.owning_mold')"
        :disabled="saving"
        v-model="owning"
      />
    </div>
  </div>
</template>

<script>
  import Checkbox from './Checkbox'

  export default {
    components: {
      Checkbox,
    },
    props: {
      owningMold: {
        type: Boolean,
        required: true
      },
      slug: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        owning: this.owningMold,
        saving: false
      }
    },
    watch: {
      owning: {
        handler: 'save'
      }
    },
    methods: {
      save() {
        this.saving = true

        this.$http.put(`/profil/${this.slug}`, { user: { owning_mold: this.owning }})
        .then( () => {
          this.saving = false
          window.globalFlashMessage("success", "Modifications enregistrées")
        })
        .catch( () => {
          this.saving = false
          window.globalFlashMessage("error", "Impossible d'enregistrer les modifications")
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
</style>