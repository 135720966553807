<template>
  <input
    type="text"
    class="form-control"
    :id="'datePicker-' + _uid"
    :value="value"
    @change="manualInput"
  />
</template>

<script>
export default {
  props: ['value'],
  methods: {
    manualInput(e) {
      this.$emit('input', e.target.value)
    }
  },
  mounted() {
    $(`#datePicker-${this._uid}`).datepicker({
      firstDay: 1,
      onSelect: date => {
        this.$emit('input', date)
      }
    })
  }
}
</script>
