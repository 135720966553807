<template>
  <v-select
    v-model="selectedThematic"
    label="label"
    :options="thematics"
    :filterable="false"
    :placeholder="placeholder"
    @search="onSearch"
  >
    <template slot="option" slot-scope="option">
      <div class="d-center">
        <img :src="option.image.url" height="40px" />
        {{ option.label }}
      </div>
    </template>
    <template slot="selected-option" slot-scope="option">
      <div class="selected d-center">
        <img :src="option.image.url" height="15px" />
        {{ option.label }}
      </div>
    </template>
  </v-select>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: () => {
        return null
      },
    },
    placeholder: {
      type: String,
      default: 'Rechercher une thématique',
    },
  },
  data() {
    return {
      selectedThematic: this.value,
      thematics: [],
    }
  },
  watch: {
    selectedThematic(newValue, oldValue) {
      this.$emit('input', newValue)
    },
    value(newValue) {
      this.selectedThematic = newValue
    },
  },
  methods: {
    onSearch(search, loading) {
      loading(true)
      this.search(loading, search, this)
    },
    search: _.debounce((loading, search, vm) => {
      vm.$http
        .get('/back/thematics/select', {
          params: {
            search_term: search,
          },
        })
        .then(({ data }) => {
          vm.thematics = data
          loading(false)
        })
    }, 350),
    truncate: _.truncate,
  },
}
</script>

<style lang="scss" scoped></style>
