<template>
  <div id="hints_n_news" class="tab-pane">
    <div class="panel panel-default">
      <div class="panel-heading">
        <h4 class="box-title">
          Bons Plans et Actualités
        </h4>
      </div>
      <div class="panel-body">
        <hint
          v-for="(hint, index) of data"
          :key="index"
          :hint="hint"
          :pageId="page.id"
          :index="index"
          @update="update"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Hint from './Hint'

export default {
  components: {
    Hint,
  },
  props: {
    page: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data: [...this.page.hints_n_news],
    }
  },
  methods: {
    update(hints) {
      this.data = [...hints]
    },
  },
}
</script>

<style lang="stylus" scoped></style>
