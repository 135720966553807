import Vue from 'vue'

// Setup for ajax requests
import axios from 'axios'
import VueAxios from 'vue-axios'

const csrfToken = document.querySelector('meta[name=csrf-token]').content

axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'
Vue.use(VueAxios, axios)

import DashboardStats from './DashboardStats'

$(document).ready(() => {
  const el = $('#dashboard-stats')[0]

  if (el) {
    var dashboardStats = new Vue({
      el,
      render: (h) => h(DashboardStats),
    })

    window.dashboardStats = dashboardStats
  }
})
