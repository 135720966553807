<template>
  <div class="col-md-12">
    <posts-stats />
    <div class="panel panel-default">
      <div class="panel-heading heading">
        <h4 class="box-title">
          Posts GDM
          <i
            v-if="loading"
            class="fa fa-fw fa-spinner fa-spin"
          ></i>
        </h4>
        <button
          :disabled="additionDisabled"
          @click.prevent="addPost"
          class="btn btn-sm btn-success"
        >
          <i class="fa fa-fw fa-plus-square"></i> Ajouter
        </button>
      </div>
      <div class="panel-body">
        <div class="row">
          <div class="col-md-3">
            <v-select
              v-model="selectedFilter"
              :clearable="false"
              :options="[
                { label: 'Tous', value: '' },
                { label: 'Actifs', value: 'active' },
                { label: 'Inactifs', value: 'inactive' },
              ]"
            />
          </div>

          <div class="col-md-9 text-right">
            <pagination
              v-if="pagination.total_pages > 1"
              :current-page="pagination.current_page"
              :total-pages="pagination.total_pages"
              @goToPage="goToPage"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <draggable
              v-model="posts"
              @change="updateList"
              :disabled="additionDisabled"
            >
              <post
                v-for="post of posts"
                :key="post.id || post.tmpId"
                :post="post"
                @remove="removePost"
                @created="savePost"
              />
            </draggable>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="text-right">
              <pagination
                v-if="pagination.total_pages > 1"
                :current-page="pagination.current_page"
                :total-pages="pagination.total_pages"
                @goToPage="goToPage"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import Pagination from '@back/components/Pagination.vue'
import Post from './Post.vue'
import PostsStats from './PostsStats.vue'

const FILTER_OPTIONS = [
  { label: 'Tous', value: null },
  { label: 'Actifs', value: 'active' },
  { label: 'Inactifs', value: 'inactive' },
]
export default {
  components: {
    draggable,
    Post,
    Pagination,
    PostsStats,
  },
  data() {
    return {
      posts: $('#gdm-posts-data').data('records'),
      loading: false,
      pagination: $('#gdm-posts-data').data('meta').pagination,
      selectedFilter: FILTER_OPTIONS[0],
    }
  },
  computed: {
    filter() {
      return this.selectedFilter.value
    },
    additionDisabled() {
      const tmpPosts = this.posts.filter((post) => {
        return !!post.tmpId
      })

      return this.loading || tmpPosts.length > 0
    },
    orderedList() {
      return this.posts.map((post, index) => {
        return { ...post, order: this.posts.length - index }
      })
    },
  },
  watch: {
    filter() {
      if (this.currentPage > 1) {
        this.currentPage = 1
      } else {
        this.goToPage(1)
      }
    },
  },
  methods: {
    goToPage(page) {
      this.loading = true
      this.$http
        .get(`/back/gdm_posts?page=${page}&filter=${this.filter}`)
        .then(({ data }) => {
          this.posts = data.posts
          this.pagination = data.meta.pagination
          this.loading = false
        })
    },
    updateList() {
      this.loading = true
      this.$http
        .put('/back/gdm_posts', {
          gdm_posts: this.orderedList,
          page: this.currentPage,
          filter: this.filter,
        })
        .then(({ data }) => {
          this.posts = data.posts
          this.pagination = data.meta.pagination
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
        })
    },
    savePost(record) {
      const index = this.posts.findIndex((post) => {
        return post.tmpId === record.tmpId
      })

      this.updateList()
    },
    addPost() {
      if (this.additionDisabled) {
        return
      }

      this.posts.unshift({
        id: null,
        url: '',
        content: '',
        image: { url: '' },
        display_date: moment().format('DD-MM-YYYY'),
        expire_date: null,
        user_id: null,
        likers_count: { advisors: 0, members: 0, orthers: 0},
        tmpId: [...Array(16)].map(() => Math.random().toString(36)[2]).join(''),
      })
    },
    removePost(removedPost) {
      let newPosts = this.posts.filter((post) => {
        if (removedPost.id) {
          return post.id != removedPost.id
        } else {
          return post.tmpId != removedPost.tmpId
        }
      })

      this.posts = newPosts
    },
  },
}
</script>

<style lang="stylus" scoped>
.heading
  display: flex
  justify-content: space-between
  align-items: center
  width: 100%
.row
  margin-bottom: 10px
</style>
