<template>
  <div
    class="five wide center aligned column stretched recipe-form__upload-wrap"
  >
    <div
      class="ui segment no-padding no-margin recipe-form__segment recipe-form__upload"
    >
      <img
        v-if="displayUrl"
        :src="displayUrl"
        class="ui image fluid js-containerimgUpload"
      />
      <img
        v-else
        :src="require('images/recipe-form-placeholder.webp')"
        class="ui image no-margin js-containerimgUpload"
      />
      <div
        class="upload-wrap"
        @mouseenter="hovered = true"
        @mouseleave="hovered = false"
        @click="launchImageCropper"
      >
        <div v-show="hovered || loading" class="upload-wrap__content">
          <label v-if="loading" class="ui pointer upload-button">
            <sui-loader indeterminate active>
              {{ $i18n.t('elements.recipe.text.image_loading') }}
            </sui-loader>
          </label>
          <label v-else-if="hovered" class="ui pointer upload-button">
            <img
              :src="require('images/club/camera.webp')"
              class="ui image icon-camera"
            />
            {{ capitalize($i18n.t('elements.recipe.buttons.photo')) }}
          </label>
        </div>
        <div class="imgUpload" />
      </div>
    </div>
    <div class="image-copyright text-left">
      {{ capitalize($i18n.t('elements.recipe.text.image_right')) }}
      <div
        @click="$emit('displayImageModal')"
        class="text-link add_image custom-link"
      >
        {{ capitalize($i18n.t('elements.global.buttons.click_here')) }}
      </div>
    </div>
  </div>
</template>

<script>
import { inflection, api } from './mixins'
import { mapGetters } from 'vuex'

export default {
  mixins: [inflection, api],
  data() {
    return {
      displayUrl: null,
      hovered: false,
      loading: false,
    }
  },
  computed: {
    ...mapGetters(['imageDisplayUrl'])
  },
  watch: {
    imageDisplayUrl() {
      if (this.imageDisplayUrl) {
        this.displayUrl = this.imageDisplayUrl
      } else {
        this.displayUrl = $('#recipe-form').data('recipe').image.url
      }
    }
  },
  methods: {
    launchImageCropper() {
      this.$store.dispatch('openImageCropperModal')
    },
  },
  created() {
    this.displayUrl = $('#recipe-form').data('recipe').image.url
  },
}
</script>

<style lang="stylus" scoped>
.js-containerimgUpload
  object-fit: cover
  height: 100% !important
.upload-wrap__content
  background-color: rgba(0,0,0,.5)
  padding: 80px 0px 80px 0px
  width: 90%
</style>
