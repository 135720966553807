import { render, staticRenderFns } from "./IcookinFields.vue?vue&type=template&id=2554ee55&scoped=true"
import script from "./IcookinFields.vue?vue&type=script&lang=js"
export * from "./IcookinFields.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2554ee55",
  null
  
)

export default component.exports