<template>
  <div class="ui fields inline m-b-0">
    <div class="ui field">
      <sui-dropdown
        search
        direction="downward"
        :options="rangeOptions(73)"
        v-model="time.hours"
        class="ui fluid dropdown search dropdown-time custom-dropdown recipe-form__dropdown recipe-form__dropdown--small selection hours-dropdown"
      />
    </div>
    <label class="prepare-label">h</label>
    <div class="ui field">
      <sui-dropdown
        search
        direction="downward"
        :options="rangeOptions(59)"
        v-model="time.minutes"
        class="ui fluid dropdown search dropdown-time custom-dropdown recipe-form__dropdown recipe-form__dropdown--small selection minutes-dropdown"
      />
    </div>
    <label class="prepare-label">min</label>
  </div>
</template>

<script>
export default{
  props: ['value'],
  data () {
    return {
      time: {
        hours: Math.trunc(this.value / 60),
        minutes: this.value % 60
      }
    }
  },
  watch: {
    time: {
      handler: 'updateValue',
      deep: true
    }
  },
  methods: {
    updateValue (newVal, oldVal) {
      let valueInMinutes = (this.time.hours * 60) + this.time.minutes

      if (valueInMinutes != this.value) {
        this.$emit('input', valueInMinutes)
      }
    },
    rangeOptions (max) {
      return [...Array(max + 1).keys()].map( (i) => {
        return { text: i.toString(), value: i }
      })
    },
  }
}
</script>

<style lang="stylus" scoped>
</style>
