<template>
  <div class="meta-flex center meta-prices">
    <div class="row">
      <span>€</span>
      <span :class="{ disabled: price === 'cheap'}">€</span>
      <span :class="{ disabled: price !== 'expensive'}">€</span>
    </div>
    <sui-dropdown
      direction="downward"
      class="ui fluid dropdown custom-dropdown recipe-form__dropdown recipe-form__dropdown--small selection"
      :options="options"
      v-model="price"
    />
    <div class="text-center dropdown-instruction m-t-1">
      {{ capitalize($i18n.t('elements.recipe.text.price_text')) }}
    </div>
  </div>
</template>

<script>
import { inflection } from './mixins'

export default {
  props: ['value'],
  mixins: [inflection],
  data () {
    return {
      price: this.value || 'cheap'
    }
  },
  computed: {
    options () {
      return ['cheap', 'moderate', 'expensive'].map( (p) => {
        return {
          value: p,
          text: this.capitalize(this.$i18n.t(`activerecord.enums.recipe.prices.${p}`))
        }
      })
    }
  },
  watch: {
    price () {
      this.$emit('input', this.price)
    }
  }
}
</script>

<style lang="stylus" scoped>
</style>

