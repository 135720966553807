<template>
  <div
    id="cta_blocks"
    class="tab-pane"
  >
    <div class="panel panel-default">
      <div class="panel-heading">
        <h4 class="box-title">
          CTA Blocks
          <i
            v-if="loading"
            class="fa fa-fw fa-spinner fa-spin"
          ></i>
        </h4>
      </div>

      <div class="panel-body">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>Image Horizontale</th>
              <th>Image Verticale</th>
              <th width="60%">Url</th>
              <th class="text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            <cta-block
              v-for="(block, index) of blocks"
              :key="index"
              :block="block"
              :page-id="page.id"
              @update="updateBlock"
            />
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import CtaBlock from './CtaBlock.vue'

export default {
  components: {
    CtaBlock
  },
  props: {
    page: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      loading: false,
      blocks: []
    }
  },
  methods: {
    updateBlock(newBlock) {
      const blockIndex = this.blocks.findIndex(block => {
        return block.id === newBlock.id
      })
      this.blocks.splice(blockIndex, 1, newBlock)
    },
    fetchBlocks() {
      this.loading = true
      this.$http.get(`/back/club_home_pages/${this.page.id}/cta_blocks`).then(response => {
        this.blocks = response.data
        this.loading = false
      })
    }
  },
  created() {
    this.fetchBlocks()
  }
}
</script>

<style lang="stylus" scoped>
</style>
