<template>
  <div id="video_url" class="tab-pane">
    <div class="panel panel-default">
      <div class="panel-heading">
        <h4 class="box-title">
          Vidéo
        </h4>
      </div>

      <div class="panel-body">
        <div class="panel panel-default">
          <div class="panel-heading">
            <span>
              Vidéo
            </span>
            <div>
              <button
                v-if="!editMode"
                @click.prevent="editMode = true"
                class="btn btn-xs btn-success"
              >
                <i class="fa fa-fw fa-edit" />
              </button>
              <template v-else>
                <button
                  @click.prevent="save"
                  :disabled="saving"
                  class="btn btn-success btn-xs"
                >
                  <i
                    :class="
                      saving ? 'fa fa-fw fa-spinner fa-spin' : 'fa fa-fw fa-check'
                    "
                  />
                </button>
                <button @click.prevent="cancelEdit" class="btn btn-xs btn-danger">
                  <i class="fa fa-fw fa-window-close" />
                </button>
              </template>
            </div>
          </div>
          <div class="panel-body">
            <div class="video-container">
              <video-input v-model="videoFile" v-if="editMode" />
              <video :src="video.url" controls width="100%" v-else />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VideoInput from '@back/components/VideoInput'

export default {
  components: { VideoInput },
  props: {
    page: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      video: this.page.video,
      videoFile: null,
      editMode: false,
      saving: false,
    }
  },
  computed: {
  },
  methods: {
    save() {
      let payload = new FormData()

      payload.append('club_home_page[video]', this.videoFile)
      this.saving = true
      this.$http
        .put(`/back/club_home_pages/${this.page.id}`, payload)
        .then(({ data }) => {
          this.video = data.video
          this.editMode = false
          this.saving = false
        })
        .catch(() => {
          this.cancelEdit()
        })
    },
    cancelEdit() {
      this.video = this.page.video
      this.editMode = false
      this.saving = false
    },
  },
}
</script>

<style lang="stylus" scoped>
.panel-heading
  display: flex
  justify-content: space-between
  align-items: center
  .url-input
    width: 80%
.video-container
  position: relative
  padding-bottom: 56.25%
  height: 0
  iframe
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%

</style>
