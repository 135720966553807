<template>
  <div class="ui middle aligned grid container group-container">
    <div class="column group-fields">
      <div class="group-wrap">
        <div class="group-header">
          <h2 class="group-title">
            <input
              v-model.lazy="label"
              maxlength="150"
              class="ui huge header m-t-0 no-border transparent title-input"
              :placeholder="capitalize($i18n.t('elements.recipe.text.prep_title'))"
            />
            <i class="gdm-input-ecrire icon"></i>
          </h2>
          <div class="group-header__remove">
            <a class="custom-link--remove remove_fields" @click.prevent="confirmDeletion">
              <i class="gdm-supprimer icon"></i>
              <span>
                {{ capitalize($i18n.t('elements.recipe.buttons.del_group')) }}
              </span>
            </a>
          </div>
        </div>

        <Steps :group="group" />
      </div>
    </div>
  </div>
</template>

<script>
import { inflection, api } from './mixins'

import Steps from './Steps.vue'

export default{
  props: ['group'],
  mixins: [inflection, api],
  components: { Steps },
  data () {
    return {
      label: this.group.label,
    }
  },
  watch: {
    label: {
      handler: 'updateGroup'
    }
  },
  methods: {
    updateGroup () {
      const groupParams = {
        group: {
          label: this.label
        }
      }

      this.sendRequest('put', `${this.recipePath}/groups/${this.group.id}`, groupParams)
      .then( (response) => {
        this.label = response.data.label
      })
    },
    confirmDeletion () {
      this.$store.dispatch('openDeleteConfirmation', {
        resource: 'group',
        path: `${this.recipePath}/groups/${this.group.id}`
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
</style>
