<template>
  <div>
    <img
      :src="displayImageUrl"
      :width="width"
    />

    <div class="input-group m-t-1">
      <span class="input-group-btn">
        <span class="btn btn-primary btn-file">
          Choisir
          <input
            @change="previewImage"
            type="file"
            accept="image/*"
          >
        </span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentImage: {
      type: Object,
      default: () => {
        return { url: null }
      }
    },
    width: {
      type: String,
      default: '100%'
    }
  },
  data() {
    return {
      imageFile: null
    }
  },
  computed: {
    displayImageUrl() {
      if (this.imageFile) {
        return URL.createObjectURL(this.imageFile)
      } else {
        return this.currentImage.url
      }
    }
  },
  methods: {
    previewImage(e) {
      this.imageFile = e.target.files[0]
      this.$emit('input', this.imageFile)
    }
  }
}
</script>

<style lang="stylus" scoped>
.btn-file
  position: relative
  overflow: hidden
.btn-file input[type=file]
  position: absolute
  top: 0
  right: 0
  min-width: 100%
  min-height: 100%
  font-size: 999px
  text-align: right
  filter: unquote('alpha(opacity=0)')
  opacity: 0
  background: red
  cursor: inherit
  display: block
input[readonly]
  background-color: white !important
  cursor: text !important
</style>
