<template>
  <div class="weekly-menu-container">
    <div class="ui equal width stackable grid header m-b-1">
      <div class="column four wide"></div>
      <div class="column text-center">
        <h1 v-if="readOnly" class="custom-title custom-title--h1 text-center">
          {{ menu.title }}
        </h1>
        <template v-else>
          <h1 class="recipe-form__title">
            <input
              type="text"
              v-model="title"
              placeholder="Titre du menu"
              maxlength="150"
              class="ui no-border transparent text-center title-input"
              required
            />
            <i class="gdm-input-ecrire icon" />
          </h1>
          <div class="ui checkbox custom-checkbox">
            <input
              v-model="isPrivate"
              type="checkbox"
              class="ui toggle checkbox"
            />
            <label @click="isPrivate = !isPrivate"> Menu privé </label>
          </div>
          <shopping-list-btn :read-only="readOnly" :menu="menu" />
        </template>
      </div>
      <div class="column four wide text-right">
        <div
          v-if="!readOnly"
          @click="deleteModalOpen = true"
          class="ui button custom-button custom-button--filaire"
        >
          <i class="trash alternate outline icon" />
          Supprimer
        </div>
        <a
          :href="printUrl"
          target="_blank"
          class="ui button custom-button custom-button--filaire round-icon"
        >
          <i class="icon gd-print" />
        </a>
      </div>
    </div>
    <div class="ui container">
      <div
        v-for="(column, index) in menu.weekly_menu_columns"
        :key="column.id"
        class="ui segment menu-row"
        :id="'column-' + column.id"
      >
        <div class="ui gdm-red ribbon label large">{{ column.title }}</div>
        <div class="menu-row-content">
          <draggable
            class="list-group"
            :list="column.weekly_menu_items"
            group="menuItems"
            :disabled="loading || readOnly || editing"
            @start="toggleDrag"
            @end="toggleDrag"
          >
            <item-card
              v-for="(item, idx) in column.weekly_menu_items"
              :key="`${index}-${idx}-${item.id}`"
              :item="item"
              :ref="`${index}-${idx}-${item.id}`"
              :menu-id="menu.id"
              :disabled="dragging"
              :read-only="readOnly"
              @editing="handleEditing"
              @update="updateItem"
              @delete="deleteItem"
            />
          </draggable>
        </div>
        <div v-if="!readOnly" class="action-links">
          <a @click.prevent="openRecipeModalFor(column.id)">
            + Ajouter une recette
          </a>
          |
          <a @click.prevent="addNote(column.id, index)">+ Ajouter une note</a>
        </div>
      </div>
    </div>
    <sui-modal v-if="!readOnly" v-model="deleteModalOpen">
      <sui-modal-header>
        <i class="trash alternate outline icon" />
        Supprimer le menu
      </sui-modal-header>
      <sui-modal-content>
        <sui-modal-description>
          Voulez-vous vraiment supprimer le menu :
          <strong>{{ menu.title }}</strong>
        </sui-modal-description>
      </sui-modal-content>
      <sui-modal-actions class="no-border transparent">
        <div
          @click="deleteModalOpen = false"
          class="ui deny button custom-button custom-button--filaire"
        >
          Annuler
        </div>
        <div
          @click="deleteMenu"
          class="ui positive green button custom-button"
          :class="{ loading: deleting }"
        >
          <i class="trash alternate outline icon" />
          Supprimer
        </div>
      </sui-modal-actions>
    </sui-modal>
    <sui-modal v-if="!readOnly" v-model="recipeModalOpen">
      <sui-modal-header class="d-flex justify-between no-border transparent">
        <div>
          + Ajouter une recette pour
          <span class="text-gdm-red">{{ (targetColumn || {}).title }}</span>
        </div>
        <i class="icon close" @click="recipeModalOpen = false" />
      </sui-modal-header>
      <sui-modal-content>
        <sui-modal-description>
          <recipe-search @select="addRecipe" ref="recipeSearchModal" />
        </sui-modal-description>
      </sui-modal-content>
    </sui-modal>
  </div>
</template>

<script>
import Draggable from 'vuedraggable'
import ItemCard from './ItemCard'
import RecipeSearch from './RecipeSearch'
import ShoppingListBtn from './ShoppingListBtn'

import _isEqual from 'lodash/isEqual'
import _findIndex from 'lodash/findIndex'
import _find from 'lodash/find'

export default {
  components: {
    Draggable,
    ItemCard,
    RecipeSearch,
    ShoppingListBtn,
  },
  data() {
    return {
      menu: $('#weekly-menu-data').data('weekly-menu'),
      readOnly: $('#weekly-menu-data').data('read-only'),
      title: $('#weekly-menu-data').data('weekly-menu').title,
      isPrivate: $('#weekly-menu-data').data('weekly-menu').is_private,
      printUrl: `${window.location.href}/print_pdf`,
      columnsData: null,
      loading: false,
      recipeModalOpen: false,
      deleteModalOpen: false,
      deleting: false,
      targetColumnId: null,
      dragging: false,
      editing: false,
    }
  },
  watch: {
    menu: {
      handler: 'updateColumns',
      deep: true,
    },
    title: {
      handler: 'updateMenu',
    },
    isPrivate: {
      handler: 'updateMenu',
    },
    recipeModalOpen() {
      if (!this.recipeModalOpen) {
        this.$refs.recipeSearchModal.resetForm()
      }
    },
  },
  computed: {
    targetColumn() {
      if (this.targetColumnId) {
        return _find(this.menu.weekly_menu_columns, {
          id: this.targetColumnId,
        })
      } else {
        return null
      }
    },
  },
  methods: {
    handleEditing(value) {
      this.editing = value
    },
    toggleDrag() {
      setTimeout(() => {
        this.dragging = !this.dragging
      }, 50)
    },
    async deleteMenu() {
      this.deleting = true
      await this.$http.delete(`/weekly_menus/${this.menu.id}`)
      window.location.href = $($('.ui.breadcrumb > a')[2]).attr('href')
    },
    async updateMenu() {
      const weekly_menu = {
        is_private: this.isPrivate,
        title: this.title,
      }

      await this.$http.put(`/weekly_menus/${this.menu.id}`, { weekly_menu })
    },
    async addRecipe(newRecipeId) {
      this.loading = true
      const index = _findIndex(this.menu.weekly_menu_columns, {
        id: this.targetColumnId,
      })
      const response = await this.$http.post(
        `/weekly_menus/${this.menu.id}/weekly_menu_items`,
        {
          item: {
            weekly_menu_column_id: this.targetColumnId,
            recipe_id: newRecipeId,
          },
        }
      )

      this.columnsData[index].push(response.data.id)
      this.menu.weekly_menu_columns[index].weekly_menu_items.push({
        ...response.data,
      })

      this.targetColumnId = null
      this.recipeModalOpen = false

      this.loading = false
    },
    openRecipeModalFor(columnId) {
      this.targetColumnId = columnId
      this.recipeModalOpen = true
    },
    deleteItem(item) {
      const columnIndex = _findIndex(this.menu.weekly_menu_columns, {
        id: item.weekly_menu_column_id,
      })

      const newItems = this.menu.weekly_menu_columns[
        columnIndex
      ].weekly_menu_items.filter((i) => {
        return i.id !== item.id
      })

      this.$set(
        this.menu.weekly_menu_columns[columnIndex],
        'weekly_menu_items',
        newItems
      )
    },
    updateItem(item) {
      const columnIndex = _findIndex(this.menu.weekly_menu_columns, {
        id: item.weekly_menu_column_id,
      })

      const itemIndex = _findIndex(
        this.menu.weekly_menu_columns[columnIndex].weekly_menu_items,
        { id: item.id }
      )

      this.$set(
        this.menu.weekly_menu_columns[columnIndex].weekly_menu_items,
        itemIndex,
        item
      )
    },
    async addNote(columnId, index) {
      this.loading = true
      const response = await this.$http.post(
        `/weekly_menus/${this.menu.id}/weekly_menu_items`,
        { item: { weekly_menu_column_id: columnId } }
      )

      this.columnsData[index].push(response.data.id)
      this.menu.weekly_menu_columns[index].weekly_menu_items.push({
        ...response.data,
        editMode: true,
      })

      this.loading = false
    },
    async updateColumns() {
      this.loading = true

      const newColumnsData = this.extractColumnsData(this.menu)

      for (const index of newColumnsData.keys()) {
        if (!_isEqual(newColumnsData[index], this.columnsData[index])) {
          await this.updateColumn(this.menu.weekly_menu_columns[index])
        }
      }

      this.columnsData = newColumnsData

      this.loading = false
    },
    async updateColumn(column) {
      const items = column.weekly_menu_items.map((i, order) => {
        return {
          id: i.id,
          description: i.description,
          recipe_id: (i.recipe || {}).id,
          weekly_menu_column_id: column.id,
          order,
        }
      })
      const response = await this.$http.put(
        `/weekly_menus/${this.menu.id}/weekly_menu_items/update_list`,
        { column: { items } }
      )
    },
    extractColumnsData(menu) {
      return menu.weekly_menu_columns.map((column) => {
        return column.weekly_menu_items.map((i) => i.id)
      })
    },
  },
  created() {
    this.columnsData = this.extractColumnsData(this.menu)
  },
}
</script>

<style lang="scss">
.d-flex {
  display: flex !important;
}
</style>

<style lang="scss" scoped>
.header {
  .close {
    cursor: pointer;
  }
  .text-gdm-red {
    color: #e83b47;
  }
  .recipe-form__title {
    margin-bottom: 20px;
    .title-input {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR4nGP6zwAAAgcBApocMXEAAAAASUVORK5CYII=');
      cursor: auto;
      width: 90%;
      padding-left: 0 !important;
      padding-right: 0 !important;
      font-size: 2.5rem !important;
    }
    .icon {
      top: 15px !important;
    }
  }
  .custom-checkbox {
    label {
      cursor: pointer;
    }
  }

  padding: 15px !important;
  .custom-title {
    color: #5c1e11;
    font-style: italic;
  }
  .round-icon {
    margin: 0;
    padding: 4px 0 !important;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    vertical-align: bottom;
    font-size: 26px;
    .icon {
      margin: 0 !important;
    }
  }
}
.weekly-menu-container {
  padding: 20px;
  width: 100%;
  height: 100%;

  .menu-row {
    background-color: #ffe9e9;
    border-color: #ffe9e9;
    width: 100%;
    margin-bottom: 30px;

    .menu-row-content {
      .sortable-ghost {
        opacity: 0.5;
      }
      .list-group {
        min-height: 20px;
        span {
          display: block;
          width: 100%;
          min-height: 20px;
        }
      }

      margin-bottom: 10px;
    }

    .ui.ribbon.label {
      border-radius: 0.3rem 0.3rem 0.3rem 0;
      padding-left: 20px;
      padding-right: 30px;
      top: calc(-1rem - 0.5em);
      font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif !important;
    }
    .gdm-red {
      background-color: #e83b47;
      border-color: #982830;
      color: white;
    }
    .action-links {
      color: #e83b47;
      a {
        color: #e83b47;
        padding: 5px;
        cursor: pointer;
      }
    }
  }
}
</style>
