<template>
  <div>
    <div
      class="panel"
      :class="inactive ? 'panel-danger' : 'panel-success'"
    >
      <div class="panel-heading">
        <input
          v-if="editMode"
          v-model="data.title"
          class="form-control title-input"
          placeholder="Titre"
        />
        <span
          class="text-black"
          v-else
        >
          {{ data.title }}
        </span>
        <div>
          <button
            v-if="errors.length < 1"
            @click.prevent="toggleEditMode"
            class="btn btn-xs btn-success"
          >
            <i
              v-if="saving"
              class="fa fa-fw fa-spinner fa-spin"
            ></i>
            <i
              v-else
              :class="editMode ? 'fa fa-fw fa-check' : 'fa fa-fw fa-edit'"
            ></i>
          </button>
          <button
            v-if="editMode && !!data.id"
            @click.prevent="cancelEdit"
            class="btn btn-xs btn-danger"
          >
            <i class="fa fa-fw fa-window-close"></i>
          </button>
          <button
            v-else
            @click.prevent="removePost"
            class="btn btn-xs btn-danger"
          >
            <i :class="
                removeLoading
                  ? 'fa fa-fw fa-spinner fa-spin'
                  : 'fa fa-fw fa-trash'
              "></i>
          </button>
        </div>
      </div>
      <div class="panel-body">
        <div class="row">
          <div class="col-md-4 text-center">
            <image-input
              v-model="file"
              :current-image="{ url: displayImageUrl }"
              v-if="editMode"
              class="m-t-1"
            />
            <img
              v-else-if="data.image.url"
              :src="data.image.url"
              width="100%"
            />
            <div class="text-left m-t-3 form-group">
              <label>
                URL Vidéo :
              </label>
              <input
                type="text"
                v-if="editMode"
                v-model="data.video_url"
                class="form-control"
              />
              <a
                :href="data.video_url"
                target="_blank"
                v-else
              >
                {{ data.video_url }}
              </a>
            </div>
            <div
              class="text-left m-t-3 form-group"
              :class="{
                'has-error': errors.includes('start_at'),
              }"
            >
              <label>
                À partir du :
              </label>
              <flat-pickr
                v-if="editMode"
                v-model="data.start_at"
                :config="pickerConfig"
              />
              <span v-else>{{ data.start_at }}</span>
            </div>
            <div
              class="text-left form-group"
              :class="{
                'has-error': errors.includes('end_at'),
              }"
            >
              <label>
                Jusqu'au (inclus) :
              </label>

              <flat-pickr
                v-if="editMode"
                v-model="data.end_at"
                :config="pickerConfig"
              />
              <span v-else>{{ data.end_at }}</span>
            </div>
          </div>

          <div class="col-md-8">
            <div class="form-group m-t-1">
              <label>Contenu</label>
              <vue-mce
                v-if="editMode"
                v-model="data.content"
                :config="{
                  toolbar:
                    'styleselect | bold italic underline | undo redo | link | media | image | code',
                  plugins: 'link image media code',
                }"
              />
              <div
                class="content"
                v-else
                v-html="data.content"
              >Contenu</div>
            </div>
            <div class="form-group m-t-1">
              <label>CTA</label>
              <template v-if="editMode">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Titre du bouton"
                    v-model="data.cta_data.title"
                    required="required"
                  />
                </div>

                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Keywords"
                    v-model="data.cta_data.keywords"
                  />
                </div>

                <div class="form-group">
                  <v-select
                    :options="categories"
                    v-model="data.cta_data.categories"
                    placeholder="Catégories"
                    multiple
                  />
                </div>

                <div class="form-group">
                  <v-select
                    :options="thematics"
                    v-model="data.cta_data.thematics"
                    placeholder="Thématiques"
                    multiple
                  />
                </div>

                <div class="form-group">
                  <v-select
                    :options="ingredients"
                    v-model="data.cta_data.ingredients"
                    placeholder="Ingrédients"
                    multiple
                  />
                </div>

                <div class="form-group">
                  <user-select
                    v-model="data.cta_data.author"
                    :disabled="saving"
                    placeholder="Auteur"
                  />
                </div>
              </template>
              <a
                :href="ctaUrl"
                target="_blank"
                class="btn btn-primary"
                style="display: block;"
              >
                {{ data.cta_data.title }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageInput from '@back/components/ImageInput.vue'
import { French } from 'flatpickr/dist/l10n/fr.js'
import UserSelect from '@back/components/UserSelect.vue'

export default {
  components: {
    ImageInput,
    UserSelect,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editMode: !this.post.id,
      saving: false,
      removeLoading: false,
      data: { ...this.post },
      file: null,
      pickerConfig: {
        enableTime: true,
        dateFormat: 'd-m-Y H:i',
        time_24hr: true,
        defaultHour: 10,
        locale: French,
      },
      categories: $('#device-news-posts-data').data('categories'),
      thematics: $('#device-news-posts-data').data('thematics'),
      ingredients: $('#device-news-posts-data').data('ingredients'),
    }
  },
  computed: {
    inactive() {
      const now = moment()

      const displayable = now.isSameOrAfter(
        moment(this.data.start_at, 'DD-MM-YYYY HH:mm')
      )

      const archived = now.isAfter(
        moment(this.data.end_at, 'DD-MM-YYYY HH:mm')
      )

      return !displayable || archived
    },
    errors() {
      let errors = []
      if (!this.data.start_at) {
        errors.push('start_at')
      }

      if (this.data.start_at && this.data.end_at) {
        let displayDate = moment(this.data.start_at, 'DD-MM-YYYY HH:mm')
        let expireDate = moment(this.data.end_at, 'DD-MM-YYYY HH:mm')

        if (displayDate.isSameOrAfter(expireDate)) {
          errors.push('end_at')
        }
      }

      if (!this.data.content) {
        errors.push('content')
      }

      return errors
    },
    displayImageUrl() {
      if (this.file) {
        return URL.createObjectURL(this.file)
      } else {
        return this.data.image.url
      }
    },
    payload() {
      let payload = new FormData()
      payload.append('device_news_post[title]', this.data.title)
      payload.append('device_news_post[video_url]', this.data.video_url)
      payload.append('device_news_post[content]', this.data.content)
      payload.append('device_news_post[start_at]', this.data.start_at)
      payload.append('device_news_post[end_at]', this.data.end_at)
      payload.append(
        'device_news_post[cta_data]',
        JSON.stringify(this.data.cta_data)
      )

      if (this.file) {
        payload.append('device_news_post[image]', this.file)
      }

      return payload
    },
    ctaUrl() {
      let url = '/recettes?is_icookable=true&'

      if (this.data.cta_data.keywords) {
        url += `search_term=${this.data.cta_data.keywords}&`
      }

      if (this.data.cta_data.categories) {
        url += `categories=${this.data.cta_data.categories
          .map((r) => {
            return r.label
          })
          .join(',')}&`
      }

      if (this.data.cta_data.thematics) {
        url += `thematics=${this.data.cta_data.thematics
          .map((r) => {
            return r.label
          })
          .join(',')}&`
      }

      if (this.data.cta_data.author) {
        url += `users=${this.data.cta_data.author.username}`
      }

      return url
    },
  },
  methods: {
    toggleEditMode() {
      if (this.editMode) {
        !!this.post.id ? this.updatePost() : this.createPost()
      } else {
        this.editMode = true
      }
    },
    updatePost() {
      this.saving = true
      this.$http
        .put(`/back/device_news_posts/${this.post.id}`, this.payload)
        .then(({ data }) => {
          this.data = data
          this.editMode = false
          this.saving = false
        })
        .catch(() => {
          this.cancelEdit()
        })
    },
    createPost() {
      this.saving = true
      this.$http
        .post('/back/device_news_posts', this.payload)
        .then(({ data }) => {
          this.$emit('created', { ...this.post, ...data })
          this.editMode = false
          this.saving = false
        })
        .catch((error) => {
          this.saving = false
        })
    },
    removePost() {
      if (!confirm('Voulez-vous vraiment supprimer ce post ?')) {
        return
      }
      if (this.data.tmpId) {
        this.$emit('remove', this.post)
        return
      } else {
        this.$http
          .delete(`/back/device_news_posts/${this.post.id}`)
          .then((response) => {
            this.$emit('remove', this.post)
          })
      }
    },
    cancelEdit() {
      this.data = { ...this.post }
      this.file = null
      this.editMode = false
      this.saving = false
    },
    toggleActivity() {
      if (this.editMode) {
        this.data.end_at = !this.data.end_at
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
.flatpickr-input {
  display: block;
}

.clickable {
  cursor: pointer;
}

.panel-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-input {
  width: 80%;
}

.body-content {
  display: flex;
  align-items: center;
}

.author-image {
  margin-right: 15px;
  width: 30px;
}

.content {
  border: 1px solid gray;
  border-radius: 5px;
}

.toggle-icon {
  font-size: 25px;
  vertical-align: sub;
  cursor: pointer;

  &.fa-toggle-on {
    color: #4cae4c;
  }

  &.fa-toggle-off {
    color: lightgray;
  }
}
</style>
