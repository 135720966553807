<template>
  <div class="groups recipe-form__groups">

    <Group
      v-for="group in groups"
      :key="group.id"
      :group="group"
    />

    <div class="links">
      <div class="groups-bottom">
        <p class="text-center">
          {{ capitalize($i18n.t('elements.recipe.text.add_group_text')) }}
        </p>

        <button
          @click.prevent="addGroup"
          :class="{ loading: loadingNewGroup }"
          class="ui button custom-button custom-button--icon m-r-0"
        >
          <i class="gdm-nav-ma-cuisine icon"></i>
          {{ capitalize($i18n.t('elements.recipe.buttons.add_group')) }}
        </button>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { inflection, api } from './mixins'
import Group from './Group.vue'

export default {
  mixins: [inflection, api],
  components: { Group },
  data () {
    return {
      loadingNewGroup: false
    }
  },
  computed: {
    ...mapGetters(['recipeData']),
    groups () {
      return this.recipeData.groups
    }
  },
  methods: {
    addGroup () {
      const count = this.groups.length + 1

      const groupName = this.$i18n.t('elements.group.text.drafted_group', {
        number: count
      })

      const groupParams = { group: { label: groupName } }

      this.loadingNewGroup = true
      this.sendRequest('post', `${this.recipePath}/groups`, groupParams)
      .then( (response) => {
        this.$store.dispatch('updateRecipe', response.data)
        this.loadingNewGroup = false
      })
      .catch( () => this.loadingNewGroup = false )
    },
  },
}
</script>

<style lang="stylus" scoped>
</style>
