<template>
  <div class="ui fields inline m-b-0">
    <div class="ui field">
      <sui-dropdown
        search
        direction="downward"
        :options="rangeOptions(Math.min(this.max / 60 + 1, 100))"
        v-model="time.minutes"
        class="ui fluid dropdown search dropdown-time custom-dropdown recipe-form__dropdown recipe-form__dropdown--small selection"
        :class="{ error }"
      />
    </div>
    <label class="prepare-label">min</label>
    <div class="ui field">
      <sui-dropdown
        search
        direction="downward"
        :options="rangeOptions(60, 5)"
        v-model="time.seconds"
        class="ui fluid dropdown search dropdown-time custom-dropdown recipe-form__dropdown recipe-form__dropdown--small selection"
        :class="{ error, disabled: secondsDisabled }"
      />
    </div>
    <label class="prepare-label">sec</label>
  </div>
</template>

<script>
import { range } from 'lodash'

export default{
  props: ['value', 'error', 'max'],
  data () {
    return {
      time: {
        minutes: Math.trunc(this.value / 60),
        seconds: this.value % 60
      },
    }
  },
  watch: {
    time: {
      handler: 'updateValue',
      deep: true
    }
  },
  computed: {
    valueInSeconds () {
      return (this.time.minutes * 60) + this.time.seconds
    },
    secondsDisabled () {
      return this.value >= this.max
    }
  },
  methods: {
    updateValue (newVal, oldVal) {
      if (this.valueInseconds >= this.max) {
        this.time.seconds = 0
        this.time.minutes = this.max / 60
      }

      if (this.valueInseconds != this.value) {
        this.$emit('input', this.valueInSeconds)
      }
    },
    rangeOptions (max, step=1) {
      return range(0, max, step).map( (i) => {
        return { text: i.toString(), value: i }
      })
    },
  },
  updated () {
    this.time.minutes = Math.trunc(this.value / 60)
    this.time.seconds = this.value % 60
  }
}
</script>

<style lang="stylus" scoped>
</style>

