<template>
  <sui-modal @clickAwayModal="resetForm" v-model="open">
    <i @click="resetForm" class="close icon" />
    <sui-modal-header>
      Ajouter <span class="text-gdm-red">{{ recipe.title }}</span> à un menu
      hebdomadaire
    </sui-modal-header>
    <sui-modal-content>
      <form class="ui form">
        <div class="two fields">
          <div class="field" :class="{ error }">
            <label>Menu Hebdomadaire</label>

            <input
              v-if="newMenuMode"
              type="text"
              placeholder="Entrez le nom de votre nouveau menu"
              v-model="newMenuName"
              required
            />
            <template v-else>
              <sui-dropdown
                direction="downward"
                class="ui fluid dropdown custom-dropdown recipe-form__dropdown recipe-form__dropdown--small selection ui dropdown"
                :options="selectableMenus"
                v-model="selectedMenu"
              />
              <a
                href="javascript:void(0)"
                class="text-gdm-red"
                @click="newMenuMode = true"
              >
                Ajouter à un nouveau menu
              </a>
            </template>
          </div>
          <div class="field">
            <label>Jour de la semaine</label>
            <sui-dropdown
              direction="downward"
              class="ui fluid dropdown custom-dropdown recipe-form__dropdown recipe-form__dropdown--small selection ui dropdown"
              :options="selectableDays"
              v-model="selectedDay"
            />
          </div>
        </div>
      </form>
    </sui-modal-content>
    <sui-modal-actions class="no-border transparent">
      <div
        @click="resetForm"
        class="ui deny button custom-button custom-button--filaire"
      >
        Annuler
      </div>
      <sui-button
        @click="addToMenu"
        :disabled="error"
        :loading="loading"
        class="ui positive button custom-button"
      >
        Valider
      </sui-button>
    </sui-modal-actions>
  </sui-modal>
</template>

<script>
export default {
  data() {
    return {
      recipe: {},
      menus: $('#weekly-menus-data').data('menus'),
      selectedMenu: ($('#weekly-menus-data').data('menus')[0] || {}).id,
      selectedDay: 0,
      newMenuMode: !$('#weekly-menus-data').data('menus').length,
      newMenuName: null,
      open: false,
      loading: false,
    }
  },
  computed: {
    error() {
      return this.newMenuName === ''
    },
    selectedColumn() {
      if (!this.newMenuMode) {
        return this.menus.find((m) => m.id === this.selectedMenu)
          .weekly_menu_columns[this.selectedDay]
      } else {
        return undefined
      }
    },
    selectableMenus() {
      return this.menus.map((menu) => {
        return {
          value: menu.id,
          text: menu.title,
        }
      })
    },
    selectableDays() {
      return [
        'Lundi',
        'Mardi',
        'Mercredi',
        'Jeudi',
        'Vendredi',
        'Samedi',
        'Dimanche',
      ].map((day, idx) => {
        return {
          value: idx,
          text: day,
        }
      })
    },
  },
  methods: {
    resetForm() {
      this.open = false
      this.newMenuMode = false
      this.newMenuName = null
      this.selectedDay = 0
      this.menus = $('#weekly-menus-data').data('menus')
      this.selectedMenu = this.menus[0].id
    },
    async addToMenu() {
      this.loading = true

      let menuId = null
      let columnId = null

      if (this.newMenuMode) {
        if (!!this.newMenuName) {
          const response = await this.$http.post(`/weekly_menus`, {
            weekly_menu: { title: this.newMenuName },
          })

          const menu = response.data

          menuId = menu.id
          columnId = menu.weekly_menu_columns[this.selectedDay].id

          $('#weekly-menus-data').data('menus', [
            ...$('#weekly-menus-data').data('menus'),
            menu,
          ])
        } else {
          this.newMenuName = ''
          this.loading = false

          return
        }
      } else {
        menuId = this.selectedMenu
        columnId = this.selectedColumn.id
      }

      await this.$http.post(`/weekly_menus/${menuId}/weekly_menu_items`, {
        item: {
          weekly_menu_column_id: columnId,
          recipe_id: this.recipe.id,
        },
      })
      this.loading = false
      window.globalFlashMessage(
        'success',
        'La recette a bien été ajoutée au menu'
      )
      this.resetForm()
    },
  },
}
</script>

<style lang="scss" scoped>
.text-gdm-red {
  color: #e83b47;
  margin-left: 5px;
}
.dropdown {
  margin-bottom: 5px;
}
.header {
  border-bottom: none !important;
}
.button:focus {
  background-color: #e83b47 !important;
}
</style>
