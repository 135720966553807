<template>
  <div>
    <nav>
      <ol class="pagination justify-content-center">
        <li
          v-if="currentPage != 1"
          class="page-item pagination__item m-1 mr-2"
        >
          <a
            class="page-link pagination__link"
            @click.prevent="goToPrev"
          >
            <i class="pagination__icon fas fa-chevron-left"></i>
            <span class="pagination__wording pagination__wording--left d-none d-md-inline">Précédente</span>
          </a>
        </li>

        <li
          :class="{ active: currentPage == 1 }"
          class="page-item pagination__item m-1"
        >
          <a
            v-if="currentPage != 1"
            class="page-link pagination__link"
            @click.prevent="goToPage(1)"
          >
            1
          </a>
          <span
            v-else
            class="page-link pagination__link"
          >
            1
          </span>
        </li>

        <li
          v-if="firstDisplayedPage > 2"
          class="page-item pagination__item my-1"
        >
          <span class="page-link pagination__link pagination__item--separator disabled">...</span>
        </li>

        <li
          v-for="page in pagesToDisplay"
          :class="currentPage == page ? 'active' : ''"
          :key="page"
          class="page-item pagination__item m-1"
        >
          <a
            v-if="currentPage != page"
            class="page-link pagination__link"
            @click.prevent="goToPage(page)"
          > {{ page }}
          </a>
          <span
            v-else
            class="page-link pagination__link"
          >{{ page }}
          </span>
        </li>

        <li
          v-if="lastDisplayedPage < totalPages - 1"
          class="page-item pagination__item my-1"
        >
          <span class="page-link pagination__link pagination__item--separator disabled">...</span>
        </li>

        <li
          :class="{ active: currentPage == totalPages }"
          class="page-item pagination__item m-1"
        >
          <a
            v-if="currentPage != totalPages"
            class="page-link pagination__link"
            @click.prevent="goToPage(totalPages)"
          >
            {{ totalPages }}
          </a>
          <span
            v-else
            class="page-link pagination__link"
          >
            {{ totalPages }}
          </span>
        </li>

        <li
          v-if="currentPage != totalPages"
          class="page-item pagination__item m-1 ml-2"
        >
          <a
            class="page-link pagination__link"
            @click.prevent="goToNext"
          >
            <span class="pagination__wording pagination__wording--right d-none d-md-inline">Suivante</span>
            <i class="pagination__icon fas fa-chevron-right"></i>
          </a>
        </li>
      </ol>
    </nav>
  </div>
</template>

<script>
import { range } from 'lodash'

export default {
  props: {
    currentPage: {
      type: Number,
      required: true
    },
    totalPages: {
      type: Number,
      required: true
    },
    displayedPagesCount: {
      type: Number,
      default: 2
    }
  },
  computed: {
    firstDisplayedPage() {
      let page = this.currentPage - this.displayedPagesCount

      return page <= 1 ? 2 : page
    },
    lastDisplayedPage() {
      let page = this.currentPage + this.displayedPagesCount

      return page >= this.totalPages ? this.totalPages - 1 : page
    },
    pagesToDisplay() {
      return range(this.firstDisplayedPage, this.lastDisplayedPage + 1)
    }
  },
  methods: {
    goToPrev() {
      this.$emit('goToPage', this.currentPage - 1)
    },
    goToNext() {
      this.$emit('goToPage', this.currentPage + 1)
    },
    goToPage(page) {
      if (this.currentPage != page) {
        this.$emit('goToPage', page)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.pagination__link
  cursor: pointer
.pagination
  margin: 0!important
</style>
