<template>
  <div class="section-white">
    <h2 class="profile_top-title">
      <i class="gd-besave" />
      {{ $i18n.t('activerecord.attributes.user.be_save') }}
    </h2>

    <p class="little-sub-text">
      {{ $i18n.t('elements.user.text.be_save_text') }}
    </p>

    <div class="fields">
      <div class="six wide field">
        <label>
          {{ $i18n.t('activerecord.attributes.user.be_save_serial') }}
        </label>
        <input
          type="text"
          v-model="serial"
          :placeholder="$i18n.t('activerecord.attributes.user.be_save_serial')"
        />
      </div>

      <div
        v-if="touched"
        class="two wide field action-button"
      >
        <label>
          <button
            class="ui icon button custom-button custom-button--success"
            :disabled="saving"
            @click.prevent="save"
          >
            <i
              class="fa fa-fw"
              :class="saving ? 'fa-spinner fa-spin' : 'fa-check'"
            />
          </button>
        </label>
      </div>
    </div>

    <div class="ui divider custom-divider--profil m-t-2 m-b-2" />

    <div class="img-be_save">
      <div class="img-device-container__wrap">
        <i class="gdm-information" />
        <div class="be_save-info">Le numéro de série de votre be save est visible sur l'étiquette située sous votre robot.</div>
      </div>
      <div class="img-serial-container__wrap">
        <img
          alt=""
          :src="require('images/club/be_save_serial.webp')"
        >
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      beSaveSerial: {
        type: String,
        default: ""
      },
    },
    data() {
      return {
        serial: this.beSaveSerial,
        saving: false
      }
    },
    computed: {
      touched() {
        return this.serial !== this.beSaveSerial 
      }
    },
    methods: {
      save() {
        this.saving = true

        this.$http.put(`/profil/${this.slug}`, { user: { be_save_serial: this.serial }})
        .then( () => {
          this.saving = false
          this.$emit('update', this.serial)
          window.globalFlashMessage("success", "Modifications enregistrées")
        })
        .catch( () => {
          this.saving = false
          window.globalFlashMessage("error", "Impossible d'enregistrer les modifications")
        })
      }
    },
  }
</script>

<style lang="stylus" scoped>
.action-button
  display: flex
  align-items: flex-end
</style>
