<template>
  <div>
    <div class="panel panel-default">
      <div class="panel-heading">
        <input
          v-if="editMode"
          v-model="data.url"
          class="form-control url-input"
          placeholder="URL de redirection"
        />
        <span v-else>
          <a :href="data.url" target="_blank">
            {{ data.url }}
          </a>
        </span>
        <div>
          <button
            v-if="errors.length < 1"
            @click.prevent="toggleEditMode"
            class="btn btn-xs btn-success"
          >
            <i v-if="saving" class="fa fa-fw fa-spinner fa-spin"></i>
            <i
              v-else
              :class="editMode ? 'fa fa-fw fa-check' : 'fa fa-fw fa-edit'"
            ></i>
          </button>
          <button
            v-if="editMode && !!data.id"
            @click.prevent="cancelEdit"
            class="btn btn-xs btn-danger"
          >
            <i class="fa fa-fw fa-window-close"></i>
          </button>
          <button
            v-else
            @click.prevent="removePost"
            class="btn btn-xs btn-danger"
          >
            <i
              :class="
                removeLoading
                  ? 'fa fa-fw fa-spinner fa-spin'
                  : 'fa fa-fw fa-trash'
              "
            ></i>
          </button>
        </div>
      </div>
      <div class="panel-body">
        <div class="row">
          <div class="col-md-4 text-center">
            <image-input
              v-model="file"
              :current-image="{ url: displayImageUrl }"
              v-if="editMode"
              class="m-t-1"
            />
            <img
              v-else-if="data.image.url"
              :src="data.image.url"
              width="100%"
            />
            <div
              class="text-left m-t-3 form-group"
              :class="{
                'has-error': errors.includes('duration'),
              }"
            >
              <label>
                Durée d'affichage :
              </label>
              <div v-if="editMode" class="input-group col-md-4">
                <input
                  v-model.number="data.duration"
                  type="number"
                  class="form-control"
                />
                <span class="input-group-addon">jour(s)</span>
              </div>
              <span v-else>{{ data.duration }} jour(s)</span>
            </div>
            <div class="text-left m-t-1 form-group">
              <label>Post actif : </label>
              <i
                v-if="editMode"
                class="toggle-icon fas"
                :class="{
                  'fa-toggle-on': data.active,
                  'fa-toggle-off': !data.active,
                }"
                @click="toggleActivity"
              />
              <span v-else>{{ data.active ? 'Oui' : 'Non' }}</span>
            </div>
          </div>

          <div class="col-md-8">
            <div
              class="form-group"
              :class="{
                'has-error': errors.includes('user'),
              }"
            >
              <label>Auteur</label>
              <div v-if="editMode">
                <user-select v-model="data.user" :disabled="saving" />
              </div>
              <div v-else-if="data.user">
                <img
                  v-if="data.user.image.url"
                  :src="data.user.image.url"
                  class="author-image"
                />
                {{ data.user.first_name }} {{ data.user.last_name }} ({{
                  data.user.email
                }})
              </div>
              <div v-else>
                N / A
              </div>
            </div>
            <div
              class="form-group m-t-1"
              :class="{
                'has-error': errors.includes('duration'),
              }"
            >
              <label>Contenu</label>
              <vue-mce
                v-if="editMode"
                v-model="data.content"
                :config="{
                  toolbar:
                    'styleselect | bold italic underline | undo redo | link | media | image | code',
                  plugins: 'link image media code',
                }"
              />
              <div class="content" v-else v-html="data.content">Contenu</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageInput from '@back/components/ImageInput.vue'
import UserSelect from '@back/components/UserSelect.vue'

export default {
  components: {
    ImageInput,
    UserSelect,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editMode: !this.post.id,
      saving: false,
      removeLoading: false,
      data: { ...this.post },
      file: null,
    }
  },
  computed: {
    errors() {
      let errors = []
      if (!this.data.duration) {
        errors.push('duration')
      }

      if (!this.data.content) {
        errors.push('content')
      }

      if (!this.data.user || _.isEmpty(this.data.user)) {
        errors.push('user')
      }

      return errors
    },
    displayImageUrl() {
      if (this.file) {
        return URL.createObjectURL(this.file)
      } else {
        return this.data.image.url
      }
    },
    payload() {
      let payload = new FormData()
      payload.append('welcome_post[url]', this.data.url)
      payload.append('welcome_post[content]', this.data.content)
      payload.append('welcome_post[duration]', this.data.duration)
      payload.append('welcome_post[active]', this.data.active)
      payload.append('welcome_post[user_id]', this.data.user.id)

      if (this.file) {
        payload.append('welcome_post[image]', this.file)
      }

      return payload
    },
  },
  methods: {
    toggleEditMode() {
      if (this.editMode) {
        !!this.post.id ? this.updatePost() : this.createPost()
      } else {
        this.editMode = true
      }
    },
    updatePost() {
      this.saving = true
      this.$http
        .put(`/back/welcome_posts/${this.post.id}`, this.payload)
        .then(({ data }) => {
          this.data = data
          this.editMode = false
          this.saving = false
        })
        .catch(() => {
          this.cancelEdit()
        })
    },
    createPost() {
      this.saving = true
      this.$http
        .post('/back/welcome_posts', this.payload)
        .then(({ data }) => {
          this.$emit('created', { ...this.post, ...data })
          this.editMode = false
          this.saving = false
        })
        .catch((error) => {
          this.saving = false
        })
    },
    removePost() {
      if (this.data.tmpId) {
        this.$emit('remove', this.post)
        return
      } else {
        this.$http
          .delete(`/back/welcome_posts/${this.post.id}`)
          .then((response) => {
            this.$emit('remove', this.post)
          })
      }
    },
    cancelEdit() {
      this.data = { ...this.post }
      this.file = null
      this.editMode = false
      this.saving = false
    },
    toggleActivity() {
      if (this.editMode) {
        this.data.active = !this.data.active
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
.panel-heading
  display: flex
  justify-content: space-between
  align-items: center
  .url-input
    width: 80%
.body-content
  display: flex
  align-items: center
.author-image
  margin-right: 15px
  width: 30px
.content
  border: 1px solid gray
  border-radius: 5px
.toggle-icon
  font-size: 25px
  vertical-align: sub
  cursor: pointer
  &.fa-toggle-on
    color: #4cae4c
  &.fa-toggle-off
    color: lightgray
</style>
