<template>
  <div>
    <div class="input-group m-b-1 text-center">
      <span class="input-group-btn">
        <span class="btn btn-primary btn-file">
          Choisir
          <input
            @change="previewVideo"
            type="file"
            accept="video/*"
          >
        </span>
      </span>
    </div>
    <video
      :src="displayVideoUrl"
      :width="width"
      controls
    />
  </div>
</template>

<script>
export default {
  props: {
    currentVideo: {
      type: Object,
      default: () => {
        return { url: null }
      }
    },
    width: {
      type: String,
      default: '100%'
    }
  },
  data() {
    return {
      videoFile: null
    }
  },
  computed: {
    displayVideoUrl() {
      if (this.videoFile) {
        return URL.createObjectURL(this.videoFile)
      } else {
        return this.currentVideo.url
      }
    }
  },
  methods: {
    previewVideo(e) {
      this.videoFile = e.target.files[0]
      this.$emit('input', this.videoFile)
    }
  }
}
</script>

<style lang="stylus" scoped>
.btn-file
  position: relative
  overflow: hidden
.btn-file input[type=file]
  position: absolute
  top: 0
  right: 0
  min-width: 100%
  min-height: 100%
  font-size: 999px
  text-align: right
  filter: unquote('alpha(opacity=0)')
  opacity: 0
  background: red
  cursor: inherit
  display: block
input[readonly]
  background-color: white !important
  cursor: text !important
</style>
