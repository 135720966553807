import Vue from 'vue'
import VueCarousel from 'vue-carousel'
import ThematicBlocsSliders from './ThematicBlocsSliders'

Vue.use(VueCarousel)
$(document).ready(() => {
  const el = $('#thematic-blocs-sliders')[0]

  if (el) {
    var thematicBlocsSliders = new Vue({
      el,
      render: (h) => h(ThematicBlocsSliders),
    })

    window.thematicBlocsSliders = thematicBlocsSliders
  }
})
