<template>
  <sui-modal @clickAwayModal="resetForm" v-model="open">
    <i @click="resetForm" class="close icon" />
    <sui-modal-header>
      Ajouter les ingrédients de
      <span class="text-gdm-red ml-0">{{ recipe.title }}</span> à une liste de
      courses
    </sui-modal-header>
    <sui-modal-content class="m-b-5">
      <form class="ui form">
        <div class="field" :class="{ error }">
          <label>Liste de courses</label>

          <input
            v-if="newListMode"
            type="text"
            placeholder="Entrez le titre de votre nouvelle liste"
            v-model="newListName"
            required
          />
          <template v-else>
            <sui-dropdown
              direction="downward"
              class="ui fluid dropdown custom-dropdown recipe-form__dropdown recipe-form__dropdown--small selection ui dropdown"
              :options="selectableLists"
              v-model="selectedList"
            />
            <a
              href="javascript:void(0)"
              class="text-gdm-red"
              @click="newListMode = true"
            >
              Ajouter à une nouvelle liste
            </a>
          </template>
        </div>
        <div class="field" v-show="!dataLoading">
          <label>Sélectionner les ingrédients à ajouter</label>
          <sui-dropdown
            direction="downward"
            class="ui fluid dropdown custom-dropdown recipe-form__dropdown recipe-form__dropdown--small selection ui dropdown"
            :options="selectableIngredientsOptions"
            :class="{ disabled: dataLoading }"
            v-model="selectedIngredientOptions"
          />
        </div>
        <div
          v-if="selectedIngredientOptions === 'custom'"
          class="ui three column stackable grid doubling-xs m-t-1"
        >
          <div
            v-if="group.ingredients_list.length"
            v-for="group in recipeData.groups"
            :key="group.id"
            class="column"
          >
            <span class="ui checkbox group-ingredients-title">
              <input
                type="checkbox"
                :checked="selectedPreparations.includes(group.id)"
                @change="
                  togglePreparation(
                    group.ingredients_list.map((ing) => ing.id),
                    $event.target.checked
                  )
                "
              />
              <label>{{ group.label }}</label>
              <div class="ui divider"></div>
            </span>
            <ul class="group-ingredients-list">
              <li
                v-for="ingredient in group.ingredients_list"
                class="ingredient-checkbox"
                :key="`ing-${ingredient.id}`"
              >
                <div class="ui checkbox">
                  <input
                    type="checkbox"
                    v-model="customIngredientsList"
                    :value="ingredient.id"
                  />
                  <label>
                    {{ ingredient.label }}
                  </label>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </form>
    </sui-modal-content>
    <sui-modal-actions class="no-border transparent">
      <div
        @click="resetForm"
        class="ui deny button custom-button custom-button--filaire"
      >
        Annuler
      </div>
      <sui-button
        @click="addToList"
        :disabled="error || dataLoading"
        :loading="loading"
        class="ui positive button custom-button"
      >
        Valider
      </sui-button>
    </sui-modal-actions>
  </sui-modal>
</template>

<script>
const $data = $('#shopping-lists-data')
export default {
  data() {
    return {
      recipe: {},
      dataLoading: false,
      selectedIngredientOptions: 'all',
      lists: $data.data('lists'),
      selectedList: ($data.data('lists')[0] || {}).id,
      newListMode: !$data.data('lists').length,
      newListName: null,
      open: false,
      loading: false,
      customIngredientsList: [],
    }
  },
  computed: {
    ingredientIds() {
      if (this.selectedIngredientOptions === 'all') {
        return this.recipeData.groups
          .map((g) => g.ingredients_list.map((i) => i.id))
          .flat()
      } else if (this.selectedIngredientOptions === 'custom') {
        return this.customIngredientsList
      } else {
        return this.recipeData.groups
          .find((g) => g.id == this.selectedIngredientOptions)
          .ingredients_list.map((i) => i.id)
      }
    },
    selectedPreparations() {
      return this.recipeData.groups
        .filter((g) => {
          return g.ingredients_list
            .map((i) => i.id)
            .every((id) => this.customIngredientsList.includes(id))
        })
        .map((g) => g.id)
    },
    error() {
      return this.newListName === ''
    },
    selectableLists() {
      return this.lists.map((list) => {
        return {
          value: list.id,
          text: list.title,
        }
      })
    },
    selectableIngredientsOptions() {
      let options = [
        { value: 'all', text: 'Tous les ingrédients de la recette' },
      ]

      for (let group of this.recipeData.groups || []) {
        if (group.ingredients_list.length) {
          options.push({
            value: group.id,
            text: `Uniquement les ingrédients de la préparation : ${group.label}`,
          })
        }
      }

      options.push({
        value: 'custom',
        text: 'Sélectionner les ingrédients manuellement',
      })

      return options
    },
  },
  asyncComputed: {
    recipeData: {
      async get() {
        if (this.recipe.slug) {
          this.dataLoading = true
          const response = await this.$http.get(
            `/recettes/${this.recipe.slug}.json`
          )

          this.dataLoading = false
          return response.data
        } else {
          return {}
        }
      },
      default() {
        return {}
      },
    },
  },
  methods: {
    togglePreparation(ids, status) {
      if (status) {
        this.customIngredientsList = [
          ...new Set([...this.customIngredientsList, ...ids]),
        ]
      } else {
        this.customIngredientsList = this.customIngredientsList.filter(
          (id) => !ids.includes(id)
        )
      }
    },
    resetForm() {
      this.open = false
      this.newListMode = false
      this.newListName = null
      this.lists = $data.data('lists')
      this.selectedList = this.lists[0].id
    },
    async addToList() {
      this.loading = true

      let listId = null

      if (this.newListMode) {
        if (!!this.newListName) {
          const response = await this.$http.post(`/shopping_lists`, {
            shopping_list: { title: this.newListName },
          })

          const list = response.data

          listId = list.id

          if ($('#shopping-lists-data').length) {
            $('#shopping-lists-data').data('lists', [
              list,
              ...$('#shopping-lists-data').data('lists'),
            ])
          }
        } else {
          this.newListName = ''
          this.loading = false

          return
        }
      } else {
        listId = this.selectedList
      }

      await this.$http.post(`/shopping_lists/${listId}/add_ingredients`, {
        ingredient_ids: this.ingredientIds,
      })
      this.loading = false
      window.globalFlashMessage(
        'success',
        'Les ingrédients ont bien été ajoutés à la liste'
      )
      this.resetForm()
    },
  },
}
</script>

<style lang="scss" scoped>
.group-ingredients-list {
  padding: 0;
  margin: 0;
}
.ingredient-checkbox {
  list-style: none;
  margin-bottom: 5px;
}
.group-ingredients-title {
  label {
    font-weight: bold;
  }
}
.text-gdm-red {
  color: #e83b47;
  margin-left: 5px;
}
.ml-0 {
  margin-left: 0 !important;
}
.dropdown {
  margin-bottom: 5px;
}
.header {
  border-bottom: none !important;
}
.button:focus {
  background-color: #e83b47 !important;
}
</style>
