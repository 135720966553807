import Vue from 'vue'

// Support for semantic-ui components
import SuiVue from 'semantic-ui-vue'
Vue.use(SuiVue)

import vueDebounce from 'vue-debounce'
Vue.use(vueDebounce)

// Shared I18n from rails app
Vue.prototype.$i18n = window.I18n

import VueClosable from 'vue-closable'
Vue.use(VueClosable)

// Setup for ajax requests
import axios from 'axios'
import VueAxios from 'vue-axios'

const csrfToken = document.querySelector('meta[name=csrf-token]').content

axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
axios.defaults.headers.common['Accept'] = 'application/json'
Vue.use(VueAxios, axios)

// FridgeRecommendations
import FridgeRecommendations from './FridgeRecommendations'

$(document).ready(() => {
  const el = $('#vue-fridge-recommendations')[0]

  if (el) {
    var fridgeRecommendations = new Vue({
      el,
      render: (h) => h(FridgeRecommendations),
    })

    window.fridgeRecommendations = fridgeRecommendations
  }
})
