<template>
  <div class="section-white">
    <h2 class="profile_top-title">
      <i class="gdm-ick" />
      {{ $i18n.t('activerecord.attributes.user.icookin') }}
    </h2>

    <p class="little-sub-text">
      {{ $i18n.t('elements.user.text.icookin_text') }}
    </p>

    <div class="img-icookin">
      <div class="img-device-container__wrap">
        <i class="gdm-information" />
        <div class="icookin-info">
          Rendez-vous dans les paramètres de votre i-cook'in ou vérifiez l'étiquette située sous votre robot.
        </div>
      </div>
      <div class="img-serial-container__wrap">
        <img
          alt=""
          :src="require('images/club/icookin-serial.webp')"
        >
      </div>
    </div>

    <div class="ui divider custom-divider--profil m-t-2 m-b-2" />

    <div class="fields">
      <div class="five wide field">
        <label>
          {{ $i18n.t('activerecord.attributes.user.icookin_serial') }}
          <input
            type="text"
            v-model="newDevice.serialNumber"
          />
        </label>
      </div>

      <div class="five wide field">
        <label>
          {{ $i18n.t('activerecord.attributes.user.icookin_number') }}
          <input
            type="text"
            v-model="newDevice.icookinNumber"
          />
        </label>
      </div>

      <div class="five wide field">
        <label>
          Alias
          <input
            type="text"
            v-model="newDevice.alias"
          />
        </label>
      </div>

      <div class="two wide field action-button">
        <label>
          <button
            class="ui icon button custom-button"
            :disabled="registering || !validRegistration"
            @click.prevent="registerDevice"
          >
            <i
              class="fa fa-fw"
              :class="registering ? 'fa-spinner fa-spin' : 'fa-plus-circle'"
            />
          </button>
        </label>
      </div>
    </div>

    <div
      v-if="devices.length"
      class="ui divider custom-divider--profil m-t-1 m-b-1"
    />

    <registered-icookin
      v-for="device of devices"
      :key="device.id"
      :icookin="device"
      @removeDevice="removeDevice"
      @updateDevice="updateDevice"
    />
  </div>
</template>

<script>
  import RegisteredIcookin from './RegisteredIcookin'
  import _findIndex from 'lodash/findIndex'

  export default {
    components: {
      RegisteredIcookin,
    },
    props: {
      registeredDevices: {
        type: Array,
        required: true
      },
    },
    data() {
      return {
        devices: [ ...this.registeredDevices ],
        newDevice: {
          serialNumber: '',
          icookinNumber: '',
          alias: ''
        },
        registering: false
      }
    },
    computed: {
      validRegistration() {
        return this.newDevice.serialNumber && this.newDevice.icookinNumber
      }
    },
    methods: {
      registerDevice() {
        if (this.validRegistration) {
          this.registering = true
  
          const payload = {
            device_user_membership: {
              serial_number: this.newDevice.serialNumber,
              icookin_number: this.newDevice.icookinNumber,
              alias: this.newDevice.alias,
            }
          }
  
          this.$http.post('/device_user_memberships', payload)
          .then( ({ data }) => {
            this.devices.unshift(data)

            this.newDevice.serialNumber = ''
            this.newDevice.icookinNumber = ''
            this.newDevice.alias = ''

            window.globalFlashMessage("success", "Modifications enregistrées")
            this.registering = false

          })
          .catch( (error) => {
            if (error.response && error.response.data) {
              const errors = error.response.data.error

              if (errors) {
                window.globalFlashMessage("error", `Erreur : ${errors[errors.length - 1]}`)
              } else {
                window.globalFlashMessage("error", "Impossible d'enregistrer les modifications ")
              }
            } else {
              console.log("Erreur : ", error)
            }

            this.registering = false
          })
        }
      },
      removeDevice(deviceId) {
        this.$set(this.devices, this.devices.splice(_findIndex(this.devices, { id: deviceId }), 1))
      },
      updateDevice(device) {
        this.$set(this.devices, this.devices.splice(_findIndex(this.devices, { id: device.id }), 1, device))
      }
    },
  }
</script>

<style lang="stylus" scoped>
.action-button {
  display: flex;
  align-items: flex-end;
}
</style>
