<template>
  <tr class="text-center">
    <template v-if="editMode">
      <td>
        <image-input
          v-model="horizontalImageFile"
          :current-image="block.horizontal_image"
          width="100px"
        />
      </td>
      <td>
        <image-input
          v-model="verticalImageFile"
          :current-image="block.vertical_image"
          width="100px"
        />
      </td>
      <td>
        <input
          v-model="data.url"
          type="text"
          placeholder="Entrez l'url du block"
          class="form-control"
          @keyup.enter="saveBlock"
        />
      </td>
      <td>
        <button
          @click.prevent="saveBlock"
          class="btn btn-success btn-sm"
        >
          <i :class="saving ? 'fa fa-fw fa-spinner fa-spin' : 'fa fa-fw fa-check'"></i>
        </button>
        <button
          @click.prevent="cancelEdit"
          class="btn btn-danger btn-sm"
        >
          <i class="fa fa-fw fa-window-close"></i>
        </button>
      </td>
    </template>
    <template v-else>
      <td>
        <img
          v-if="block.horizontal_image.url"
          :src="block.horizontal_image.url"
          width="100px"
        />
      </td>
      <td>
        <img
          v-if="block.vertical_image.url"
          :src="block.vertical_image.url"
          width="100px"
        />

      </td>
      <td>
        <a
          v-if="block.url"
          href="https://www.google.fr"
          target="_blank"
        >
          {{ block.url }}
        </a>
        <span v-else>
          N\A
        </span>
      </td>

      <td>
        <button
          @click.prevent="editMode = true"
          class="btn btn-success btn-sm"
        >
          <i class="fa fa-fw fa-edit"></i>
        </button>
      </td>
    </template>
  </tr>
</template>

<script>
import ImageInput from '@back/components/ImageInput.vue'
export default {
  components: { ImageInput },
  props: {
    block: {
      type: Object,
      required: true
    },
    pageId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      editMode: false,
      data: { ...this.block },
      horizontalImageFile: null,
      verticalImageFile: null,
      saving: false
    }
  },
  methods: {
    cancelEdit() {
      this.data = { ...this.block }
      this.editMode = false
    },
    saveBlock() {
      let payload = new FormData()

      payload.append('cta_block[url]', this.data.url)
      if (this.verticalImageFile) {
        payload.append('cta_block[vertical_image]', this.verticalImageFile)
      }
      if (this.horizontalImageFile) {
        payload.append('cta_block[horizontal_image]', this.horizontalImageFile)
      }
      this.saving = true
      this.$http
        .put(`/back/club_home_pages/${this.pageId}/cta_blocks/${this.block.id}`, payload)
        .then(response => {
          this.editMode = false
          this.saving = false
          this.$emit('update', response.data)
        })
    }
  }
}
</script>

<style lang="stylus" scoped>
td
  padding: 10px !important
</style>
