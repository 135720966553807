<template>
  <div id="member" class="tab-pane">
    <div class="panel panel-default">
      <div class="panel-heading">
        <h4 class="box-title">
          Membre du moment
        </h4>
      </div>
      <div class="panel-body">
        <div class="row">
          <div class="col-md-6 col-md-offset-3">
            <div class="panel panel-default">
              <div v-if="editMode" class="panel-heading">
                <div class="col-md-9">
                  <user-select v-model="selectedMember" :disabled="saving" />
                </div>
                <div>
                  <button @click="save" class="btn btn-sm btn-success">
                    <i v-if="saving" class="fa fa-fw fa-spin fa-spinner" />
                    <i v-else class="fa fa-fw fa-check" />
                  </button>
                  <button @click="cancelEdit" class="btn btn-sm btn-danger">
                    <i class="fa fa-fw fa-window-close" />
                  </button>
                </div>
              </div>
              <div v-else class="panel-heading">
                <a :href="'/back/users/' + data.slug" target="_blank">
                  {{ data.email }} ( {{ data.username }} )
                </a>
                <div>
                  <button
                    class="btn btn-sm btn-success"
                    :disabled="loading"
                    @click="editMode = true"
                  >
                    <i class="fa fa-fw fa-edit" />
                  </button>
                </div>
              </div>
              <div class="panel-body">
                <div class="row">
                  <div class="col-md-4">
                    <img
                      :src="data.image.url"
                      v-if="data.image.url"
                      width="100%"
                    />
                  </div>
                  <div class="col-md-8">
                    <h5>{{ data.first_name }} {{ data.last_name }}</h5>
                    <div>
                      <a
                        :href="'/recettes?lock=users&users=' + data.username"
                        class="btn btn-success"
                        target="_blank"
                      >
                        Recettes
                      </a>
                      <a
                        :href="'/profil/' + data.slug"
                        class="btn btn-primary"
                        target="_blank"
                      >
                        Profil Club
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserSelect from '@back/components/UserSelect.vue'

export default {
  components: {
    UserSelect,
  },
  props: {
    page: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data: { ...this.page.member_of_the_day },
      editMode: false,
      selectedMember: null,
      loading: false,
      saving: false,
    }
  },
  methods: {
    save() {
      this.saving = true
      this.$http
        .put(`/back/club_home_pages/${this.page.id}`, {
          club_home_page: {
            member_of_the_day_id: this.selectedMember.id,
          },
        })
        .then(() => {
          this.data = this.selectedMember
          this.selectedMember = null
          this.saving = false
          this.editMode = false
        })
        .catch(() => {
          this.cancelEdit()
        })
    },
    cancelEdit() {
      this.data = this.page.member_of_the_day
      this.selectedMember = null
      this.editMode = false
    },
  },
}
</script>

<style lang="stylus" scoped>
.panel-heading
  display: flex
  justify-content: space-between
  align-items: center
  .url-input
    width: 80%
</style>
