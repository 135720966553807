<template>
  <sui-modal v-model="open" size="tiny" class="transition custom-modal" @clickAwayModal="$emit('onClose')">
    <i class="close icon" @click="$emit('onClose')"></i>
    <sui-modal-header class="no-border">
      {{ capitalize($i18n.t('elements.recipe.text.image_right')) }}
    </sui-modal-header>
    <sui-modal-content>
      <div class="ui form">
        <p class="custom-text">
          {{ capitalize($i18n.t('elements.recipe.text.please_fill')) }}
        </p>
        <sui-message error style="position: relative; display: block" v-if="Object.keys(errors).length">
          <ul>
            <li v-for="key in Object.keys(errors)" :key="key">
              {{ capitalize($i18n.t(`activerecord.attributes.recipe.${key}`)) }} {{ errors[key][0] }}
            </li>
          </ul>
        </sui-message>
        <form class="image_form">
          <div class="ui divider"></div>
          <div class="field" :class="{ error: errors.author_image }">
            <label>
              {{ capitalize($i18n.t('activerecord.attributes.recipe.author_image')) }}
            </label>
            <input class="ui input" type="text" v-model="author" />
          </div>
          <div class="field" :class="{ error: errors.author_image_link }">
            <label>
              {{ capitalize($i18n.t('activerecord.attributes.recipe.author_image_link')) }}
            </label>
            <input class="ui input" type="url" v-model="authorLink" />
          </div>
        </form>
      </div>
    </sui-modal-content>
    <sui-modal-actions class="no-border transparent">
      <sui-button deny class="custom-button custom-button--filaire" @click="$emit('onClose')">
        {{ capitalize($i18n.t('elements.global.buttons.cancel')) }}
      </sui-button>
      <sui-button positive :loading="loading" class="custom-button" @click="updateImageRight">
        {{ capitalize($i18n.t('elements.global.buttons.valid')) }}
      </sui-button>
    </sui-modal-actions>
  </sui-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import { inflection, api } from './mixins'

export default {
  mixins: [inflection, api],
  props: ['open'],
  data () {
    return {
      author: '',
      authorLink: '',
      loading: false,
      errors: {}
    }
  },
  computed: {
    ...mapGetters(['recipeData'])
  },
  methods: {
    updateImageRight () {
      const updateParams = {
        recipe: {
          author_image: this.author || '',
          author_image_link: this.authorLink || ''
        }
      }

      this.loading = true
      this.sendRequest('put', this.recipePath, updateParams)
      .then( () => {
        this.errors = {}
        this.loading = false
        this.$emit('onClose')
      })
      .catch( (error) => {
        this.loading = false
        this.errors = error.response.data
      })
    }
  },
  created () {
    this.author = this.recipeData.author_image
    this.authorLink = this.recipeData.author_image_link
  }
}
</script>

<style lang="stylus" scoped>
</style>
