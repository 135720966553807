<template>
 <sui-modal v-model="open" size="small" class="transition custom-modal" @clickAwayModal="$emit('onClose')">
    <i class="close icon" @click="$emit('onClose')"></i>
    <sui-modal-header class="no-border">
      {{ capitalize($i18n.t('elements.recipe.modal.delete_recipe_title')) }}
    </sui-modal-header>
    <sui-modal-content>
      {{ $i18n.t('elements.recipe.modal.delete_recipe_content') }}
    </sui-modal-content>
    <sui-modal-actions class="no-border transparent">
      <sui-button deny class="custom-button custom-button--filaire" @click.prevent="$emit('onClose')">
        {{ capitalize($i18n.t('elements.global.buttons.cancel')) }}
      </sui-button>
      <sui-button :loading="loading" positive class="custom-button" @click.prevent="deleteRecipe">
        {{ capitalize($i18n.t('elements.global.buttons.valid')) }}
      </sui-button>
    </sui-modal-actions>
  </sui-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import { inflection, api } from './mixins'

export default {
  mixins: [inflection, api],
  props: ['open'],
  data () {
    return {
      loading: false
    }
  },
  methods: {
    deleteRecipe () {
      this.loading = true
      this.sendRequest('delete', this.recipePath)
      .then( (response) => {
        window.location.href = response.data.path
      })
      .catch( () => this.loading = false )
    }
  }
}
</script>

<style lang="stylus" scoped>
</style>
