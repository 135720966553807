<template>
  <sui-modal
    v-model="open"
    size="tiny"
    class="transition custom-modal"
    @clickAwayModal="$emit('onClose')"
  >
    <i class="close icon" @click="$emit('onClose')"></i>
    <sui-modal-header class="no-border">
      {{ capitalize($i18n.t('elements.recipe.buttons.video')) }}
    </sui-modal-header>
    <sui-modal-content>
      <div class="ui form">
        <p class="custom-text">
          {{ capitalize($i18n.t('elements.recipe.text.please_fill')) }}
        </p>
        <sui-message
          error
          style="position: relative; display: block"
          v-if="Object.keys(errors).length"
        >
          <ul>
            <li v-for="key in Object.keys(errors)" :key="key">
              {{ capitalize($i18n.t(`activerecord.attributes.recipe.${key}`)) }}
              {{ errors[key][0] }}
            </li>
          </ul>
        </sui-message>
        <form class="video_form">
          <div
            class="field"
            :class="{ error: errors.video }"
            v-if="currentUser.profile === 'gdm'"
          >
            <label>Video embed script</label>
            <textarea class="ui input video-url" v-model="videoEmbed" />
          </div>
          <div class="field" :class="{ error: errors.video }" v-else>
            <label>
              {{
                capitalize($i18n.t('activerecord.attributes.recipe.video_link'))
              }}
            </label>
            <input class="ui input video-url" type="url" v-model="videoLink" />
          </div>
          <h3>{{ capitalize($i18n.t('elements.recipe.text.video_right')) }}</h3>
          <p class="custom-text">
            {{ capitalize($i18n.t('elements.recipe.text.please_fill')) }}
          </p>
          <div class="ui divider"></div>

          <div class="field" :class="{ error: errors.author_video }">
            <label>
              {{
                capitalize(
                  $i18n.t('activerecord.attributes.recipe.author_video')
                )
              }}
            </label>
            <input class="ui input video-author" type="text" v-model="author" />
          </div>

          <div class="field" :class="{ error: errors.author_video_link }">
            <label>
              {{
                capitalize(
                  $i18n.t('activerecord.attributes.recipe.author_video_link')
                )
              }}
            </label>
            <input
              class="ui input video-author-url"
              type="url"
              v-model="authorLink"
            />
          </div>
        </form>
      </div>
    </sui-modal-content>
    <sui-modal-actions class="no-border transparent">
      <sui-button
        deny
        class="custom-button custom-button--filaire"
        @click="$emit('onClose')"
      >
        {{ capitalize($i18n.t('elements.global.buttons.cancel')) }}
      </sui-button>
      <sui-button positive class="custom-button" @click="updateVideo">
        {{ capitalize($i18n.t('elements.global.buttons.valid')) }}
      </sui-button>
    </sui-modal-actions>
  </sui-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import { inflection, api } from './mixins'

export default {
  mixins: [inflection, api],
  props: ['open'],
  data() {
    return {
      videoLink: '',
      videoEmbed: '',
      author: '',
      authorLink: '',
      loading: false,
      errors: {},
      currentUser: $('#current-user').data('user'),
    }
  },
  computed: {
    ...mapGetters(['recipeData']),
  },
  methods: {
    updateVideo() {
      const updateParams = {
        recipe: {
          video: this.videoLink || '',
          author_video: this.author || '',
          author_video_link: this.authorLink || '',
          video_embed: this.videoEmbed || '',
        },
      }
      this.loading = true

      this.sendRequest('put', this.recipePath, updateParams)
        .then((response) => {
          this.errors = {}
          this.$store.dispatch('updateRecipe', response.data)
          this.loading = false
          this.$emit('onClose')
        })
        .catch((error) => {
          this.loading = false
          this.errors = error.response.data
        })
    },
  },
  created() {
    this.videoLink = this.recipeData.video
    this.author = this.recipeData.author_video
    this.authorLink = this.recipeData.author_video_link
  },
}
</script>

<style lang="stylus" scoped></style>
