<template>
  <div class="panel panel-default">
    <div class="panel-heading heading">
      <h4 class="box-title">
        Statistiques
        <i
          v-if="loading"
          class="fa fa-fw fa-spinner fa-spin"
        ></i>
      </h4>
      <div class="form-inline">
        <div class="form-group">
          <label>Du</label>
          <date-input v-model="periodStart" />
        </div>
        <div class="form-group">
          <label>Au</label>
          <date-input v-model="periodEnd" />
        </div>
      </div>
    </div>
    <div class="panel-body">
      <div class="row-fluid">
        <ul class="nav nav-tabs">
          <li class="active">
            <a
              href="#graph"
              aria-controls="graph"
              data-toggle="tab"
            >Nombre de likes</a>
          </li>
          <li>
            <a
              href="#rankings"
              aria-controls="rankings"
              data-toggle="tab"
            >
              Classement des posts
            </a>
          </li>
        </ul>

        <div class="tab-content">
          <div
            class="tab-pane active"
            id="graph"
          >
            <g-chart
              v-if="stats"
              type="AreaChart"
              @ready="onChartReady"
            />
          </div>

          <div
            class="tab-pane"
            id="rankings"
          >
            <table
              v-if="ranking.length > 0"
              class="table table-bordered table-hover"
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th>Image</th>
                  <th>Auteur</th>
                  <th>Contenu</th>
                  <th>Nombre de likes / jour</th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="(post, index) of ranking"
                  :key="index"
                >
                  <td>{{ index + 1 }}</td>
                  <td>
                    <img
                      v-if="post.image.url"
                      :src="post.image.url"
                      width="150"
                    />
                  </td>
                  <td>
                    <img
                      v-if="post.user.image.url"
                      :src="post.user.image.url"
                      class="author-image"
                      width="30"
                    />
                    {{ post.user.first_name }} {{ post.user.last_name }} ({{ post.user.email }})
                  </td>
                  <td>
                    {{ truncate(post.content.replace(/<\/?[^>]+(>|$)/g, ""), 150) }}
                  </td>
                  <td>
                    {{ Math.round(post.avg * 100) / 100 }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateInput from '@back/components/DateInput.vue'

export default {
  components: {
    DateInput
  },
  data() {
    return {
      chart: null,
      google: null,
      statsLoading: false,
      rankingLoading: false,
      stats: {},
      ranking: [],
      periodStart: moment()
        .subtract(1, 'month')
        .format('DD-MM-YYYY'),
      periodEnd: moment().format('DD-MM-YYYY')
    }
  },
  computed: {
    loading() {
      return this.statsLoading || this.rankingLoading
    },
    statsData() {
      let data = [['Date', 'Membres', 'Conseillers', 'Autres']]

      for (let key of Object.keys(this.stats)) {
        data.push([
          new Date(key),
          this.stats[key].members || 0,
          this.stats[key].advisors || 0,
          this.stats[key].others || 0
        ])
      }

      return data
    },
    chartOptions() {
      return {
        isStacked: true,
        legend: { position: 'bottom' },
        height: 500,
        explorer: {
          actions: ['dragToZoom', 'rightClickToReset'],
          axis: 'horizontal',
          keepInBounds: true,
          maxZoomIn: 4.0
        },
        vAxis: { minValue: 0, viewWindow: { min: 0 }, format: '0' },
        hAxis: {
          format: 'dd-MM-y'
        }
      }
    }
  },
  watch: {
    periodStart: {
      handler: 'getData'
    },
    periodEnd: {
      handler: 'getData'
    },
    stats: {
      handler: 'drawChart',
      deep: true
    }
  },
  methods: {
    truncate: _.truncate,
    getData() {
      if (
        moment(this.periodEnd, 'DD-MM-YYYY').isBefore(
          moment(this.periodStart, 'DD-MM-YYYY')
        )
      ) {
        let swap = this.periodEnd
        this.periodEnd = this.periodStart
        this.periodStart = swap
      } else {
        this.getStats()
        this.getRanking()
      }
    },
    onChartReady(chart, google) {
      this.chart = chart
      this.google = google

      this.drawChart()
    },
    drawChart() {
      if (this.google && Object.keys(this.stats || {}).length > 0) {
        let formatter = new this.google.visualization.DateFormat({
          pattern: 'dd-MM-y'
        })

        const data = this.google.visualization.arrayToDataTable(this.statsData)

        formatter.format(data, 0)

        this.chart.draw(data, this.chartOptions)
      }
    },
    getStats() {
      this.statsLoading = true
      this.$http
        .get(
          `/back/gdm_posts/stats?start_date=${this.periodStart}&end_date=${this.periodEnd}`
        )
        .then(({ data }) => {
          this.stats = data
          this.statsLoading = false
        })
    },
    getRanking() {
      this.rankingLoading = true
      this.$http
        .get(
          `/back/gdm_posts/ranking?start_date=${this.periodStart}&end_date=${this.periodEnd}`
        )
        .then(({ data }) => {
          this.ranking = data
          this.rankingLoading = false
        })
    }
  },
  created() {
    this.getData()
  }
}
</script>

<style lang="css">
#google-visualization-errors-all-1,
#google-visualization-errors-all-2,
#google-visualization-errors-all-3,
#google-visualization-errors-all-4,
#google-visualization-errors-all-5,
#google-visualization-errors-all-6,
#google-visualization-errors-all-7,
#google-visualization-errors-all-8,
#google-visualization-errors-all-9,
#google-visualization-errors-all-10,
#google-visualization-errors-all-11,
#google-visualization-errors-all-12,
#google-visualization-errors-all-13,
#google-visualization-errors-all-14 {
  display: none!important;
}
</style>

<style lang="stylus" scoped>
.heading
  display: flex
  justify-content: space-between
table
  margin-top: 20px
  tbody
    tr
      td
        padding: 10px
</style>
