<template>
  <div class="panel panel-default">
    <div class="panel-heading">
      <input
        type="text"
        v-if="editMode"
        class="form-control url-input"
        v-model="data.url"
      />
      <span v-else>
        <a :href="data.url" target="_blank">
          {{ data.url }}
        </a>
      </span>
      <div>
        <button
          v-if="!editMode"
          @click.prevent="editMode = true"
          class="btn btn-xs btn-success"
        >
          <i class="fa fa-fw fa-edit" />
        </button>
        <template v-else>
          <button
            @click.prevent="save"
            :disabled="saving"
            class="btn btn-success btn-xs"
          >
            <i
              :class="
                saving ? 'fa fa-fw fa-spinner fa-spin' : 'fa fa-fw fa-check'
              "
            />
          </button>
          <button @click.prevent="cancelEdit" class="btn btn-xs btn-danger">
            <i class="fa fa-fw fa-window-close" />
          </button>
        </template>
      </div>
    </div>
    <div class="panel-body">
      <div class="row">
        <div class="col-md-2 text-center">
          <img
            :src="data.image.url"
            v-if="data.image.url && !editMode"
            width="150px"
          />
          <image-input
            v-if="editMode"
            v-model="file"
            :current-image="{ url: data.image.url }"
          />
        </div>
        <div v-if="editMode" class="col-md-10">
          <input
            v-model="data.title"
            type="text"
            class="form-control"
            placeholder="Titre"
          />
          <textarea
            v-model="data.content"
            class="form-control m-t-1"
            placeholder="Contenu"
          />
        </div>
        <div class="col-md-10" v-else>
          <h5>{{ data.title }}</h5>
          <p>{{ data.content }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageInput from '@back/components/ImageInput.vue'

export default {
  components: {
    ImageInput,
  },
  props: {
    info: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    pageId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      data: { ...this.info },
      editMode: false,
      saving: false,
      file: null,
    }
  },
  computed: {
    payload() {
      let payload = new FormData()
      let index = this.index + 1
      payload.append(
        `club_home_page[more_information${index}_title]`,
        this.data.title
      )
      payload.append(
        `club_home_page[more_information${index}_url]`,
        this.data.url
      )
      payload.append(
        `club_home_page[more_information${index}_content]`,
        this.data.content
      )

      if (this.file) {
        payload.append(
          `club_home_page[more_information${index}_image]`,
          this.file
        )
      }

      return payload
    },
  },
  methods: {
    cancelEdit() {
      this.data = { ...this.hint }
      this.file = null
      this.editMode = false
    },
    save() {
      this.saving = true
      this.$http
        .put(`/back/club_home_pages/${this.pageId}`, this.payload)
        .then(({ data }) => {
          this.$emit('update', data.more_information)
          this.data.image = data.more_information[this.index].image
          this.saving = false
          this.editMode = false
        })
        .catch((error) => {
          this.cancelEdit()
        })
    },
  },
}
</script>

<style lang="stylus" scoped>
.panel-heading
  display: flex
  justify-content: space-between
  align-items: center
  .url-input
    width: 80%
</style>
