<template>
  <div class="ui grid d-flex justify-center">
    <div class="twelve wide column m-b-1">
      <div class="ui item">
        <div class="ui fluid category search general navigation custom-search">
          <div class="ui icon input align-baseline">
            <delayed-input v-model="search" />
            <i
              v-if="loading"
              class="fa fa-fw fa-spinner fa-spin search-loader"
            />
            <i v-else class="gdm-search-logo icon" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="recipes.length" class="sixteen wide column recipes-container">
      <div class="ui stackable two column grid">
        <div class="column" v-for="recipe in recipes" :key="recipe.slug">
          <div
            class="recipe-card flex flex-row cursor-pointer"
            :class="{ loading }"
            @mouseover="hoverId = recipe.id"
            @mouseleave="hoverId = null"
          >
            <transition name="fade">
              <div v-if="recipe.id === hoverId" class="ui active dimmer">
                <div class="content flex flex-row">
                  <h4 @click="selectRecipe(recipe)">
                    <i class="fa fa-plus-circle" />
                    <p>Ajouter la recette</p>
                  </h4>
                  <h4 @click="openRecipe(recipe)">
                    <i class="gdm-icon gd-eye" />
                    <p>Voir la recette</p>
                  </h4>
                </div>
              </div>
            </transition>

            <div class="img-container m-r-1">
              <img
                :src="
                  recipe.image.url || require('images/recipe-placeholder.webp')
                "
              />
            </div>
            <div class="details">
              <div class="header">
                <h3>{{ _truncate(recipe.title, { length: '50' }) }}</h3>
                <span>Par {{ recipe.author_name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row m-t-2 text-center">
        <a
          class="ui button custom-button custom-button--icon more-recipes"
          :href="`/recettes?order=relevance&search_term=${search}`"
        >
          <i class="gdm-nav-ma-cuisine icon"></i>Voir plus de recettes !
        </a>
      </div>
    </div>
    <div v-else-if="!!search && !loading">
      <p class="m-b-2">Aucune recette trouvée pour cette recherche...</p>
    </div>
  </div>
</template>

<script>
import _truncate from 'lodash/truncate'
import DelayedInput from './DelayedInput'

export default {
  components: {
    DelayedInput,
  },
  data() {
    return {
      search: null,
      recipes: [],
      loading: false,
      hoverId: null,
    }
  },
  methods: {
    _truncate,
    openRecipe(recipe) {
      window.open(`/recettes/${recipe.slug}`, '_blank')
    },
    selectRecipe(recipe) {
      this.$emit('select', recipe.id)
      this.resetForm()
    },
    resetForm() {
      this.search = ''
      this.recipes = []
    },
  },
  watch: {
    async search() {
      if (this.search.length > 2) {
        this.loading = true

        const response = await this.$http.get('/recipes/search', {
          params: { title: this.search },
        })

        this.recipes = response.data
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.loading {
  opacity: 0.5;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0 !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
.align-baseline {
  align-items: baseline !important;
}
.search-loader {
  color: gray;
  margin-left: -30px;
}
.input {
  width: 100%;
}
.recipes-container {
  max-height: 60vh;
  overflow-y: scroll;
}
.recipe-card {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
  max-height: 100px;
  position: relative;
  border-radius: 5px;
  .dimmer {
    border-radius: 5px !important;
    .content {
      width: 100%;
      height: 70%;
      align-items: center;
      h4 {
        margin: 0 !important;
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        &:first-child {
          border-right: 1px solid white;
        }
        i {
          margin-bottom: 5px;
        }
        p:hover {
          text-decoration: underline;
        }
      }
    }
  }
  img {
    width: 100px;
    height: auto;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  .details {
    padding: 10px;
    display: flex;
    align-items: center;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;

    .header {
      h3 {
        font-weight: normal;
      }
      span {
        font-style: italic;
        color: #4a4a4a;
      }
    }
  }
}
</style>
