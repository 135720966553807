import Vue from 'vue'

// Support for semantic-ui components
import SuiVue from 'semantic-ui-vue'
Vue.use(SuiVue)

import Nl2br from 'vue-nl2br'

Vue.component('nl2br', Nl2br)

// Shared I18n from rails app
Vue.prototype.$i18n = window.I18n

// Setup for ajax requests
import axios from 'axios'
import VueAxios from 'vue-axios'

const csrfToken = document.querySelector('meta[name=csrf-token]').content

axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
axios.defaults.headers.common['Accept'] = 'application/json'
Vue.use(VueAxios, axios)

// WeeklyMenu
import WeeklyMenu from './WeeklyMenu'

$(document).ready(() => {
  const el = $('#vue-weekly-menu')[0]

  if (el) {
    var weeklyMenu = new Vue({
      el,
      render: (h) => h(WeeklyMenu),
    })

    window.weeklyMenu = weeklyMenu
  }
})
