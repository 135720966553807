<template>
  <div class="ui container recipe-form__container">
    <div class="recipe-form m-b-5">
      <sui-message
        error
        style="position: relative; display: block"
        v-if="Object.keys(errors).length"
      >
        <ul>
          <li v-for="key in Object.keys(errors)" :key="key">
            {{ capitalize($i18n.t(`activerecord.attributes.recipe.${key}`)) }}
            {{ errors[key][0] }}
          </li>
        </ul>
      </sui-message>
      <form @submit.stop="" class="recipe-form form ui large edit_recipe">
        <div class="recipe-form__header">
          <h1 class="recipe-form__title">
            <input
              v-model.lazy="recipe.title"
              type="text"
              class="ui no-border transparent text-center title-input"
              :placeholder="$i18n.t('elements.recipe.text.recipe_title')"
              maxlength="150"
              required
            />
            <i class="gdm-input-ecrire icon"></i>
          </h1>

          <div class="ui checkbox custom-checkbox">
            <input
              v-model="recipe.is_private"
              type="checkbox"
              class="ui toggle checkbox"
            />
            <label
              @click="recipe.is_private = !recipe.is_private"
              style="cursor: pointer"
            >
              {{ $i18n.t('activerecord.attributes.recipe.is_private') }}
            </label>
          </div>
        </div>
        <div class="ui centered grid">
          <div class="wide computer column">
            <CategorySelect />
            <ThematicSelect />
          </div>
        </div>

        <p class="m-t-3 recipe-form__subtitle">
          {{ capitalize($i18n.t('elements.recipe.text.general_informations')) }}
        </p>

        <div
          class="ui column aligned stackable doubling grid centered recipe-form__head"
        >
          <ImageField @displayImageModal="imageModalDisplay = true" />

          <div class="eleven wide center aligned column stretched">
            <div class="ui segment no-padding recipe-form__segment">
              <div class="recipe-form__dropdowns recipe-form__prepare">
                <div class="inline-b v-mid">
                  <i class="gdm-recette-temps huge icon"></i>
                </div>
                <div class="inline-b m-r-1 v-mid">
                  <TimeInput
                    v-model="recipe.prepare_time"
                    :class="{ error: errors.prepare_time }"
                  />
                </div>
                <div
                  class="inline-b dropdown-instruction prepare-set-time v-mid"
                >
                  <p>
                    {{
                      capitalize(
                        $i18n.t('elements.recipe.text.fill_prepare_time')
                      )
                    }}
                  </p>
                </div>

                <div class="ui divider recipe-form__divider"></div>

                <div class="ui centered recipe-form__meta">
                  <PersonInput v-model="recipe.people" :disabled="saving" />
                  <PriceInput v-model="recipe.price" />
                  <DifficultyInput v-model="recipe.difficulty" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="recipe-form__times">
          <div class="times-flex">
            <span class="times-description">
              {{ capitalize($i18n.t('elements.recipe.text.bake')) }}
            </span>
            <div class="times-item recipe-form__dropdown">
              <i class="gdm-recette-cuisson big icon"></i>
              <TimeInput v-model="recipe.bake_time" />
            </div>
          </div>
          <div class="times-flex">
            <span class="times-description">
              {{ capitalize($i18n.t('elements.recipe.text.sleep')) }}
            </span>
            <div class="times-item recipe-form__dropdown">
              <i class="gdm-recette-repos big icon"></i>
              <TimeInput v-model="recipe.sleep_time" />
            </div>
          </div>
          <div class="times-flex">
            <span class="times-description">
              {{ capitalize($i18n.t('elements.recipe.text.cold')) }}
            </span>
            <div class="times-item recipe-form__dropdown">
              <i class="gdm-recette-thermometre big icon"></i>
              <TimeInput v-model="recipe.cold_time" />
            </div>
          </div>
          <div class="times-flex">
            <span class="times-description">
              {{ capitalize($i18n.t('elements.recipe.text.frost')) }}
            </span>
            <div class="times-item recipe-form__dropdown">
              <i class="gdm-recette-congelation big icon"></i>
              <TimeInput v-model="recipe.freezing_time" />
            </div>
          </div>
        </div>

        <div class="ui middle aligned grid container m-t-2 m-b-5">
          <div class="row">
            <div class="center aligned column centered" style="width: 50%">
              <div
                v-if="!!recipeData.video_iframe"
                class="ui embed"
                v-html="recipeData.video_iframe"
              />
              <div
                v-else-if="!!recipeData.video_embed"
                v-html="recipeData.video_embed"
              />

              <div
                @click="videoModalDisplay = true"
                class="ui button custom-button custom-button--filaire add_video"
              >
                {{
                  capitalize(
                    $i18n.t(
                      'elements.recipe.buttons.' +
                        (!!recipeData.video ? 'video_added' : 'video')
                    )
                  )
                }}
              </div>
            </div>
          </div>
        </div>

        <label class="recipe-form__subtitle">
          {{ capitalize($i18n.t('elements.recipe.text.steps_description')) }}
        </label>

        <Groups />

        <Products />

        <div class="ui middle aligned grid container m-t-4 m-b-7">
          <div class="column text-right">
            <a class="text-link" @click.prevent="deleteModalDisplay = true">
              {{ capitalize($i18n.t('elements.recipe.text.delete')) }}
            </a>
            <p>{{ capitalize($i18n.t('elements.recipe.text.delete_text')) }}</p>
            <a
              :href="recipePath"
              v-if="!isDrafted"
              class="button ui custom-button"
            >
              {{ capitalize($i18n.t('elements.recipe.buttons.update')) }}
            </a>
            <template v-else>
              <a
                :href="recipePath"
                class="custom-link custom-link--remove preview-link m-r-2"
              >
                {{ capitalize($i18n.t('elements.recipe.buttons.preview')) }}
              </a>
              <sui-button
                @click.prevent="publishRecipe"
                class="button ui custom-button publish_recipe"
                :loading="publishing"
              >
                {{ capitalize($i18n.t('elements.recipe.buttons.publish')) }}
              </sui-button>
            </template>
          </div>
        </div>
      </form>
    </div>
    <ImageRightsModal
      :open="imageModalDisplay"
      @onClose="imageModalDisplay = false"
    />
    <VideoModal
      :open="videoModalDisplay"
      @onClose="videoModalDisplay = false"
    />
    <DeleteModal
      :open="deleteModalDisplay"
      @onClose="deleteModalDisplay = false"
    />
    <DeleteConfirmationModal
      :open="deleteConfirmation.open"
      :resource="deleteConfirmation.resource"
      :path="deleteConfirmation.path"
      @onClose="$store.dispatch('closeDeleteConfirmation')"
    />
    <CustomIngredientModal
      :open="customIngredientModal.open"
      :stepEndpoint="customIngredientModal.stepEndpoint"
      :ingredient="customIngredientModal.ingredient"
      @onClose="$store.dispatch('closeCustomIngredientModal')"
    />
    <ImageCropperModal />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { omit, pick, pickBy } from 'lodash'

import DefaultParams from './constants'

import { inflection, api } from './mixins'

import CategorySelect from './CategorySelect.vue'
import ThematicSelect from './ThematicSelect.vue'
import ImageField from './ImageField.vue'
import ImageRightsModal from './ImageRightsModal.vue'
import DeleteConfirmationModal from './DeleteConfirmationModal.vue'
import CustomIngredientModal from './CustomIngredientModal.vue'
import ImageCropperModal from './ImageCropperModal.vue'
import VideoModal from './VideoModal.vue'
import DeleteModal from './DeleteModal.vue'
import TimeInput from './TimeInput.vue'
import PersonInput from './PersonInput.vue'
import PriceInput from './PriceInput.vue'
import DifficultyInput from './DifficultyInput.vue'
import Groups from './Groups.vue'
import Products from './Products.vue'

export default {
  mixins: [inflection, api],
  components: {
    CategorySelect,
    ThematicSelect,
    ImageField,
    ImageRightsModal,
    VideoModal,
    DeleteModal,
    TimeInput,
    PersonInput,
    PriceInput,
    DifficultyInput,
    Groups,
    DeleteConfirmationModal,
    CustomIngredientModal,
    ImageCropperModal,
    Products,
  },
  data() {
    return {
      recipe: {},
      imageModalDisplay: false,
      videoModalDisplay: false,
      deleteModalDisplay: false,
      localVersion: 0,
      savingLock: 0,
      publishing: false,
      errors: {},
      saving: false,
    }
  },
  watch: {
    recipe: {
      handler: 'updateRecipe',
      deep: true,
    },
  },
  computed: {
    ...mapGetters([
      'recipeData',
      'deleteConfirmation',
      'customIngredientModal',
      'isDrafted',
    ]),
  },
  methods: {
    incrementSavingLock() {
      this.savingLock = this.savingLock + 1
    },
    publishRecipe() {
      const publishParams = { recipe: { is_drafted: false } }

      this.publishing = true
      return new Promise((resolve, reject) => {
        this.sendRequest('put', this.recipePath, publishParams)
          .then(() => {
            resolve()
            window.location.href = this.recipePath
            this.publishing = false
          })
          .catch((error) => {
            this.errors = error.response.data
            window.scrollTo({ top: 0, behavior: 'smooth' })
            this.publishing = false
            reject(error)
          })
      })
    },
    updateRecipe(newVal, oldVal) {
      if (!oldVal.slug) {
        return
      }
      if (this.savingLock !== this.localVersion) {
        this.localVersion = this.savingLock
        return
      }

      const updateParams = omit(
        pickBy(this.recipe, (v, _k) => v != null),
        ['slug']
      )

      this.saving = true
      this.sendRequest('put', this.recipePath, updateParams)
        .then((response) => {
          this.incrementSavingLock()
          this.$store.dispatch('updateRecipe', response.data)
          this.resetRecipe()

          // Update public/private status in edit bar if necessary
          if (this.recipe) {
            $('.recipe-status')
              .find('strong')
              .text(
                this.$i18n.t(
                  `elements.recipe.text.is_private_${this.recipe.is_private}`
                )
              )
          }
          this.saving = false
        })
        .catch(() => {
          $('.publish_recipe').removeClass('loading')
          this.saving = false
        })
    },
    resetRecipe() {
      this.recipe = {
        ...DefaultParams.RECIPE,
        ...pick(this.recipeData, Object.keys(DefaultParams.RECIPE)),
      }
      this.errors = {}
    },
  },
  created() {
    this.resetRecipe()
  },
}
</script>

<style lang="stylus">
.embed
  margin-bottom: 20px
</style>
