<template>
  <div
    class="ui checkbox custom-checkbox"
    :class="{ disabled: disabled, checked: value }"
  >
    <input
      class="hidden"
      type="checkbox"
      :checked="value"
      tabindex="0"
    >
    <label
      class="label-profile"
      @click.prevent="disabled ? null : $emit('input', !value)"
    >
      {{ label }}
    </label>
  </div>
</template>

<script>
  export default {
    props: {
      value: {
        type: Boolean,
        default: false
      },
      label: {
        type: String,
        required: true
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
  }
</script>

<style lang="stylus" scoped>
.label-profile
  cursor: pointer!important
</style>
