<template>
  <sui-modal v-model="open" size="large" class="transition custom-modal cropper-modal" @clickAwayModal="closeModal">
    <i class="close icon" @click="closeModal"></i>
    <sui-modal-header class="no-border">
      Ajustez votre image
    </sui-modal-header>
    <sui-modal-content>
      <div v-if="loading" class="loader text-center">
        <img :src="require('images/club/trendings/trending-reco.gif')" />
        <p class="waiting">Quelques instants...</p>
      </div>
      <cropper
        v-else
        :options="{ showPreview: false }"
        @cropper-saved="saveImage"
      />
    </sui-modal-content>
  </sui-modal>
</template>

<script>
import Cropper from './Cropper'
import { inflection, api } from './mixins'
import { mapGetters } from 'vuex'

export default {
  mixins: [inflection, api],
  components: { Cropper },
  data() {
    return {
      open: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters(['imageCropperModalOpen'])
  },
  methods: {
    closeModal() {
      this.$store.dispatch('closeImageCropperModal')
    },
    saveImage(image) {
      if (this.loading) {
        return
      }

      let payload = new FormData()

      const file = image.croppedFile
      payload.append('recipe[image]', file)

      this.loading = true

      this.sendRequest('put', this.recipePath, payload)
        .then((response) => {
          this.loading = false
          this.$store.dispatch('setDisplayImageUrl', image.croppedImageURI)
          this.closeModal()
        })
        .catch((e) => {
          this.loading = false
        })
    }
  },
  watch: {
    imageCropperModalOpen() {
      this.open = this.imageCropperModalOpen
    }
  }
}
</script>