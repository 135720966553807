<template>
  <div class="meta-flex center meta-persons">
    <div class="row">
      <i class="gdm-recette-nbr-person icon"></i>
    </div>
    <div class="ui right icon input">
      <input
        type="number"
        style="border-radius: 20px"
        :value="value"
        min="0"
        max="256"
        step="1"
        :disabled="disabled"
        @change="updateValue"
      />
      <i class="users icon"></i>
    </div>
    <div class="text-center dropdown-instruction m-t-1">
      Le nombre de personnes se definit selon la quantité d'ingrédients utilisés
    </div>
  </div>
</template>

<script>
import { inflection } from './mixins'

export default {
  props: ['value', 'disabled'],
  mixins: [inflection],
  methods: {
    updateValue (e) {
      let val = e.target.value
      if (val < 0) {
        this.$emit('input', 0)
      } else if (val > 256) {
        this.$emit('input', 256)
      } else {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
</style>
