<template>
  <div>
    <div class="panel panel-default">
      <div class="panel-heading">
        <input
          v-if="editMode"
          class="form-control url-input"
          v-model="data.url"
        >
        <span v-else>
          <a
            :href="data.url"
            target="_blank"
          >
            {{ data.url }}
          </a>
        </span>
        <div>
          <button
            @click.prevent="toggleEditMode"
            class="btn btn-xs btn-success"
          >
            <i
              v-if="saving"
              class="fa fa-fw fa-spinner fa-spin"
            ></i>
            <i
              v-else
              :class="editMode ? 'fa fa-fw fa-check' : 'fa fa-fw fa-edit'"
            ></i>
          </button>
          <button
            v-if="editMode && !!data.id"
            @click.prevent="cancelEdit"
            class="btn btn-xs btn-danger"
          >
            <i class="fa fa-fw fa-window-close"></i>
          </button>
          <button
            v-else
            @click.prevent="removeHeadline"
            class="btn btn-xs btn-danger"
          >
            <i :class="removeLoading ? 'fa fa-fw fa-spinner fa-spin' : 'fa fa-fw fa-trash'"></i>
          </button>
        </div>
      </div>
      <div class="panel-body">
        <div class="body-content row">
          <div class="image-field col-sm-2 text-center">
            <image-input
              v-model="file"
              :current-image="{ url: displayImageUrl }"
              v-if="editMode"
              class="m-t-1"
            />
            <img
              v-else
              :src="displayImageUrl"
              width="100%"
            />
          </div>

          <div class="content-field col-sm-10">
            <textarea
              v-if="editMode"
              v-model="data.content"
              class="form-control"
              rows="8"
            />
            <span v-else>
              {{ data.content }}
            </span>
          </div>
        </div>
        <div class="body-content row mt-1">
          <div class="content-field col-sm-10">
            <label v-if="editMode">
              <input type="checkbox" v-model="data.pinned" />
              Épinglé
            </label>
            <span v-else>
              <strong>Épinglé : </strong>{{ data.pinned ? 'Oui' : 'Non' }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageInput from '@back/components/ImageInput.vue'

export default {
  components: {
    ImageInput
  },
  props: ['headline', 'pageId'],
  data() {
    return {
      removeLoading: false,
      saving: false,
      editMode: !!this.headline.tmpId,
      data: { ...this.headline },
      file: null
    }
  },
  computed: {
    payload() {
      let payload = new FormData()
      payload.append('headline[url]', this.data.url)
      payload.append('headline[content]', this.data.content)
      payload.append('headline[pinned]', this.data.pinned)

      if (this.file) {
        payload.append('headline[image]', this.file)
      }

      return payload
    },
    displayImageUrl() {
      if (this.file) {
        return URL.createObjectURL(this.file)
      } else if (this.data.image && !!this.data.image.url) {
        return this.data.image.url
      } else {
        return require('images/recipe-placeholder.webp')
      }
    }
  },
  methods: {
    cancelEdit() {
      this.data = this.headline
      this.file = null
      this.editMode = false
    },
    toggleEditMode() {
      if (this.editMode) {
        !!this.headline.id ? this.updateHeadline() : this.createHeadline()
      } else {
        this.editMode = true
      }
    },
    createHeadline() {
      this.saving = true
      this.$http
        .post(`/back/club_home_pages/${this.pageId}/headlines`, this.payload)
        .then(response => {
          this.$emit('created', { ...this.headline, ...response.data })
          this.editMode = false
          this.saving = false
        })
        .catch(error => {
          this.cancelEdit()
          this.saving = false
        })
    },
    updateHeadline() {
      this.saving = true
      this.$http
        .put(`/back/club_home_pages/${this.pageId}/headlines/${this.headline.id}`, this.payload)
        .then(response => {
          this.editMode = false
          this.saving = false
        })
        .catch(error => {
          this.cancelEdit()
          this.saving = false
        })
    },
    removeHeadline() {
      this.removeLoading = true
      if (this.headline.id) {
        this.$http
          .delete(`/back/club_home_pages/${this.pageId}/headlines/${this.headline.id}`)
          .then(response => {
            this.$emit('removeHeadline', this.headline)
          })
      } else {
        this.$emit('removeHeadline', this.headline)
      }
    }
  }
}
</script>

<style lang='stylus' scoped>
.panel-heading
  display: flex
  justify-content: space-between
  align-items: center
  .url-input
    width: 80%
.body-content
  display: flex
  align-items: center
.mt-1
  margin-top: 10px
</style>
