<template>
  <div class="col-md-12">
    <div class="box box-gdm">
      <div class="box-header with-border">
        <h3 class="box-title">
          <strong>Engagement Utilisateur</strong>
        </h3>
      </div>
      <div class="box-body">
        <div class="col-md-12">
          <form @submit.prevent="validateFilters" class="form-inline">
            <div class="col-md-6">
              <h5>Période Observée</h5>
              <div class="form-group">
                <label>Du</label>
                <input
                  type="date"
                  v-model="obsPeriod.from"
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <label>Au</label>
                <input
                  type="date"
                  v-model="obsPeriod.to"
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-md-6">
              <h5>Période de comparaison</h5>
              <div class="form-group">
                <label>Du</label>
                <input
                  type="date"
                  v-model="compPeriod.from"
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <label>Au</label>
                <input
                  type="date"
                  v-model="compPeriod.to"
                  class="form-control"
                />
                <button type="submit" class="btn btn-primary">Valider</button>
              </div>
            </div>
          </form>
        </div>
        <div class="col-md-12">
          <div class="table-responsive">
            <table class="table no-margin home-table">
              <thead>
                <tr>
                  <th>Donnée</th>
                  <th>Période observée</th>
                  <th>Période de comparaison</th>
                </tr>
              </thead>
              <tbody>
                <stat-row
                  v-for="row in tableRows"
                  :key="row.title"
                  :filters="filters"
                  :row-data="row"
                />
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StatRow from './StatRow.vue'

export default {
  components: { StatRow },
  data() {
    return {
      ...$('#dashboard-stats-init').data('default-periods'),
      filters: {
        ...$('#dashboard-stats-init').data('default-periods'),
      },
      tableRows: [
        {
          title: 'Nouveaux utilisateurs',
          widget: 'new_users',
        },
        {
          title: 'Utilisateurs actifs',
          widget: 'active_users',
        },
        {
          title: 'Abonnés newsletter',
          widget: 'newsletter_subscribers',
        },
        {
          title: 'Taux de membres ayant créé une recette',
          widget: 'recipe_creators_percentage',
        },
        {
          title: 'Nouvelles recettes classiques (par statut)',
          widget: 'new_recipes',
          opts: {
            agg_type: 'by_status',
            recipe_kind: 'classic',
          },
        },
        {
          title: 'Nouvelles recettes Icookin (par statut)',
          widget: 'new_recipes',
          opts: {
            agg_type: 'by_status',
            recipe_kind: 'icookin',
          },
        },
        {
          title: 'Nouvelles recettes Classiques (par membres)',
          widget: 'new_recipes',
          opts: {
            agg_type: 'by_members',
            recipe_kind: 'classic',
          },
        },
        {
          title: 'Nouvelles recettes Icookin (par membres)',
          widget: 'new_recipes',
          opts: {
            agg_type: 'by_members',
            recipe_kind: 'icookin',
          },
        },
        {
          title: 'Listes de favoris',
          widget: 'new_favorite_lists',
        },
        {
          title: 'Menus hebdos',
          widget: 'new_weekly_menus',
        },
        {
          title: 'Listes de courses',
          widget: 'new_shopping_lists',
        },
        {
          title: 'Favoris',
          widget: 'new_favorites',
        },
        {
          title: 'Commentaires',
          widget: 'new_comments',
        },
        {
          title: 'Notes',
          widget: 'new_ratings',
        },
        {
          title: 'Top Membres',
          widget: 'top_members_by_followers',
        },
        {
          title: 'Top Membres',
          widget: 'top_members_by_recipes',
        },
        {
          title: 'Top Recettes',
          widget: 'top_recipes_by_favorites',
        },
        {
          title: 'Top Thématiques',
          widget: 'top_thematics_by_recipes',
        },
        {
          title: 'Top Categories',
          widget: 'top_categories_by_recipes',
        },
      ],
    }
  },
  methods: {
    validateFilters() {
      this.filters = {
        obsPeriod: this.obsPeriod,
        compPeriod: this.compPeriod,
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.form-control {
  margin: 10px;
}
</style>
