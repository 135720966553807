<template>
  <sui-modal v-model="open" size="tiny" class="transition custom-modal" @clickAwayModal="$emit('onClose')">
    <i class="close icon" @click="$emit('onClose')"></i>
    <sui-modal-header class="no-border">
      {{ capitalize($i18n.t(`elements.recipe.modal.delete_${resource}_title`)) }}
    </sui-modal-header>
    <sui-modal-content>
      {{ capitalize($i18n.t(`elements.recipe.modal.delete_${resource}_content`)) }}
    </sui-modal-content>
    <sui-modal-actions class="no-border transparent">
      <sui-button deny class="custom-button custom-button--filaire" @click="$emit('onClose')">
        {{ capitalize($i18n.t('elements.global.buttons.cancel')) }}
      </sui-button>
      <sui-button :loading="loading" positive class="custom-button" @click="deleteResource">
        {{ capitalize($i18n.t('elements.global.buttons.valid')) }}
      </sui-button>
    </sui-modal-actions>
  </sui-modal>
</template>

<script>
import { inflection, api } from './mixins'

export default {
  mixins: [inflection, api],
  props: ['open', 'resource', 'path'],
  data () {
    return {
      loading: false
    }
  },
  methods: {
    deleteResource () {
      this.loading = true
      this.sendRequest('delete', this.path)
      .then( (response) => {
        this.$store.dispatch('updateRecipe', response.data)
        this.loading = false
        this.$emit('onClose')
      })
      .catch( () => this.loading = false )
    }
  }
}
</script>

<style lang="stylus" scoped>
</style>
