export const UPDATE_RECIPE = 'UPDATE_RECIPE'
export const OPEN_DELETE_CONFIRMATION = 'OPEN_DELETE_CONFIRMATION'
export const CLOSE_DELETE_CONFIRMATION = 'CLOSE_DELETE_CONFIRMATION'
export const OPEN_CUSTOM_INGREDIENT_MODAL = 'OPEN_CUSTOM_INGREDIENT_MODAL'
export const CLOSE_CUSTOM_INGREDIENT_MODAL = 'CLOSE_CUSTOM_INGREDIENT_MODAL'
export const OPEN_IMAGE_CROPPER_MODAL = 'OPEN_IMAGE_CROPPER_MODAL'
export const CLOSE_IMAGE_CROPPER_MODAL = 'CLOSE_IMAGE_CROPPER_MODAL'
export const ADD_INGREDIENT = "ADD_INGREDIENT"
export const ADD_AVAILABLE_INGREDIENT = "ADD_AVAILABLE_INGREDIENT"
export const DELETE_INGREDIENT = "DELETE_INGREDIENT"
export const SET_IMAGE_DISPLAY_URL = "SET_IMAGE_DISPLAY_URL"
