<template>
  <div>
    <div class="ui container" v-for="title in sortedResultKeys" :key="title">
      <div class="title-section m-b-2">
        <h2 class="custom-title">
          {{ recipesFoundMsg(results[title].total_count) }}
          pour :
          {{ title }}
        </h2>
      </div>
      <template v-if="results[title].total_count">
        <div v-html="results[title].html"></div>
        <div class="ui column text-center m-t-2 m-b-2">
          <a
            :href="urlFor(title)"
            class="ui button custom-button"
            target="_blank"
          >
            {{ moreRecipesMsg(results[title].total_count) }}
          </a>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    results: {
      type: Object,
      required: true,
    },
    filters: {
      type: String,
      required: true,
    },
  },
  computed: {
    sortedResultKeys() {
      return [
        ...Object.keys(this.results).filter(
          (key) => this.results[key].total_count
        ),
        ...Object.keys(this.results).filter(
          (key) => !this.results[key].total_count
        ),
      ]
    },
  },
  methods: {
    urlFor(title) {
      const ingredientFilter = `ingredients=${title.replace(', ', ',')}`
      const filters = [ingredientFilter, ...this.filters].join('&')

      return `/recettes?${filters}`
    },
    moreRecipesMsg(count) {
      return count > 1 ? `Voir les ${count} recettes` : 'Voir la recette'
    },
    recipesFoundMsg(count) {
      return [
        '0 recette trouvée',
        '1 recette trouvée',
        `${count} recettes trouvées`,
      ][count]
    },
  },
  mounted() {
    $('.js-favorite-list-modal-trigger').on('click', (e) => {
      const recipeData = $(e.currentTarget).data('recipe')

      let $modal = window.favoriteListModal.$children[0]

      $modal.recipe = recipeData
      $modal.open = true
    })
    $('.js-weekly-menu-modal-trigger').on('click', (e) => {
      const recipeData = $(e.currentTarget).data('recipe')

      let $modal = window.weeklyMenuModal.$children[0]

      $modal.recipe = recipeData
      $modal.open = true
    })
    $('.js-shopping-list-trigger').on('click', (e) => {
      const recipeData = $(e.currentTarget).data('recipe')
      const groupId = $(e.currentTarget).data('group-id')

      let $modal = window.shoppingListModal.$children[0]

      $modal.recipe = recipeData
      $modal.selectedIngredientOptions = groupId
      $modal.open = true
    })
  },
}
</script>

<style lang="scss" scoped></style>
