<template>
  <sui-dropdown
    v-model="selectedOptions"
    ref="dropdown"
    :options="options"
    :placeholder="placeholder"
    :class="suiClass"
    :multiple="multiple"
    :disabled="disabled"
    direction="downward"
    no-results-message="Aucun résultat"
    search
  />
</template>

<script>
  export default {
    props: {
      value: {
        type: [String, Number, Array],
        required: true
      },
      options: {
        type: Array,
        required: true
      },
      placeholder: {
        type: String,
        required: false,
        default: ''
      },
      selectClass: {
        type: String,
        required: false,
        default: ''
      },
      multiple: {
        type: Boolean,
        required: false,
        default: false
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    data () {
      return {
        selectedOptions: this.value,
        locked: false
      }
    },
    computed: {
      suiClass () {
        let sClass = this.selectClass
        if (this.disabled) {
          return `${sClass} disabled`
        }
        return sClass
      }
    },
    watch: {
      selectedOptions () {
        this.$emit('input', this.selectedOptions)
      },
      disabled () {
        let $search = $(this.$refs.dropdown.$el).find('.search')

        if (this.disabled) {
          $search.attr("disabled", true)
        } else {
          $search.removeAttr("disabled")
        }
      }
    }
  }
</script>

<style lang="stylus">
.recipe-from__dropdown--thematics
  .ui.label
    border-radius: 15px
    border-color: #DAAA5D
    background-color: #FFEDD0
    color: #9F7227
    -webkit-print-color-adjust: exact
    h2
      color: #9F7227

    &:before
      background-color: #DAAA5D
      -webkit-print-color-adjust: exact

.recipe-from__dropdown--categories
  .ui.label
    border-radius: 15px
    border-color: #94B5F2
    background-color: #E3ECFC
    color: #33589B
    -webkit-print-color-adjust: exact
    h2
      color: #33589B

    &:before
      background-color: #94B5F2
      -webkit-print-color-adjust: exact

.recipe-from__dropdown--accessories
  .ui.label
    border-radius: 15px
    border-color: #E83B47
    background-color: #FCE7E9
    color: #E83B47

    &:before
      display: none
</style>
