<template>
  <div>
    <a
      href="javascript:void(0)"
      class="ui button not_allowed custom-button m-t-20-px"
      @click="handleClick"
    >
      <i class="gdm-icon gd-shopping-cart"></i>
      Ajouter les ingrédients à une liste de courses
    </a>
    <sui-modal @clickAwayModal="resetForm" v-model="modalOpen">
      <i @click="resetForm" class="close icon" />
      <sui-modal-header>
        Ajouter les ingrédients à une liste de courses
      </sui-modal-header>
      <sui-modal-content class="m-b-5">
        <form class="ui form">
          <div class="field" :class="{ error }">
            <label>Liste de courses</label>

            <input
              v-if="newListMode"
              type="text"
              placeholder="Entrez le titre de votre nouvelle liste"
              v-model="newListName"
              required
            />
            <template v-else>
              <sui-dropdown
                direction="downward"
                class="ui fluid dropdown custom-dropdown recipe-form__dropdown recipe-form__dropdown--small selection ui dropdown"
                :options="selectableLists"
                v-model="selectedList"
              />
              <a
                href="javascript:void(0)"
                class="text-gdm-red"
                @click="newListMode = true"
              >
                Ajouter à une nouvelle liste
              </a>
            </template>
          </div>
          <div class="ui four column stackable grid doubling-xs m-t-1">
            <div
              v-for="column in menu.weekly_menu_columns.filter(
                (c) => c.weekly_menu_items.filter((i) => i.recipe).length
              )"
              :key="`column-${column.id}`"
              class="column"
            >
              <span class="ui checkbox column-title">
                <input
                  type="checkbox"
                  :checked="selectedColumns.includes(column.id)"
                  @change="toggleColumn(column, $event.target.checked)"
                />
                <label>{{ column.title }}</label>
                <div class="ui divider"></div>
              </span>
              <ul class="recipes-list">
                <li
                  v-for="item in column.weekly_menu_items.filter(
                    (i) => i.recipe
                  )"
                  class="recipe-checkbox"
                  :key="`recipe-${item.recipe.id}`"
                >
                  <div class="ui checkbox">
                    <input
                      type="checkbox"
                      v-model="selectedRecipeIds"
                      :value="item.recipe.id"
                    />
                    <label>
                      {{ _truncate(item.recipe.title, { length: 25 }) }}
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </form>
      </sui-modal-content>
      <sui-modal-actions class="no-border transparent">
        <div
          @click="resetForm"
          class="ui deny button custom-button custom-button--filaire"
        >
          Annuler
        </div>
        <sui-button
          @click="addToList"
          :disabled="error || loading"
          :loading="loading"
          class="ui positive button custom-button"
        >
          Valider
        </sui-button>
      </sui-modal-actions>
    </sui-modal>
  </div>
</template>

<script>
const $data = $('#shopping-lists-data')
import _truncate from 'lodash/truncate'

export default {
  props: {
    readOnly: {
      type: Boolean,
      required: true,
    },
    menu: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      modalOpen: false,
      lists: $data.data('lists'),
      selectedList: ($data.data('lists')[0] || {}).id,
      newListMode: !$data.data('lists').length,
      newListName: null,
      loading: false,
      selectedRecipeIds: this.menu.weekly_menu_columns
        .map((c) => {
          return c.weekly_menu_items
            .filter((i) => i.recipe)
            .map((i) => i.recipe.id)
        })
        .flat(),
    }
  },
  computed: {
    error() {
      return this.newListName === ''
    },
    selectableLists() {
      return this.lists.map((list) => {
        return {
          value: list.id,
          text: list.title,
        }
      })
    },
    selectedColumns() {
      return this.menu.weekly_menu_columns
        .filter((c) => c.weekly_menu_items.filter((i) => i.recipe).length)
        .filter((c) => {
          return c.weekly_menu_items
            .filter((i) => i.recipe)
            .map((i) => i.recipe.id)
            .every((id) => this.selectedRecipeIds.includes(id))
        })
        .map((c) => c.id)
    },
  },
  methods: {
    _truncate,
    toggleColumn(column, status) {
      const recipeIds = column.weekly_menu_items
        .filter((i) => i.recipe)
        .map((i) => i.recipe.id)
      if (status) {
        this.selectedRecipeIds = [
          ...new Set([...this.selectedRecipeIds, ...recipeIds]),
        ]
      } else {
        this.selectedRecipeIds = this.selectedRecipeIds.filter(
          (id) => !recipeIds.includes(id)
        )
      }
    },
    handleClick() {
      if (this.readOnly) {
        window.location = '/se-connecter'
      } else {
        this.modalOpen = true
      }
    },
    resetForm() {
      this.modalOpen = false
      this.newListMode = false
      this.newListName = null
      this.lists = $data.data('lists')
      this.selectedList = this.lists[0].id
    },
    async addToList() {
      this.loading = true

      let listId = null

      if (this.newListMode) {
        if (!!this.newListName) {
          const response = await this.$http.post(`/shopping_lists`, {
            shopping_list: { title: this.newListName },
          })

          const list = response.data

          listId = list.id

          if ($('#shopping-lists-data').length) {
            $('#shopping-lists-data').data('lists', [
              list,
              ...$('#shopping-lists-data').data('lists'),
            ])
          }
        } else {
          this.newListName = ''
          this.loading = false

          return
        }
      } else {
        listId = this.selectedList
      }

      await this.$http.post(`/shopping_lists/${listId}/add_recipes`, {
        recipe_ids: this.selectedRecipeIds,
      })

      this.loading = false
      window.globalFlashMessage(
        'success',
        'Les ingrédients ont bien été ajoutés à la liste'
      )
      this.resetForm()
    },
  },
}
</script>

<style lang="scss" scoped>
.recipes-list {
  padding: 0;
  margin: 0;
}
.recipe-checkbox {
  list-style: none;
  margin-bottom: 5px;
}
.column-title {
  label {
    font-weight: bold;
  }
}
.gd-shopping-cart {
  vertical-align: bottom;
}
.text-gdm-red {
  color: #e83b47;
  margin-left: 5px;
}
.dropdown {
  margin-bottom: 5px;
}
.header {
  border-bottom: none !important;
}
.button:focus {
  background-color: #e83b47 !important;
}
</style>
