<template>
  <sui-modal @clickAwayModal="open = false" v-model="open">
    <i @click="open = false" class="close icon" />
    <sui-modal-header class="text-center">{{ this.products[this.selectedProduct].title }}</sui-modal-header>
    <sui-modal-content class="m-b-2">
      <div class="row flex items-center">
        <div class="info-modal-image m-r-1">
          <img :src="this.products[this.selectedProduct].image" width="90px" />
        </div>
        <div class="info-modal-text">
          <p class="custom-text" v-html="this.products[this.selectedProduct].text"></p>
        </div>
      </div>
      <div class="row text-center m-t-2">
        <a :href="this.products[this.selectedProduct].url" target="_blank" class="custom-link">Je découvre ></a>
      </div>
    </sui-modal-content>
  </sui-modal>
</template>

<script>
const $data = $('#shopping-lists-data')
export default {
  data() {
    return {
      open: false,
      selectedProduct: 'canofea',
      products: {
        canofea: {
          title: 'Canofea®',
          url: '/canofea',
          image: require('images/club/canofea-edit.webp'),
          text: "Canofea® est la plus intuitive des machines à café à grains automatiques !<br /><br /> Elle vous accompagne toute la journée, de votre expresso du matin à vos cappuccinos de l'après-midi !",
        },
        ohra: {
          title: 'ohra®',
          url: 'https://boutique.guydemarle.com/content/14-8-bonnes-raisons-de-choisir-le-ohra-guy-demarle',
          image: require('images/club/ohra-edit.webp'),
          text: "Le ohra® est une innovation 100% française qui a séduit il y a déjà plus de 20 ans les plus grands chefs et pâtissiers du monde entier pour devenir LA référence en matière de moule dans les cuisines professionnelles.",
        },
        besave: {
          title: 'Be Save®',
          url: 'https://besave.guydemarle.com',
          image: require('images/club/be_save-edit.webp'),
          text: "Be Save® est le 1er appareil de mise sous vide d’air automatique permettant de conserver les aliments beaucoup plus longtemps. Un véritable concentré d'innovation qui révolutionne la conservation !",
        },
        icookin: {
          title: 'i-Cook’in®',
          url: 'https://icookin.guydemarle.com/',
          image: require('images/club/icookin_device.webp'),
          text: "i-Cook’in® est un robot de cuisine multifonction, connecté et communautaire. Avec plus de 8000 recettes disponibles directement sur son écran et ses 25 fonctionnalités, il vous simplifie la vie !",
        }
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.header {
  border-bottom: none !important;
}
</style>