<template>
  <sui-modal @clickAwayModal="resetForm" v-model="open">
    <i @click="resetForm" class="close icon" />
    <sui-modal-header>
      Ajouter <span class="text-gdm-red">{{ recipe.title }}</span> à une liste
      de favoris
    </sui-modal-header>
    <sui-modal-content>
      <form class="ui form">
        <div class="field" :class="{ error }">
          <template v-if="newListMode">
            <div class="field">
              <label class="custom-label">Nom de la liste de favoris</label>
              <input
                type="text"
                placeholder="Entrez le nom de votre nouvelle liste de favoris"
                v-model="newListName"
                required
              />
            </div>
            <div class="grouped fields">
              <label class="custom-label">
                Qui peut voir votre liste de favoris ?
              </label>
              <div class="field">
                <div
                  class="ui radio checkbox"
                  @click="newListPrivateMode = false"
                >
                  <input
                    id="favorite_list_is_private"
                    class="hidden"
                    type="radio"
                    :checked="!newListPrivateMode"
                  />
                  <label for="favorite_list_is_private">Tout le monde</label>
                </div>
              </div>
              <div class="field">
                <div
                  class="ui radio checkbox"
                  @click="newListPrivateMode = true"
                >
                  <input
                    id="favorite_list_is_private"
                    class="hidden"
                    type="radio"
                    :checked="newListPrivateMode"
                  />
                  <label for="favorite_list_is_private">Moi uniquement</label>
                </div>
              </div>
            </div>
            <div class="field">
              <label class="custom-label">Description (optionnelle)</label>
              <textarea
                class="maxLines"
                maxLength="900"
                v-model="newListDescription"
              />
            </div>
          </template>
          <div class="field" v-else>
            <label class="custom-label">Liste de favoris</label>
            <sui-dropdown
              direction="downward"
              class="ui fluid dropdown custom-dropdown recipe-form__dropdown recipe-form__dropdown--small selection ui dropdown"
              :options="selectableLists"
              v-model="selectedList"
            />
            <a
              href="javascript:void(0)"
              class="text-gdm-red"
              @click="newListMode = true"
            >
              Ajouter à une nouvelle liste de favoris
            </a>
          </div>
        </div>
      </form>
    </sui-modal-content>
    <sui-modal-actions class="no-border transparent">
      <div
        @click="resetForm"
        class="ui deny button custom-button custom-button--filaire"
      >
        Annuler
      </div>
      <sui-button
        @click="addToList"
        :disabled="error"
        :loading="loading"
        class="ui positive button custom-button"
      >
        Valider
      </sui-button>
    </sui-modal-actions>
  </sui-modal>
</template>

<script>
export default {
  data() {
    return {
      recipe: {},
      lists: $('#favorite-lists-data').data('lists'),
      selectedList: ($('#favorite-lists-data').data('lists')[0] || {}).id,
      selectedDay: 0,
      newListMode: !$('#favorite-lists-data').data('lists').length,
      newListDescription: null,
      newListName: null,
      newListPrivateMode: false,
      open: false,
      loading: false,
    }
  },
  computed: {
    error() {
      return this.newListName === ''
    },
    selectableLists() {
      return this.lists.map((list) => {
        return {
          value: list.id,
          text: list.label,
        }
      })
    },
  },
  methods: {
    resetForm() {
      this.open = false
      this.newListMode = !$('#favorite-lists-data').data('lists').length
      this.newListName = null
      this.newListDescription = null
      this.newListPrivateMode = false
      this.lists = $('#favorite-lists-data').data('lists')
      this.selectedList = this.lists[0].id
    },
    async addToList() {
      this.loading = true

      let listId = null

      if (this.newListMode) {
        if (!!this.newListName) {
          const response = await this.$http.post(`/favorite_lists`, {
            favorite_list: {
              label: this.newListName,
              is_private: this.newListPrivateMode,
              description: this.newListDescription,
            },
          })

          const slug = response.data.split('-')

          listId = slug[slug.length - 1]

          $('#favorite-lists-data').data('lists', [
            ...$('#favorite-lists-data').data('lists'),
            {
              id: listId,
              label: this.newListName,
              is_private: this.newListPrivateMode,
              description: this.newListDescription,
            },
          ])
        } else {
          this.resetForm()

          return
        }
      } else {
        listId = this.selectedList
      }

      try {
        const response = await this.$http.post(
          `/favorite_lists/${listId}/add_recipe`,
          {
            recipe_id: this.recipe.id,
          }
        )

        const data = response.data

        const $btn = $(
          `.add_recipe_to_favorite_list[data-recipe-id='${this.recipe.id}']`
        )

        $btn.find('.ui.pointing.label').text(data.favorites_count)
        $btn.find('.gdm-carte-unlike').addClass('liked')

        window.globalFlashMessage(
          'success',
          'La recette a bien été ajoutée à la liste de favoris'
        )
      } catch (error) {
        console.log('ERROR: ', error)
        window.globalFlashMessage(
          'error',
          'La recette fait déjà partie de cette liste de favoris'
        )
      }
      this.loading = false
      this.resetForm()
    },
  },
}
</script>

<style lang="scss" scoped>
.text-gdm-red {
  color: #e83b47;
  margin-left: 5px;
}
.dropdown {
  margin-bottom: 5px;
}
.header {
  border-bottom: none !important;
}
.button:focus {
  background-color: #e83b47 !important;
}
.ui.radio.checkbox input:indeterminate ~ .box:after,
.ui.radio.checkbox input:indeterminate ~ label:after {
  &:focus {
    background-color: red;
  }
}
</style>
