import moment from 'moment'

var REFERENCE = moment();
var TODAY = REFERENCE.clone().startOf('day');
var YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day');

const I18n = window.I18n;

function isToday(momentDate) {
  return momentDate.isSame(TODAY, 'd')
};

function isYesterday(momentDate) {
  return momentDate.isSame(YESTERDAY, 'd')
};

export default function (date) {
  let formattedDate

  if (isYesterday(date)) {
    formattedDate = I18n.t('elements.global.table.yesterday')
  } else if (isToday(date)) {
    formattedDate = I18n.t('elements.global.table.today')
  } else {
    formattedDate = I18n.l('date.formats.default', date.format('L')).toLowerCase()
  }

  return `${formattedDate} ${I18n.t('elements.global.text.at')} ${date.format('HH[h]mm')}`
};
