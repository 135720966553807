<template>
  <v-select
    v-model="selectedMember"
    label="email"
    :options="members"
    :filterable="false"
    :placeholder="placeholder"
    @search="onSearch"
  >
    <template slot="option" slot-scope="option">
      <div class="d-center">
        <img :src="option.image.url" height="40px" />
        {{ option.email }} ( {{ option.username }} )
      </div>
    </template>
    <template slot="selected-option" slot-scope="option">
      <div class="selected d-center">
        <img :src="option.image.url" height="40px" />
        {{
          truncate(`${option.email} (${option.username})`, {
            length: 25,
          })
        }}
      </div>
    </template>
  </v-select>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: () => {
        return null
      },
    },
    placeholder: {
      type: String,
      default: 'Rechercher un membre par email ou username',
    },
  },
  data() {
    return {
      selectedMember: this.value,
      members: [],
    }
  },
  watch: {
    selectedMember(newValue, oldValue) {
      this.$emit('input', newValue)
    },
    value(newValue) {
      this.selectedMember = newValue
    },
  },
  methods: {
    onSearch(search, loading) {
      loading(true)
      this.search(loading, search, this)
    },
    search: _.debounce((loading, search, vm) => {
      vm.$http
        .get('/back/users/select', {
          params: {
            search_term: search,
          },
        })
        .then(({ data }) => {
          vm.members = data
          loading(false)
        })
    }, 350),
    truncate: _.truncate,
  },
}
</script>

<style lang="scss" scoped></style>
