<template>
  <div class="meta-flex center meta-difficulties">
    <div class="row">
      <div class="default">
        <i :class="iconClass"></i>
      </div>
    </div>
    <sui-dropdown
      direction="downward"
      class="ui fluid dropdown custom-dropdown recipe-form__dropdown recipe-form__dropdown--small selection"
      :options="options"
      v-model="difficulty"
    />
    <div class="text-center dropdown-instruction m-t-1">
      {{ capitalize($i18n.t('elements.recipe.text.difficulty_text')) }}
    </div>
  </div>
</template>

<script>
import { inflection } from './mixins'

export default {
  props: ['value'],
  mixins: [inflection],
  data () {
    return {
      difficulty: this.value || 'easy',
      optionsArray: ['easy', 'medium', 'hard']
    }
  },
  computed: {
    options () {
      return this.optionsArray.map( (d) => {
        return {
          value: d,
          text: this.capitalize(this.$i18n.t(`activerecord.enums.recipe.difficulties.${d}`))
        }
      })
    },
    iconClass () {
      const value = this.optionsArray.indexOf(this.difficulty) + 1
      return `gdm-recette-diffuculte_${value} icon`
    }
  },
  watch: {
    difficulty () {
      this.$emit('input', this.difficulty)
    }
  }
}
</script>

<style lang="stylus" scoped>
</style>

