<template>
  <div>
    <div class="ui top attached tabular menu tab-robot step-tabs custom-tabular-menu">
      <a
         class="item"
         v-for="option in speedOptions"
         :key="option"
         :class="{ active: icookinFields.speed === option }"
         @click.prevent="icookinFields.speed = option"
      >
         {{ capitalize($i18n.t('elements.recipe.text.' + option)) }}
      </a>
    </div>
    <div class="ui bottom attached tab custom-tab active">
      <div class="custom-tab__wrap">
        <div class="robot-modes__wrap">
          <div v-show="icookinFields.speed != 'turbo'" class="robot-modes__flex">
            <span class="robot-modes__label label--m-l">
              <label>
                {{ capitalize($i18n.t('activerecord.attributes.step.cook_time')) }}
              </label>
            </span>
            <div class="cook-time__flex recipe-form__dropdowns">
              <i class="gdm-ick icon"></i>
              <SecondsInput
                v-model="icookinFields.cookTime"
                :max="icookinFields.speed === 'brown' ? 180 : 5940"
                :error="errors.cook_time"
              />
            </div>
          </div>

          <div v-show="!['petrissage', 'turbo'].includes(icookinFields.speed)" class="robot-modes__flex recipe-form__dropdowns">
            <span class="robot-modes__label">
              <label>
                {{ capitalize($i18n.t('activerecord.attributes.step.temperature')) }}
              </label>
            </span>
            <div class="ui field">
              <SemanticSelect
                v-model="icookinFields.temperature"
                :class="{ error: errors.temperature }"
                :options="tempOptions"
                select-class="ui fluid search selection dropdown custom-dropdown recipe-form__dropdown recipe-form__dropdown--small"
              />
            </div>
          </div>

          <div v-show="icookinFields.speed === 'normal'" class="robot-modes__flex recipe-form__dropdowns">
            <span class="robot-modes__label">
              <label>
                {{ capitalize($i18n.t('activerecord.attributes.step.speed')) }}
              </label>
            </span>
            <div class="ui field">
              <SemanticSelect
                v-model="icookinFields.selectedSpeed"
                :class="{ error: errors.speed }"
                :options="normalSpeedOptions"
                select-class="ui fluid search selection dropdown custom-dropdown recipe-form__dropdown recipe-form__dropdown--small"
              />
            </div>
          </div>

          <div v-show="icookinFields.speed === 'turbo'" class="robot-modes__flex recipe-form__dropdowns">
            <span class="robot-modes__label">
              <label>
                {{ capitalize($i18n.t('activerecord.attributes.step.speed')) }}
              </label>
            </span>
            <div class="ui field">
              <SemanticSelect
                v-model="icookinFields.turboSpeed"
                :class="{ error: errors.turbo_speed }"
                :options="turboSpeedOptions"
                select-class="ui fluid search selection dropdown custom-dropdown recipe-form__dropdown recipe-form__dropdown--small"
              />
            </div>
          </div>

          <div v-show="icookinFields.speed === 'progressive'" class="robot-modes__flex recipe-form__dropdowns">
            <span class="robot-modes__label">
              <label>
                {{ capitalize($i18n.t('activerecord.attributes.step.vitesse_1')) }}
              </label>
            </span>
            <div class="ui field">
              <SemanticSelect
                v-model="icookinFields.pSpeedBegin"
                :class="{ error: errors.progressive_speed_begin }"
                :options="normalSpeedOptions"
                select-class="ui fluid search selection dropdown custom-dropdown recipe-form__dropdown recipe-form__dropdown--small"
              />
            </div>
          </div>

          <div v-show="icookinFields.speed === 'progressive'" class="robot-modes__flex recipe-form__dropdowns">
            <span class="robot-modes__label">
              <label>
                {{ capitalize($i18n.t('activerecord.attributes.step.vitesse_2')) }}
              </label>
            </span>
            <div class="ui field">
              <SemanticSelect
                v-model="icookinFields.pSpeedEnd"
                :class="{ error: errors.progressive_speed_end }"
                :options="normalSpeedOptions"
                select-class="ui fluid search selection dropdown custom-dropdown recipe-form__dropdown recipe-form__dropdown--small"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inflection, api } from './mixins'
import { range } from 'lodash'

import SecondsInput from './SecondsInput.vue'
import SemanticSelect from './SemanticSelect.vue'

export default{
  props: ['stepEndpoint', 'step'],
  mixins: [inflection, api],
  components: { SecondsInput, SemanticSelect },
  data () {
    return {
      icookinFields: {
        cookTime: this.step.cook_time || 0,
        pSpeedBegin: this.step.progressive_speed_begin || 1,
        pSpeedEnd: this.step.progressive_speed_end || 2,
        speed: ['brown', 'petrissage', 'turbo', 'progressive'].includes(this.step.speed) ? this.step.speed : 'normal',
        temperature: this.step.temperature || 0,
        turboSpeed: this.step.turbo_speed || '1t',
        selectedSpeed: parseInt(this.step.speed) || 1
      },
      speedOptions: ['normal', 'brown', 'petrissage', 'turbo', 'progressive'],
      errors: {}
    }
  },
  computed: {
    tempOptions () {
      let values = [0, ...range(40, 150, 10)]

      return values.map( (i) => {
        return { value: i, text: i.toString() }
      })
    },
    normalSpeedOptions () {
      return range(1, 11).map( (i) => {
        return {
          value: i,
          text: i.toString(),
          disabled: i > 5 && this.icookinFields.temperature > 0
        }
      })
    },
    turboSpeedOptions () {
      return range(1, 10).map( (i) => {
        return {
          value: `${i}t`,
          text: `${i}t`
        }
      })
    }
  },
  watch: {
    icookinFields: {
      handler: 'updateFields',
      deep: true
    }
  },
  methods: {
    updateFields () {
      if (this.icookinFields.speed === 'brown' && this.icookinFields.cookTime > 180) {
        this.icookinFields.cookTime = 180
      }

      const params = {
        step: {
          cook_time: this.icookinFields.cookTime,
          progressive_speed_begin: this.icookinFields.pSpeedBegin,
          progressive_speed_end: this.icookinFields.pSpeedEnd,
          speed: this.icookinFields.speed === 'normal' ? this.icookinFields.selectedSpeed : this.icookinFields.speed,
          temperature: this.icookinFields.temperature,
          turbo_speed: this.icookinFields.turboSpeed,
        }
      }

      this.sendRequest('put', this.stepEndpoint, params)
      .then( () => this.errors = {} )
      .catch( (error) => {
        this.errors = error.response.data
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
</style>
