<template>
  <div id="promo" class="tab-pane">
    <div class="panel panel-default">
      <div class="panel-heading">
        <h4 class="box-title">
          Promo
        </h4>
      </div>

      <div class="panel-body">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>Image Horizontale</th>
              <th>Image Verticale</th>
              <th width="60%">Url</th>
              <th class="text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr class="text-center">
              <template v-if="editMode">
                <td>
                  <image-input
                    v-model="horizontalImageFile"
                    :current-image="data.horizontal_image"
                    width="100px"
                  />
                </td>
                <td>
                  <image-input
                    v-model="verticalImageFile"
                    :current-image="data.vertical_image"
                    width="100px"
                  />
                </td>
                <td>
                  <input
                    v-model="data.url"
                    type="text"
                    placeholder="Entrez l'url de la promo"
                    class="form-control"
                    @keyup.enter="save"
                  />
                </td>
                <td>
                  <button @click.prevent="save" class="btn btn-success btn-sm">
                    <i
                      :class="
                        saving
                          ? 'fa fa-fw fa-spinner fa-spin'
                          : 'fa fa-fw fa-check'
                      "
                    ></i>
                  </button>
                  <button
                    @click.prevent="cancelEdit"
                    class="btn btn-danger btn-sm"
                  >
                    <i class="fa fa-fw fa-window-close"></i>
                  </button>
                </td>
              </template>
              <template v-else>
                <td>
                  <img
                    v-if="promo.horizontal_image.url"
                    :src="data.horizontal_image.url"
                    width="100px"
                  />
                </td>
                <td>
                  <img
                    v-if="data.vertical_image.url"
                    :src="data.vertical_image.url"
                    width="100px"
                  />
                </td>
                <td>
                  <a
                    v-if="data.url"
                    href="https://www.google.fr"
                    target="_blank"
                  >
                    {{ data.url }}
                  </a>
                  <span v-else>
                    N\A
                  </span>
                </td>

                <td>
                  <button
                    @click.prevent="editMode = true"
                    class="btn btn-success btn-sm"
                  >
                    <i class="fa fa-fw fa-edit"></i>
                  </button>
                </td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import ImageInput from '@back/components/ImageInput.vue'

export default {
  components: { ImageInput },
  props: {
    page: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      data: { ...this.page.promo },
      editMode: false,
      horizontalImageFile: null,
      verticalImageFile: null,
      saving: false,
    }
  },
  computed: {
    promo() {
      return this.page.promo
    }
  },
  methods: {
    save() {
      let payload = new FormData()

      payload.append('club_home_page[promo_url]', this.data.url)
      if (this.verticalImageFile) {
        payload.append(
          'club_home_page[promo_responsive_image]',
          this.verticalImageFile
        )
      }
      if (this.horizontalImageFile) {
        payload.append(
          'club_home_page[promo_desktop_image]',
          this.horizontalImageFile
        )
      }
      this.saving = true
      this.$http
        .put(`/back/club_home_pages/${this.page.id}`, payload)
        .then(({ data }) => {
          this.data = data
          this.horizontalImageFile = null
          this.verticalImageFile = null
          this.editMode = false
        })
        .catch(() => {
          this.cancelEdit()
        })
    },
    cancelEdit() {
      this.horizontalImageFile = null
      this.verticalImageFile = null
      this.data = { ...this.page.promo }
      this.editMode = false
    },
  },
}
</script>

<style lang="stylus" scoped>
td
  padding: 10px !important
</style>
