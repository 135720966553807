require('../vue-components/recipe-form')
require('../vue-components/user-profile')
require('../vue-components/back/club-home-page')
require('../vue-components/back/welcome-posts')
require('../vue-components/back/thematic-blocs')
require('../vue-components/back/gdm-posts')
require('../vue-components/back/device-news-posts')
require('../vue-components/back/default-followings')
require('../vue-components/back/dashboard-stats')
require('../vue-components/vue-weekly-menus')
require('../vue-components/thematic-blocs-sliders')
require('../vue-components/weekly_menu_modal')
require('../vue-components/favorite_list_modal')
require('../vue-components/shopping_list_modal')
require('../vue-components/product-info-modal')
require('../vue-components/vue-shopping-lists')
require('../vue-components/fridge_recommendations')
