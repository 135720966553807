<template>
  <div>
    <products />
  </div>
</template>

<script>
import Products from './Products'

export default {
  components: {
    Products,
  },
}
</script>
