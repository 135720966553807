<template>
  <form class="ui form custom-form__profile" @submit.prevent="">
    <ohra :owning-mold="data.owning_mold" :slug="data.slug" />
    <canofea
      :canofea-serial="data.canofea_serial"
      @update="updateCanofeaSerial"
    />
    <borealia
      :borealia-serial="data.borealia_serial"
      @update="updateBorealiaSerial"
    />
    <be-save
      :be-save-serial="data.be_save_serial"
      @update="updateBeSaveSerial"
    />
    <icookin :registered-devices="data.registered_devices" />
  </form>
</template>

<script>
import ohra from './Ohra'
import Icookin from './Icookin'
import BeSave from './BeSave'
import Canofea from './Canofea'
import Borealia from './Borealia'

export default {
  components: {
    ohra,
    BeSave,
    Icookin,
    Canofea,
    Borealia,
  },
  data() {
    return {
      data: { ...$('#user-profile-products-data').data('products') },
    }
  },
  methods: {
    updateBeSaveSerial(serial) {
      this.data.be_save_serial = serial
    },
    updateCanofeaSerial(serial) {
      this.data.canofea_serial = serial
    },
    updateBorealiaSerial(serial) {
      this.data.borealia_serial = serial
    },
  },
}
</script>
