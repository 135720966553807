<template>
  <div class="col-md-12">
    <div class="panel panel-default">
      <div class="panel-heading heading">
        <h4 class="box-title">
          Blocs de thématiques
          <i v-if="loading" class="fa fa-fw fa-spinner fa-spin"></i>
        </h4>
        <button
          :disabled="additionDisabled"
          @click.prevent="addBloc"
          class="btn btn-sm btn-success"
        >
          <i class="fa fa-fw fa-plus-square"></i> Ajouter
        </button>
      </div>
      <div class="panel-body">
        <draggable
          v-model="blocs"
          @change="updateList"
          :disabled="additionDisabled"
        >
          <bloc
            v-for="bloc of blocs"
            :key="bloc.id || bloc.tmpId"
            :bloc="bloc"
            @remove="removeBloc"
            @created="saveBloc"
          />
        </draggable>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import Bloc from './Bloc.vue'

export default {
  components: {
    draggable,
    Bloc
  },
  data() {
    return {
      blocs: $('#thematic-blocs-data').data('records'),
      loading: false,
    }
  },
  computed: {
    additionDisabled() {
      const tmpBlocs = this.blocs.filter((bloc) => {
        return !!bloc.tmpId
      })

      return this.loading || tmpBlocs.length > 0
    },
    orderedList() {
      return this.blocs.map((bloc, index) => {
        return { ...bloc, order: this.blocs.length - index }
      })
    },
  },
  methods: {
    updateList() {
      this.loading = true
      this.$http
        .put('/back/thematic_blocs', { thematic_blocs: this.orderedList })
        .then((response) => {
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
        })
    },
    addBloc() {
      if (this.additionDisabled) {
        return
      }

      this.blocs.unshift({
        id: null,
        name: '',
        tmpId: [...Array(16)].map(() => Math.random().toString(36)[2]).join(''),
      })
    },
    removeBloc(removedBloc) {
      let newBlocs = this.blocs.filter((bloc) => {
        if (removedBloc.id) {
          return bloc.id != removedBloc.id
        } else {
          return bloc.tmpId != removedBloc.tmpId
        }
      })

      this.blocs = newBlocs
    },
    saveBloc(record) {
      const index = this.blocs.findIndex((bloc) => {
        return bloc.tmpId === record.tmpId
      })

      this.$set(this.blocs, index, { ...record, tmpId: null })
    },
  }
}
</script>

<style lang="stylus" scoped>
.heading
  display: flex
  justify-content: space-between
  align-items: center
  width: 100%
</style>
