<template>
  <div id="advisor_articles" class="tab-pane">
    <div class="panel panel-default">
      <div class="panel-heading">
        <h4 class="box-title">
          Articles Profil Conseillers
        </h4>
      </div>
      <div class="panel-body">
        <advisor-article
          v-for="(article, index) of data"
          :key="index"
          :article="article"
          :index="index"
          :page-id="page.id"
          @update="update"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AdvisorArticle from './AdvisorArticle'

export default {
  components: {
    AdvisorArticle,
  },
  props: {
    page: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      data: [...this.page.advisor_articles],
    }
  },
  methods: {
    update(articles) {
      this.data = [...articles]
    },
  },
}
</script>

<style lang="stylus" scoped></style>
