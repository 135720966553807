<template>
  <div class="ui container m-t-5 m-b-5">
    <p class="recipe-form__subtitle m-b-1">
      {{ $i18n.t('elements.recipe.text.products_description') }}
    </p>
    <div class="recipe-form__products">
      <div class="products-flex products-flex--left">
        <img :src="require('images/club/products-illustration.webp')" class="ui image" />
      </div>
      <div class="products-flex products-flex--right">
        <p class="custom-text m-b-0">
          {{ $i18n.t('elements.recipe.text.add_product') }}
        </p>
        <div class="recipe-form__dropdowns">
          <SemanticSelect
             v-model="recipeProducts"
             :options="products"
             :placeholder="$i18n.t('elements.product.text.search')"
             :multiple="true"
             :disabled="locked"
             select-class="ui multiple search dropdown pointing top left custom-dropdown recipe-form__dropdown recipe-form__dropdown--search recipe-form__dropdown--small recipe-from__dropdown--accessories selection"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { inflection, api } from './mixins'
import SemanticSelect from './SemanticSelect.vue'

export default{
  mixins: [inflection, api],
  components: { SemanticSelect },
  data () {
    return {
      recipeProducts: null,
      locked: false
    }
  },
  watch: {
    recipeProducts: {
      handler: 'updateProducts',
      deep: true
    }
  },
  computed: {
    ...mapGetters(['recipeData', 'products'])
  },
  methods: {
    updateProducts (newVal, oldVal) {
      if (!oldVal) { return }

      const updateParams = {
        association: {
          model_name: 'Product',
          ids_list: newVal
        }
      }

      this.locked = true
      this.sendRequest('put', `${this.recipePath}/update_association`, updateParams)
      .then( () => this.locked = false )
      .catch( () => this.locked = false )
    }
  },
  created () {
    this.recipeProducts = (this.recipeData.products || []).map( (p) => p.id )
  }
}
</script>

<style lang="stylus" scoped>
</style>
