<template>
  <div class="ui item" @click="addIngredient(source)">
    <span class="result">
      <div class="content">
        <div class="title">{{ source.label }}</div>
      </div>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    source: {
      type: Object,
      required: true,
    },
    addIngredient: {
      type: Function,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped></style>
