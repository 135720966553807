<template>
  <div
    :id="'item-' + item.id"
    @mouseover="onMouseover"
    @mouseleave="hover = false"
    class="ui item-card card list-group-item cursor-pointer"
    :class="{ recipeCard: isRecipeCard, noteCard: isNoteCard }"
  >
    <transition name="fade">
      <div
        v-if="!editMode && hover && (isRecipeCard || (!disabled && !readOnly))"
        class="ui dimmer active"
      >
        <div class="content">
          <div v-if="!disabled && !readOnly">
            <button class="ui fade animated button gdm-red">
              <div class="hidden content">Déplacer</div>
              <div class="visible content">
                <i class="gd-move icon"></i>
              </div>
            </button>
            <button
              class="ui fade animated button gdm-red"
              @click="deleteModalOpen = true"
            >
              <div class="hidden content">Suppr.</div>
              <div class="visible content">
                <i class="trash alternate outline icon" />
              </div>
            </button>
            <button
              class="ui fade animated button gdm-red"
              @click="handleClick"
            >
              <div class="hidden content">
                {{ isRecipeCard ? 'Voir' : 'Éditer' }}
              </div>
              <div class="visible content">
                <i
                  :class="
                    isRecipeCard ? 'gd-eye gdm-icon' : 'pencil alternate icon'
                  "
                />
              </div>
            </button>
          </div>
          <h4 class="break-word" v-if="isRecipeCard" @click="handleClick">
            {{ _truncate(item.recipe.title, { length: '50' }) }}
          </h4>
        </div>
      </div>
    </transition>

    <div class="note-icon" v-if="isNoteCard">
      <i class="icon gd-note" />
    </div>
    <div class="image" v-if="isRecipeCard">
      <img :src="imageUrl" />
    </div>
    <div class="content">
      <div class="description break-word" :class="{ readOnly }">
        <span class="recipe-title" v-if="isRecipeCard">
          {{ item.recipe.title }}
        </span>
        <template v-else>
          <div v-if="!disabled && editMode" class="ui form">
            <div class="field">
              <textarea
                v-click-outside="closeEditMode"
                :disabled="saving"
                rows="7"
                v-model.lazy="editedDesc"
                autofocus
              />
            </div>
          </div>
          <nl2br tag="span" v-else :text="item.description || ''" />
        </template>
      </div>
    </div>
    <sui-modal v-if="!disabled && !readOnly" v-model="deleteModalOpen">
      <sui-modal-header>
        <i class="trash alternate outline icon" />
        Supprimer la carte
      </sui-modal-header>
      <sui-modal-content class="modal-content">
        <sui-modal-description class="modal-description">
          <p>Voulez-vous vraiment supprimer cette carte ?</p>
        </sui-modal-description>
      </sui-modal-content>
      <sui-modal-actions class="no-border transparent">
        <div
          @click="deleteModalOpen = false"
          class="ui deny button custom-button custom-button--filaire"
        >
          Annuler
        </div>
        <div
          @click="deleteItem"
          class="ui positive green button custom-button"
          :class="{ loading: saving }"
        >
          <i class="trash alternate outline icon" />
          Supprimer
        </div>
      </sui-modal-actions>
    </sui-modal>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import _truncate from 'lodash/truncate'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    menuId: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      editMode: this.item.editMode,
      editModeTriggered: this.item.editMode,
      editedDesc: this.item.description,
      deleteModalOpen: false,
      saving: false,
      hover: false,
    }
  },
  computed: {
    isRecipeCard() {
      return !!this.item.recipe
    },
    isNoteCard() {
      return !this.isRecipeCard
    },
    imageUrl() {
      if (this.isRecipeCard) {
        return (
          this.item.recipe.image.url || require('images/recipe-placeholder.webp')
        )
      } else {
        return undefined
      }
    },
  },
  methods: {
    _truncate,
    onMouseover() {
      if (!this.disabled || this.readOnly) {
        this.hover = true
      } else {
        this.hover = false
      }
    },
    handleClick() {
      if (this.isRecipeCard) {
        window.open(`/recettes/${this.item.recipe.slug}`, '_blank')
      } else {
        this.editMode = true
        this.$emit('editing', true)
      }
    },
    closeEditMode() {
      if (this.editMode && this.editModeTriggered) {
        this.editMode = false
        this.editModeTriggered = false
        this.$emit('editing', false)
      } else {
        this.editModeTriggered = true
      }
    },
    async updateDescription() {
      const response = await this.$http.put(
        `/weekly_menus/${this.menuId}/weekly_menu_items/${this.item.id}`,
        { item: { description: this.editedDesc } }
      )

      this.$emit('update', response.data)

      this.editMode = false
    },
    async deleteItem() {
      this.saving = true
      const response = await this.$http.delete(
        `/weekly_menus/${this.menuId}/weekly_menu_items/${this.item.id}`
      )

      this.$emit('delete', this.item)
      this.saving = false
      this.deleteModalOpen = false
    },
  },
  watch: {
    editedDesc: {
      handler: 'updateDescription',
    },
  },
  directives: {
    ClickOutside,
  },
}
</script>

<style lang="scss" scoped>
.animated {
  .hidden {
    display: inherit !important;
    font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  }
}

.modal-description {
  height: auto !important;
  overflow: auto;
}

.modal-content {
  padding: 10px !important;
}

.dimmer {
  z-index: 9999 !important;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0 !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
.cursor-move {
  cursor: move !important;
}
.item-card {
  .readOnly {
    overflow-y: auto !important;
    height: 170px !important;
    word-wrap: break-word !important;
    word-break: break-word !important;
    text-overflow: unset !important;
    white-space: normal !important;
  }
  .break-word {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }
  .gdm-red {
    margin-bottom: 4px;
    background-color: #e83b47;
    border-color: #982830;
    color: white;
    &:hover {
      background-color: #982830;
    }
  }
  &.recipeCard {
    width: 170px !important;
  }
  &.noteCard {
    width: 270px !important;
    .description {
      height: 170px;
      overflow: hidden;
    }
  }
  border: none;
  box-shadow: none;
  margin-top: 0 !important;
  margin-right: 20px;
  display: inline-block;
  height: 215px;
  vertical-align: top;
  textarea {
    font-size: 1em;
  }
  .note-icon {
    padding: 5px;
    color: gray;
  }
  .description {
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    .recipe-title {
      width: 100%;
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .content {
    border-top: none;
    border-radius: 5px;
    padding-top: 0;
  }
  .image {
    width: 170px;
    height: 170px;
    overflow: hidden;
    position: relative;
    border-radius: 5px 5px 0 0 !important;
    margin-bottom: 12px;
  }
}
</style>
