import Vue from 'vue'
const ignoreWarnMessage =
  'The .native modifier for v-on is only valid on components but it was used on <div>.'
Vue.config.warnHandler = function (msg, vm, trace) {
  // `trace` is the component hierarchy trace
  if (msg === ignoreWarnMessage) {
    msg = null
    vm = null
    trace = null
  }
}

// Support for semantic-ui components
import SuiVue from 'semantic-ui-vue'
Vue.use(SuiVue)

// Shared I18n from rails app
Vue.prototype.$i18n = window.I18n

// Setup for ajax requests
import axios from 'axios'
import VueAxios from 'vue-axios'

const csrfToken = document.querySelector('meta[name=csrf-token]').content

axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
axios.defaults.headers.common['Accept'] = 'application/json'
Vue.use(VueAxios, axios)

// RecipeForm
import RecipeForm from './RecipeForm'

// Shared store for recipe data
import store from './RecipeForm/store'
import './RecipeForm/tinyMCE/fr_FR.js'

$(document).ready(() => {
  const el = $('#recipe-form')[0]

  if (el) {
    var recipeForm = new Vue({
      el,
      store,
      render: (h) => h(RecipeForm),
    })

    window.recipeForm = recipeForm
  }
})
