import moment from 'moment'

export default {
  data () {
    return {
      recipePath: ''
    }
  },
  methods: {
    updateEditBarTime () {
      // Update updated_at date_time in edit bar
      $('.recipe-dates__update').text(
        `${this.capitalize(this.$i18n.t('elements.recipe.text.updated_at'))} : ${this.formatDate(moment())}`
      )
    },
    flashMessage (type = 'success', message = null) {
      let key = type === 'success' ? 'updated' : 'cant_save'
      let flash = this.$i18n.t(`elements.recipe.flash.${key}`)

      if (!!message) {
        flash = `${flash} : ${message}`
      }

      window.globalFlashMessage(type, flash)
    },
    sendRequest(method, endpoint, params) {
      return new Promise( (resolve, reject) => {
        this.$http[method](endpoint, params)
        .then( (response) => {
          this.updateEditBarTime()
          this.flashMessage('success')
          resolve(response)
        })
        .catch( (error) => {
          this.flashMessage('error')
          reject(error)
        })
      })
    },
  },
  created () {
    this.recipePath = $('#recipe-path').data('path')
  }
}
