<template>
  <div class="panel panel-default">
    <div class="panel-heading">
      <div class="col-md-4">
        <v-select
          v-if="editMode && !disabled"
          v-model.lazy="selectedRecipe"
          :options="selectOptions"
          :filterable="false"
          label="title"
          placeholder="Rechercher une recette"
          @search="onSearch"
        >
          <template slot="no-options">
            Rechercher une recette par titre ou slug
          </template>

          <template slot="option" slot-scope="option">
            <div class="d-center">
              <img :src="option.image.url" width="30px" />
              {{ option.title }}
            </div>
          </template>

          <template slot="selected-option" slot-scope="option">
            <div class="selected d-center">
              <img :src="option.image.url" width="50px" />
              {{ truncate(option.title, { length: 25 }) }}
            </div>
          </template>
        </v-select>
        <span v-else>
          {{ data.title }}
        </span>
      </div>
      <template v-if="!disabled">
        <button
          class="btn btn-success btn-sm"
          v-if="!editMode"
          @click.prevent="editMode = true"
        >
          <i class="fa fa-fw fa-edit" />
        </button>
        <div v-else>
          <button @click.prevent="save" class="btn btn-sm btn-success">
            <i class="fa fa-fw fa-check" />
          </button>
          <button @click.prevent="cancelEdit" class="btn btn-sm btn-danger">
            <i class="fa fa-fw fa-window-close" />
          </button>
        </div>
      </template>
    </div>
    <div class="panel-body">
      <div class="row" v-if="data.slug">
        <div class="col-md-2">
          <img width="150px" :src="data.image.url" v-if="data.image.url" />
        </div>

        <div class="col-md-10">
          <h5>
            <a :href="'/recettes/' + data.slug" target="_blank">
              {{ data.title }}
            </a>
          </h5>
          <span>
            Crée le {{ data.created_at | moment('DD-MM-YYYY') }} par
            <a :href="'/back/users/' + data.author.slug" target="_blank">
              {{ data.author.username }}
            </a>
          </span>
        </div>
      </div>
      <div class="row" v-else>
        <div class="col-md-10">
          <h5>Recette Supprimée</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    recipe: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editMode: false,
      data: { ...this.recipe },
      selectedRecipe: null,
      selectOptions: [],
      selectLoading: false,
    }
  },
  methods: {
    save() {
      this.$emit('update', {
        oldRecipe: this.recipe,
        newRecipe: this.selectedRecipe,
      })
    },
    cancelEdit() {
      this.data = { ...this.recipe }
      this.editMode = false
    },
    onSearch(search, loading) {
      loading(true)
      this.search(loading, search, this)
    },
    search: _.debounce((loading, search, vm) => {
      vm.$http
        .get('/back/recipes/select', {
          params: {
            search_term: search,
          },
        })
        .then(({ data }) => {
          vm.selectOptions = data
          loading(false)
        })
    }, 350),
    truncate: _.truncate,
  },
}
</script>

<style lang="stylus" scoped>
.panel-heading
  display: flex
  justify-content: space-between
  align-items: center
</style>
