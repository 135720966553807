<template>
  <div class="col-md-12">
    <div class="nav-tabs-custom">
      <ul class="nav nav-tabs">
        <li class="active">
          <a data-toggle="tab" aria-expanded="true" href="#recipes">
            Recettes
          </a>
        </li>
        <li>
          <a aria-expanded="true" href="#headlines" data-toggle="tab">
            À la une
          </a>
        </li>
        <li>
          <a data-toggle="tab" aria-expanded="false" href="#hints_n_news">
            Bons Plans
          </a>
        </li>
        <li>
          <a data-toggle="tab" aria-expanded="false" href="#thematic">
            Thématique
          </a>
        </li>
        <li v-if="page.name == 'home'">
          <a data-toggle="tab" aria-expanded="false" href="#more_info">
            En savoir plus
          </a>
        </li>
        <li v-if="page.name == 'home'">
          <a data-toggle="tab" aria-expanded="false" href="#advisor_articles">
            Profil Conseillers
          </a>
        </li>
        <li v-if="page.name == 'home'">
          <a data-toggle="tab" aria-expanded="false" href="#promo">
            Promo
          </a>
        </li>
        <li v-if="page.name == 'home'">
          <a data-toggle="tab" aria-expanded="false" href="#member">
            Membre
          </a>
        </li>
        <li>
          <a data-toggle="tab" aria-expanded="false" href="#cta_blocks">
            Blocks CTA
          </a>
        </li>
        <li v-if="page.name != 'home'">
          <a data-toggle="tab" aria-expanded="false" href="#video_url">
            Vidéo
          </a>
        </li>
      </ul>

      <div class="tab-content">
        <recipes-form :page="page" />
        <headlines-form :page="page" />
        <hints-n-news-form :page="page" />
        <thematic-form :page="page" />
        <more-info-form :page="page" />
        <advisor-article-form :page="page" />
        <promo-form :page="page" />
        <member-form :page="page" />
        <cta-blocks-form :page="page" />
        <video-url-form :page="page" />
      </div>
    </div>
  </div>
</template>

<script>
import HeadlinesForm from './HeadlinesForm'
import CtaBlocksForm from './CtaBlocksForm'
import RecipesForm from './RecipesForm'
import HintsNNewsForm from './HintsNNewsForm'
import ThematicForm from './ThematicForm'
import MoreInfoForm from './MoreInfoForm'
import AdvisorArticleForm from './AdvisorArticleForm'
import PromoForm from './PromoForm'
import MemberForm from './MemberForm'
import VideoUrlForm from './VideoUrlForm'

export default {
  components: {
    HeadlinesForm,
    CtaBlocksForm,
    RecipesForm,
    HintsNNewsForm,
    ThematicForm,
    MoreInfoForm,
    AdvisorArticleForm,
    PromoForm,
    MemberForm,
    VideoUrlForm
  },
  data() {
    return {
      page: $('#club-home-page-data').data('record'),
    }
  },
}
</script>

<style lang="scss" scoped></style>
