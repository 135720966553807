import Vue from 'vue'

// Shared I18n from rails app
Vue.prototype.$i18n = window.I18n
// Support for semantic-ui components
import SuiVue from 'semantic-ui-vue'
Vue.use(SuiVue)
// Setup for ajax requests
import axios from 'axios'
import VueAxios from 'vue-axios'

const csrfToken = document.querySelector('meta[name=csrf-token]').content

axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
axios.defaults.headers.common['Accept'] = 'application/json'
Vue.use(VueAxios, axios)

//AsyncComputed
import AsyncComputed from 'vue-async-computed'

Vue.use(AsyncComputed)

// ShoppingList
import ShoppingListModal from './ShoppingListModal'

$(document).ready(() => {
  const el = $('#shopping-list-modal')[0]

  if (el) {
    let shoppingListModal = new Vue({
      el,
      render: (h) => h(ShoppingListModal),
    })

    window.shoppingListModal = shoppingListModal

    // Trigger shoppingListModal
    $('.js-shopping-list-trigger').on('click', (e) => {
      const recipeData = $(e.currentTarget).data('recipe')
      const groupId = $(e.currentTarget).data('group-id')

      let $modal = window.shoppingListModal.$children[0]

      $modal.recipe = recipeData
      $modal.selectedIngredientOptions = groupId
      $modal.open = true
    })
  }
})
