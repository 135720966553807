import Vue from 'vue'

// Support for semantic-ui components
import SuiVue from 'semantic-ui-vue'
Vue.use(SuiVue)

// Shared I18n from rails app
Vue.prototype.$i18n = window.I18n

// Setup for ajax requests
import axios from 'axios'
import VueAxios from 'vue-axios'

const csrfToken = document.querySelector('meta[name=csrf-token]').content

axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
axios.defaults.headers.common['Accept'] = 'application/json'
Vue.use(VueAxios, axios)

// ShoppingLists
import ShoppingLists from './ShoppingLists'

$(document).ready(() => {
  const el = $('#vue-shopping-lists')[0]

  if (el) {
    var shoppingLists = new Vue({
      el,
      render: (h) => h(ShoppingLists),
    })

    window.shoppingLists = shoppingLists
  }
})
