<template>
  <div class="section-white">
    <h2 class="profile_top-title">
      <i class="gd-canofea" />
      Canofea
    </h2>

    <p class="little-sub-text">
      Vous avez une machine à café Canofea® ? Renseignez le numéro de série de votre machine à café pour afficher le badge sur votre profil.
    </p>

    <div class="fields">
      <div class="six wide field">
        <label>Numéro de série</label>
        <input type="text" v-model="serial" placeholder="Numéro de série" />
      </div>

      <div v-if="touched" class="two wide field action-button">
        <label>
          <button
            class="ui icon button custom-button custom-button--success"
            :disabled="saving"
            @click.prevent="save"
          >
            <i
              class="fa fa-fw"
              :class="saving ? 'fa-spinner fa-spin' : 'fa-check'"
            />
          </button>
        </label>
      </div>
    </div>

    <div class="ui divider custom-divider--profil m-t-2 m-b-2" />

    <div class="img-be_save">
      <div class="img-device-container__wrap">
        <i class="gdm-information" />
        <div class="be_save-info">
          Le numéro de série de votre Canofea® est visible sur l'étiquette située sous votre machine.
        </div>
      </div>
      <div class="img-serial-container__wrap">
        <img :src="require('images/club/canofea_serial.webp')">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    canofeaSerial: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      serial: this.canofeaSerial,
      saving: false,
    }
  },
  computed: {
    touched() {
      return this.serial !== this.canofeaSerial
    },
  },
  methods: {
    save() {
      this.saving = true

      this.$http
        .put(`/profil/${this.slug}`, { user: { canofea_serial: this.serial } })
        .then(() => {
          this.saving = false
          this.$emit('update', this.serial)
          window.globalFlashMessage('success', 'Modifications enregistrées')
        })
        .catch( () => {
          this.saving = false
          window.globalFlashMessage(
            'error',
            "Impossible d'enregistrer les modifications"
          )
        })
    }
  },
}
</script>

<style lang="stylus" scoped>
.action-button
  display: flex
  align-items: flex-end

.gd-canofea
  vertical-align: text-top
</style>
