<template>
  <div>
    <div class="panel panel-default">
      <div class="panel-heading">
        <input
          v-show="editMode"
          v-model="data.name"
          class="form-control name-input"
          placeholder="Nom"
          ref="nameInput"
          @keydown.enter="toggleEditMode"
        />
        <span v-if="!editMode">
          {{ data.name }}
          <i v-if="saving" class="fa fa-fw fa-spinner fa-spin"></i>
        </span>
        <div class="dates-input-group" v-if="editMode">
          <div class="date_field">
            <label>Du</label>
            <input
              @keydown.enter="toggleEditMode"
              type="date"
              v-model="data.start_on"
              class="form-control"
            />
          </div>
          <div class="date_field">
            <label>Au</label>
            <input
              @keydown.enter="toggleEditMode"
              type="date"
              v-model="data.end_on"
              class="form-control"
            />
          </div>
        </div>
        <span v-else>
          Du
          {{
            this.data.start_on
              ? dayjs(this.data.start_on).format('DD/MM/YYYY')
              : '...'
          }}
          au
          {{
            this.data.end_on
              ? dayjs(this.data.end_on).format('DD/MM/YYYY')
              : '...'
          }}
        </span>
        <div>
          <button
            @click.prevent="toggleEditMode"
            class="btn btn-xs btn-success"
            :disabled="saving || removeLoading"
          >
            <i v-if="saving" class="fa fa-fw fa-spinner fa-spin"></i>
            <i
              v-else
              :class="editMode ? 'fa fa-fw fa-check' : 'fa fa-fw fa-edit'"
            ></i>
          </button>
          <button
            v-if="editMode && !!data.id"
            @click.prevent="cancelEdit"
            class="btn btn-xs btn-danger"
            :disabled="saving || removeLoading"
          >
            <i class="fa fa-fw fa-window-close"></i>
          </button>
          <button
            v-else
            @click.prevent="removeBloc"
            class="btn btn-xs btn-danger"
            :disabled="saving || removeLoading"
          >
            <i
              :class="
                removeLoading
                  ? 'fa fa-fw fa-spinner fa-spin'
                  : 'fa fa-fw fa-trash'
              "
            ></i>
          </button>
        </div>
      </div>
      <div class="panel-body">
        <div class="container-fluid">
          <form @submit.prevent="addThematic" v-if="!editMode">
            <div class="row add-thematic no-gutter">
              <div class="col-md-8">
                <thematic-select
                  v-model="newThematic"
                  class="thematic-select"
                />
              </div>
              <div class="col-md-2">
                <button type="submit" class="btn btn-success">
                  <i class="fa fa-fw fa-plus-square"></i> Ajouter
                </button>
              </div>
            </div>
          </form>
          <div class="row flex">
            <draggable
              class="w-100"
              v-model="data.thematics"
              @change="updateList"
            >
              <div
                class="thematic col-md-2"
                v-for="thematic in data.thematics"
                :key="thematic.id"
              >
                <div class="thematic-wrapper">
                  <img
                    :src="thematic.image.url"
                    width="100%"
                    class="thematic-image"
                  />
                  <p class="thematic-label">{{ thematic.label }}</p>
                  <button
                    class="btn btn-danger btn-xs thematic-remove-btn"
                    @click.prevent="removeThematic(thematic)"
                  >
                    <i class="fa fa-fw fa-trash" />
                  </button>
                </div>
              </div>
            </draggable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import ThematicSelect from '@back/components/ThematicSelect.vue'
import dayjs from 'dayjs'

export default {
  components: { draggable, ThematicSelect },
  props: {
    bloc: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editMode: false,
      saving: false,
      removeLoading: false,
      data: { ...this.bloc },
      newThematic: null,
    }
  },
  computed: {
    orderedList() {
      return this.data.thematics.map((thematic, index) => {
        return { ...thematic, order: index }
      })
    },
  },
  methods: {
    dayjs,
    toggleEditMode() {
      if (this.editMode) {
        !!this.bloc.id ? this.updateBloc() : this.createBloc()
      } else {
        this.editMode = true
        this.$nextTick(() => {
          this.$refs.nameInput.focus()
        })
      }
    },
    updateBloc() {
      this.saving = true
      this.$http
        .put(`/back/thematic_blocs/${this.bloc.id}`, {
          thematic_bloc: {
            name: this.data.name,
            start_on: this.data.start_on,
            end_on: this.data.end_on,
          },
        })
        .then(({ data }) => {
          this.data = data
          this.editMode = false
          this.saving = false
        })
        .catch(() => {
          this.cancelEdit()
        })
    },
    createBloc() {
      this.saving = true
      this.$http
        .post('/back/thematic_blocs', {
          thematic_bloc: {
            name: this.data.name,
            start_on: this.data.start_on,
            end_on: this.data.end_on,
          },
        })
        .then(({ data }) => {
          this.$emit('created', { ...this.bloc, ...data })
          this.editMode = false
          this.saving = false
        })
        .catch((error) => {
          this.saving = false
        })
    },
    removeBloc() {
      if (this.data.tmpId) {
        this.$emit('remove', this.bloc)
        return
      } else {
        this.removeLoading = true
        this.$http
          .delete(`/back/thematic_blocs/${this.bloc.id}`)
          .then((response) => {
            this.$emit('remove', this.bloc)
            this.removeLoading = false
          })
      }
    },
    addThematic() {
      if (!this.data.tmpId) {
        this.saving = true
        this.$http
          .post(`/back/thematic_blocs/${this.bloc.id}/add_thematic`, {
            thematic_id: this.newThematic.id,
          })
          .then(({ data }) => {
            this.data.thematics = [...this.data.thematics, data]
            this.saving = false
            this.newThematic = null
          })
      }
    },
    cancelEdit() {
      this.data = { ...this.bloc }
      this.editMode = false
      this.saving = false
    },
    updateList() {
      this.saving = true
      this.$http
        .put(`/back/thematic_blocs/${this.bloc.id}/update_thematics`, {
          thematics: this.orderedList,
        })
        .then((response) => {
          this.saving = false
        })
        .catch((error) => {
          this.saving = false
        })
    },
    removeThematic(thematic) {
      this.saving = true
      this.$http
        .delete(`/back/thematic_blocs/${this.bloc.id}/remove_thematic`, {
          data: { thematic_id: thematic.id },
        })
        .then((response) => {
          this.data.thematics = this.data.thematics.filter(
            (t) => t.id !== thematic.id
          )
        })
        .finally(() => {
          this.saving = false
        })
    },
  },
  mounted() {
    if (!this.data.id) {
      this.toggleEditMode()
    }
  },
}
</script>

<style lang="stylus" scoped>
.w-100
  width: 100%
.no-gutter [class*="-2"]
  padding-left: 0
.panel-heading
  display: flex
  justify-content: space-between
  align-items: center
  cursor: move
  .name-input
    width: 40%
  .dates-input-group
    width: 40%
    display: flex
    .date_field
      display: flex
      align-items: center
      input
        margin-right: 15px
        margin-left: 5px
.add-thematic
  padding: 10px
  margin-bottom: 10px

.thematic-wrapper
  cursor: move
  background: white
  box-shadow: 5px 6px 15px 0px rgba(0, 0, 0, 0.36)
  border-radius: 5px
  .thematic-image
    border-top-left-radius: 5px
    border-top-right-radius: 5px
  .thematic-label
    padding: 10px
    color: black
    text-align: center
    font-size: 1em
  .thematic-remove-btn
    position: absolute
    top: 5px
    right: 25px
</style>
