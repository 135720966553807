/*global $*/

import { filter, reject } from 'lodash'
import * as Mutations from '../mutations/recipe'

const state = {
  recipe: $('#recipe-form').data('recipe'),
  deleteConfirmation: {
    open: false,
    resource: 'group',
    path: '',
  },
  customIngredientModal: {
    open: false,
    stepEndpoint: '',
    ingredient: {},
  },
  accessories: $('#accessories').data('list'),
  ingredients: {
    units: $('#ingredients').data('units'),
    accessible: $('#ingredients').data('accessible'),
    preps: $('#ingredients').data('preps'),
  },
  thematics: $('#thematics-list').data('thematics'),
  categories: $('#categories-list').data('categories'),
  products: $('#products').data('list'),
  imageCropperModalOpen: false,
  imageDisplayUrl: null,
}

const getters = {
  recipeData(state) {
    return state.recipe
  },
  deleteConfirmation(state) {
    return state.deleteConfirmation
  },
  isIcookable(state) {
    return !!state.recipe.is_icookable
  },
  isDrafted(state) {
    return !!state.recipe.is_drafted
  },
  accessoriesOptions(state) {
    return state.accessories
  },
  ingredientsData(state) {
    return state.ingredients
  },
  thematics(state) {
    return state.thematics
  },
  categories(state) {
    return state.categories
  },
  products(state) {
    return state.products
  },
  customIngredientModal(state) {
    return state.customIngredientModal
  },
  imageCropperModalOpen(state) {
    return state.imageCropperModalOpen
  },
  imageDisplayUrl(state) {
    return state.imageDisplayUrl
  }
}

const mutations = {
  [Mutations.UPDATE_RECIPE](state, data) {
    state.recipe = { ...data }
  },
  [Mutations.CLOSE_CUSTOM_INGREDIENT_MODAL](state, params) {
    state.customIngredientModal.open = false
  },
  [Mutations.OPEN_CUSTOM_INGREDIENT_MODAL](state, params) {
    state.customIngredientModal = {
      ...params,
      open: true,
    }
  },
  [Mutations.CLOSE_DELETE_CONFIRMATION](state, params) {
    state.deleteConfirmation.open = false
  },
  [Mutations.OPEN_DELETE_CONFIRMATION](state, params) {
    state.deleteConfirmation = {
      ...params,
      open: true,
    }
  },
  [Mutations.OPEN_IMAGE_CROPPER_MODAL](state) {
    state.imageCropperModalOpen = true
  },
  [Mutations.CLOSE_IMAGE_CROPPER_MODAL](state) {
    state.imageCropperModalOpen = false
  },
  [Mutations.ADD_INGREDIENT](state, params) {
    let groupIndex = state.recipe.groups.findIndex(
      (g) => g.id === params.groupId
    )
    let stepIndex = state.recipe.groups[groupIndex].steps.findIndex(
      (s) => s.id === params.stepId
    )

    state.recipe.groups[groupIndex].steps[stepIndex].ingredients.push({
      tmpId:
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15),
      ingredient_id: null,
      qty: '',
      unit: 'unit',
    })
  },
  [Mutations.DELETE_INGREDIENT](state, params) {
    let groupIndex = state.recipe.groups.findIndex(
      (g) => g.id === params.groupId
    )
    let stepIndex = state.recipe.groups[groupIndex].steps.findIndex(
      (s) => s.id === params.stepId
    )

    let ingredients =
      state.recipe.groups[groupIndex].steps[stepIndex].ingredients
    let ingredientList = reject(ingredients, {
      tmpId: params.ingredientIdentifier,
    })

    state.recipe.groups[groupIndex].steps[
      stepIndex
    ].ingredients = ingredientList
  },
  [Mutations.ADD_AVAILABLE_INGREDIENT](state, params) {
    if (filter(state.ingredients.accessible, { id: params.id }).length) {
      return
    }

    state.ingredients.accessible = [
      ...state.ingredients.accessible,
      {
        id: params.id,
        label: params.label,
      },
    ]
  },
  [Mutations.SET_IMAGE_DISPLAY_URL](state, url) {
    state.imageDisplayUrl = url
  }
}

const actions = {
  setDisplayImageUrl({ commit }, url) {
    commit(Mutations.SET_IMAGE_DISPLAY_URL, url)
  },
  updateRecipe({ commit }, data) {
    commit(Mutations.UPDATE_RECIPE, data)
  },
  openDeleteConfirmation({ commit }, params) {
    commit(Mutations.OPEN_DELETE_CONFIRMATION, params)
  },
  closeDeleteConfirmation({ commit }, params) {
    commit(Mutations.CLOSE_DELETE_CONFIRMATION, params)
  },
  openCustomIngredientModal({ commit }, params) {
    commit(Mutations.OPEN_CUSTOM_INGREDIENT_MODAL, params)
  },
  closeCustomIngredientModal({ commit }, params) {
    commit(Mutations.CLOSE_CUSTOM_INGREDIENT_MODAL, params)
  },
  addIngredient({ commit }, params) {
    commit(Mutations.ADD_INGREDIENT, params)
  },
  deleteIngredient({ commit }, params) {
    commit(Mutations.DELETE_INGREDIENT, params)
  },
  addAvailableIngredient({ commit }, params) {
    commit(Mutations.ADD_AVAILABLE_INGREDIENT, params)
  },
  openImageCropperModal({ commit }) {
    commit(Mutations.OPEN_IMAGE_CROPPER_MODAL)
  },
  closeImageCropperModal({ commit }, params) {
    commit(Mutations.CLOSE_IMAGE_CROPPER_MODAL)
  }
}

export default {
  state,
  mutations,
  getters,
  actions,
}
