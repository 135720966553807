<template>
  <div class="col-md-12">
    <div class="panel panel-default">
      <div class="panel-heading">
        <h4 class="box-titile">Abonnements automatiques</h4>
      </div>

      <div class="panel-body">
        <div class="row">
          <div class="col-md-8">
            <div class="form-group">
              <user-select v-model="selectedUser" />
              <button
                class="btn btn-success"
                :disabled="!userAddable"
                @click.prevent="addUser"
              >
                + Ajouter
              </button>
            </div>
          </div>
        </div>
        <div class="row m-t-1">
          <div class="col-md-12">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Avatar</th>
                  <th>Id</th>
                  <th>Nom</th>
                  <th>E-mail</th>
                  <th>Statut</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="text-center"
                  v-for="record of records"
                  :key="record.user.id"
                >
                  <td>
                    <img
                      v-if="record.user.image.url"
                      :src="record.user.image.url"
                      width="60px"
                    />
                  </td>
                  <td>
                    <a
                      :href="'/back/users/' + record.user.slug"
                      target="_blank"
                    >
                      {{ record.user.id }}
                    </a>
                  </td>
                  <td>{{ record.user.first_name}} {{ record.user.last_name }}</td>
                  <td>{{ record.user.email }}</td>
                  <td>{{ record.user.profile }}</td>
                  <td>
                    <button
                      @click.prevent="removeUser(record)"
                      class="btn btn-danger"
                    >
                      <i class="fa fa-fw fa-trash" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserSelect from '@back/components/UserSelect.vue'

export default {
  components: {
    UserSelect
  },
  data() {
    return {
      selectedUser: null,
      records: $('#default-followings-data').data('records')
    }
  },
  computed: {
    userAddable() {
      return (
        this.selectedUser &&
        !this.records
          .map(r => {
            return r.user.id
          })
          .includes(this.selectedUser.id)
      )
    }
  },
  methods: {
    addUser() {
      if (!this.userAddable) {
        return
      }

      this.$http
        .post('/back/default_followings', { user_id: this.selectedUser.id })
        .then(({ data }) => {
          this.records.unshift(data)
          this.saving = false
          this.selectedUser = null
        })
    },
    removeUser(record) {
      this.$http.delete(`/back/default_followings/${record.id}`).then(() => {
        this.records = this.records.filter(r => {
          return r.id != record.id
        })
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.form-group
  display: flex
  width: 100%
  button
    margin-left: 15px
    padding: 0 20px
    font-size: 18px
  .v-select
    width: 100%
td
  img
    margin: 10px
</style>
