<template>
  <div class="ingredients-select">
    <div
      class="ui fluid search selection dropdown custom-dropdown recipe-form__dropdown recipe-form__dropdown--small ingredient-description"
      :id="_uid"
      :class="{ disabled }"
      ref="select"
    >
      <input
        type="hidden"
        :value="selected"
        @change="selected = $event.target.value"
      />
      <i class="dropdown icon"></i>
      <div :class="{ default: !selected }" class="text">{{ selectedText }}</div>
      <div class="menu">
        <div
          class="ui item"
          v-for="option in ingredientsData.accessible"
          :key="option.id"
          :data-value="option.id"
        >
          {{ option.label }}
        </div>

        <div class="ui segment clearing no-border no-margin sticky-bottom">
          <a @click="createCustomIngredient" href="javascript:void(0)">
            {{ $i18n.t('elements.ingredient.custom.text.create') }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { inflection, api } from './mixins'
import { find } from 'lodash'

export default {
  props: ['value', 'stepEndpoint', 'ingredient', 'disabled'],
  mixins: [inflection, api],
  data() {
    return {
      selected: this.value,
    }
  },
  computed: {
    ...mapGetters(['ingredientsData']),
    selectedText() {
      if (!this.selected) {
        return this.$i18n.t('elements.ingredient.official.text.ingredient')
      } else {
        return (
          find(this.ingredientsData.accessible, { id: this.selected }) || {
            label: this.$i18n.t('elements.ingredient.official.text.ingredient'),
          }
        ).label
      }
    },
  },
  watch: {
    value() {
      this.selected = this.value
    },
    selected() {
      if (this.selected === this.value) {
        return
      }
      this.$emit('input', this.selected)
    },
  },
  methods: {
    createCustomIngredient() {
      this.$store.dispatch('openCustomIngredientModal', {
        stepEndpoint: this.stepEndpoint,
        ingredient: this.ingredient,
      })
    },
  },
  mounted() {
    $(this.$refs.select).dropdown()
  },
}
</script>

<style lang="stylus" scoped></style>
