import { render, staticRenderFns } from "./Hint.vue?vue&type=template&id=6fa9a038&scoped=true"
import script from "./Hint.vue?vue&type=script&lang=js"
export * from "./Hint.vue?vue&type=script&lang=js"
import style0 from "./Hint.vue?vue&type=style&index=0&id=6fa9a038&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fa9a038",
  null
  
)

export default component.exports