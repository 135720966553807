import 'vue-select/dist/vue-select.css'

import Vue from 'vue'
import vSelect from 'vue-select'

import VueGoogleCharts from 'vue-google-charts'
Vue.use(VueGoogleCharts)

// Setup for ajax requests
import axios from 'axios'
import VueAxios from 'vue-axios'

const csrfToken = document.querySelector('meta[name=csrf-token]').content

axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
axios.defaults.headers.common['Accept'] = 'application/json'
Vue.use(VueAxios, axios)

import VueMce from 'vue-mce'

Vue.use(VueMce)

Vue.use(require('vue-moment'))
Vue.component('v-select', vSelect)

import GdmPosts from './GdmPosts'

$(document).ready(() => {
  const el = $('#gdm-posts')[0]

  if (el) {
    var gdmPost = new Vue({
      el,
      render: (h) => h(GdmPosts),
    })

    window.gdmPost = gdmPost
  }
})
