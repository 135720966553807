import Vue from 'vue'

// Shared I18n from rails app
Vue.prototype.$i18n = window.I18n
// Support for semantic-ui components
import SuiVue from 'semantic-ui-vue'
Vue.use(SuiVue)

// ShoppingList
import ProductInfoModal from './ProductInfoModal'

$(document).ready(() => {
  const el = $('#product-info-modal')[0]

  if (el) {
    let productInfoModal = new Vue({
      el,
      render: (h) => h(ProductInfoModal),
    })

    window.productInfoModal = productInfoModal

    // Trigger productInfoModal
    $('.js-product-info-modal-trigger').on('click', (e) => {
      let $modal = window.productInfoModal.$children[0]

      let $target = $(e.target).parent('.js-product-info-modal-trigger')
      $modal.selectedProduct = $target.data('product')
      $modal.open = true
    })
  }
})
