<template>
  <div class="form-group">
    <Ingredient
       v-for="ingredient in step.ingredients"
       :key="ingredient.id ? ingredient.id : ingredient.tmpId"
       :step-endpoint="stepEndpoint"
       :ingredient="ingredient"
       :group="group"
       :step="step"
       :disabled="saving"
       @saving="toggleSaving"
    />
    <!-- Ingredient -->
    <div class="size wide column m-t-1">
      <button
        class="ui button custom-button custom-button--filaire custom-button--icon m-r-0"
        :class="{ disabled: saving }"
        @click.prevent="addIngredient"
      >
        <i class="gdm-nav-ma-cuisine icon"></i>
        {{ capitalize($i18n.t('elements.recipe.buttons.add_ingredient')) }}
      </button>
    </div>
  </div>
</template>

<script>
import { inflection, api } from './mixins'
import Ingredient from './Ingredient.vue'

export default {
  props: ['stepEndpoint', 'group', 'step'],
  mixins: [inflection, api],
  components: { Ingredient },
  data () {
    return {
      saving: false
    }
  },
  methods: {
    toggleSaving (value) {
      this.saving = value
    },
    addIngredient () {
      this.$store.dispatch('addIngredient', {
        groupId: this.group.id,
        stepId: this.step.id
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
</style>
