<template>
  <div id="thematic" class="tab-pane">
    <div class="panel panel-default">
      <div class="panel-heading">
        <h4 class="box-title">
          Thématique du moment
        </h4>
      </div>
      <div class="panel-body">
        <div class="row">
          <div class="col-md-6 col-md-offset-3">
            <div class="panel panel-default">
              <div v-if="editMode && !loading" class="panel-heading">
                <div class="col-md-9">
                  <v-select
                    v-model="selectedThematic"
                    :options="thematics"
                    placeholder="Rechercher une thématique"
                  >
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        <img :src="option.image.url" width="30px" />
                        {{ option.label }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        <img :src="option.image.url" width="50px" />
                        {{ truncate(option.label, { length: 25 }) }}
                      </div>
                    </template>
                  </v-select>
                </div>
                <div>
                  <button @click="save" class="btn btn-sm btn-success">
                    <i v-if="saving" class="fa fa-fw fa-spin fa-spinner" />
                    <i v-else class="fa fa-fw fa-check" />
                  </button>
                  <button @click="cancelEdit" class="btn btn-sm btn-danger">
                    <i class="fa fa-fw fa-window-close" />
                  </button>
                </div>
              </div>
              <div v-else class="panel-heading">
                <span>{{ data.label }}</span>
                <div>
                  <button
                    class="btn btn-sm btn-success"
                    :disabled="loading"
                    @click="editMode = true"
                  >
                    <i class="fa fa-fw fa-edit" />
                  </button>
                </div>
              </div>
              <div class="panel-body">
                <template v-if="!!data.image">
                  <img :src="data.image.url" width="100%" />
                </template>
              </div>
            </div>
            <div>
              <div class="form-check">
                <label class="form-check-label">
                  <input type="checkbox" v-model="blockVisible" />
                  Block visible
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    page: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data: { ...this.page.thematic },
      blockVisible: this.page.thematic_visible,
      editMode: false,
      selectedThematic: null,
      thematics: [],
      loading: false,
      saving: false,
    }
  },
  watch: {
    blockVisible() {
      this.save()
    }
  },
  methods: {
    fetchThematics() {
      this.loading = true
      this.$http.get('/back/thematics/select').then(({ data }) => {
        this.thematics = data
        this.loading = false
      })
    },
    save() {
      this.saving = true
      this.$http
        .put(`/back/club_home_pages/${this.page.id}`, {
          club_home_page: {
            thematic_id: (this.selectedThematic || this.data).id,
            thematic_visible: this.blockVisible
          },
        })
        .then(() => {
          if (this.selectedThematic) {
            this.data = this.selectedThematic
            this.selectedThematic = null
          }
          this.saving = false
          this.editMode = false
        })
        .catch(() => {
          this.cancelEdit()
        })
    },
    cancelEdit() {
      this.data = this.thematic
      this.selectedThematic = null
      this.editMode = false
    },
    truncate: _.truncate,
  },
  created() {
    this.fetchThematics()
  },
}
</script>

<style lang="stylus" scoped>
.panel-heading
  display: flex
  justify-content: space-between
  align-items: center
  .url-input
    width: 80%
</style>
