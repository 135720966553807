import 'vue-select/dist/vue-select.css'

import Vue from 'vue'
import vSelect from 'vue-select'

// Setup for ajax requests
import axios from 'axios'
import VueAxios from 'vue-axios'

const csrfToken = document.querySelector('meta[name=csrf-token]').content

axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
axios.defaults.headers.common['Accept'] = 'application/json'
Vue.use(VueAxios, axios)

Vue.component('v-select', vSelect)

import ThematicBlocs from './ThematicBlocs'

$(document).ready(() => {
  const el = $('#thematic-blocs')[0]

  if (el) {
    var thematicBlocs = new Vue({
      el,
      render: (h) => h(ThematicBlocs),
    })

    window.thematicBlocs = thematicBlocs
  }
})
