<template>
  <tr>
    <template v-if="loading">
      <td>
        {{ this.rowData.title }} (<i class="fa fa-fw fa-spin fa-spinner" />)
      </td>
      <td class="text-center"><i class="fa fa-fw fa-spin fa-spinner" /></td>
      <td class="text-center"><i class="fa fa-fw fa-spin fa-spinner" /></td>
    </template>
    <template v-else>
      <td>
        {{ rowData.title }}
        <span class="ml-10px" v-if="translatedKeys.length">
          ( {{ translatedKeys.join(' | ') }} )
        </span>
      </td>
      <td>
        <template v-if="data.keys.includes('rate')">
          {{ `${data.obs_period.rate}%` }}
          <span class="ml-10px"> ( {{ data.obs_period.total }} ) </span>
        </template>
        <template v-else-if="data.keys.includes('links')">
          <div
            v-for="link in data.obs_period.links"
            :key="link.url"
            class="m-5px"
          >
            <a :href="link.url" class="chip" target="_blank">
              <img :src="link.image_url" width="60" height="60" />
              {{ link.title }} ({{ link[data.keys[0]] }})
            </a>
            <br />
          </div>
        </template>
        <template v-else>
          {{ data.obs_period.total }}
          <span class="ml-10px"> ( {{ detailedValues.obsPeriod }} ) </span>
        </template>
      </td>
      <td>
        <template v-if="data.keys.includes('rate')">
          {{ `${data.obs_period.rate}%` }}
          <span class="ml-10px"> ( {{ data.comp_period.total }} ) </span>
        </template>
        <template v-else-if="data.keys.includes('links')">
          <div
            v-for="link in data.comp_period.links"
            :key="link.url"
            class="m-5px"
          >
            <a :href="link.url" class="chip" target="_blank">
              <img :src="link.image_url" width="60" height="60" />
              {{ link.title }} ({{ link[data.keys[0]] }})
            </a>
            <br />
          </div>
        </template>
        <template v-else>
          {{ data.comp_period.total }}
          <span class="ml-10px"> ( {{ detailedValues.compPeriod }} ) </span>
        </template>
        <span class="ml-10px" :class="diffClass" v-if="data.percent_diff">
          <i :class="diffIconClass" />&nbsp;{{ data.percent_diff }}%
        </span>
      </td>
    </template>
  </tr>
</template>

<script>
export default {
  props: {
    filters: {
      type: Object,
      required: true,
    },
    rowData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data: {},
      loading: false,
    }
  },
  computed: {
    diffClass() {
      return (this.data.percent_diff || 0) > 0 ? 'text-success' : 'text-danger'
    },
    diffIconClass() {
      return (this.data.percent_diff || 0) > 0
        ? 'fa fa-arrow-alt-circle-up'
        : 'fa fa-arrow-alt-circle-down'
    },
    filteredKeys() {
      if (!this.data.keys) {
        return []
      }

      const filters = ['total', 'links']
      return this.data.keys.filter((k) => !filters.includes(k))
    },
    translatedKeys() {
      return this.filteredKeys.map((key) => {
        return {
          members: 'Membres',
          advisors: 'Conseillers',
          public: 'Publique',
          private: 'Privé',
          draft: 'Brouillon',
          others: 'Autres',
          club: 'Club',
          ick: 'Icookin',
          orphans: 'Non membres',
          rate: 'Utilisateurs uniques',
          new_followers: 'Nouveaux followers',
          new_recipes: 'Nouvelles recettes publiques',
          new_favs: 'Nouveaux favoris',
        }[key]
      })
    },
    detailedValues() {
      return {
        obsPeriod: this.filteredKeys
          .map((key) => this.data.obs_period[key])
          .join(' | '),
        compPeriod: this.filteredKeys
          .map((key) => this.data.comp_period[key])
          .join(' | '),
      }
    },
  },
  methods: {
    async loadData() {
      this.loading = true

      const response = await this.$http.post('/back/dashboard_stats', {
        stat_widget: this.rowData.widget,
        opts: this.rowData.opts || {},
        obs_period: this.filters.obsPeriod,
        comp_period: this.filters.compPeriod,
      })

      this.data = response.data
      this.loading = false
    },
  },
  watch: {
    filters: {
      handler: 'loadData',
      immediate: true,
    },
  },
}
</script>

<style lang="sass" scoped>
  .ml-10px {
    margin-left: 10px;
  }

  .m-5px {
    margin: 5px;
  }
  .chip {
    display: inline-block;
    padding: 0 25px;
    height: 30px;
    font-size: 14px;
    line-height: 30px;
    border-radius: 25px;
    background-color: lightgrey;
    color: black;
    &:hover {
      background-color: grey;
    }
    img {
      float: left;
      margin: 0 10px 0 -25px;
      height: 30px;
      width: 30px;
      border-radius: 50%;
    }
  }
</style>
