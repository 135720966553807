<template>
  <div>
    <div
      class="panel"
      :class="inactive ? 'panel-danger' : 'panel-success'"
    >
      <div class="panel-heading">
        <input
          v-if="editMode"
          v-model="data.url"
          class="form-control url-input"
          placeholder="URL de redirection"
        />
        <span v-else>
          <a
            :href="data.url"
            target="_blank"
          >
            {{ data.url }}
          </a>
        </span>
        <div>
          <button
            v-if="errors.length < 1"
            @click.prevent="toggleEditMode"
            class="btn btn-xs btn-success"
          >
            <i
              v-if="saving"
              class="fa fa-fw fa-spinner fa-spin"
            ></i>
            <i
              v-else
              :class="editMode ? 'fa fa-fw fa-check' : 'fa fa-fw fa-edit'"
            ></i>
          </button>
          <button
            v-if="editMode && !!data.id"
            @click.prevent="cancelEdit"
            class="btn btn-xs btn-danger"
          >
            <i class="fa fa-fw fa-window-close"></i>
          </button>
          <button
            v-else
            @click.prevent="removePost"
            class="btn btn-xs btn-danger"
          >
            <i :class="
                removeLoading
                  ? 'fa fa-fw fa-spinner fa-spin'
                  : 'fa fa-fw fa-trash'
              "></i>
          </button>
        </div>
      </div>
      <div class="panel-body">
        <div
          class="row"
          :class="{ 'display-stats': statsMode }"
        >
          <div class="col-md-4 text-center">
            <image-input
              v-model="file"
              :current-image="{ url: displayImageUrl }"
              v-if="editMode"
              class="m-t-1"
            />
            <img
              v-else-if="data.image.url"
              :src="data.image.url"
              width="100%"
            />
            <div
              class="text-left m-t-3 form-group"
              :class="{
                'has-error': errors.includes('display_date'),
              }"
            >
              <label>
                À partir du :
              </label>
              <date-input
                v-if="editMode"
                v-model="data.display_date"
              />
              <span v-else>{{ data.display_date }}</span>
            </div>
            <div
              class="text-left form-group"
              :class="{
                'has-error': errors.includes('expire_date'),
              }"
            >
              <label>
                Jusqu'au (inclus) :
              </label>

              <date-input
                v-if="editMode"
                v-model="data.expire_date"
              />
              <span v-else>{{ data.expire_date }}</span>
            </div>
            <div class="text-left form-group">
              <label>
                Nombre de likes :
              </label>
              <span>{{ totalLikersCount }}</span>
            </div>
            <div
              v-if="totalLikersCount > 0"
              class="text-left form-group clickable"
              @click.prevent="statsMode = true"
            >
              <label>
                Répartition :
              </label>
              <g-chart
                type="PieChart"
                :data="[
                  ['Profil', 'Nombre de likes'],
                  ['Membres', data.likers_count.members],
                  ['Conseillers', data.likers_count.advisors],
                  ['Autre', data.likers_count.others],
                ]"
                :options="{
                  pieHole: 0.3,
                  is3d: true,
                }"
              />
            </div>
          </div>

          <div
            v-if="statsMode"
            class="col-md-8"
          >
            <div style="text-align: right;">
              <i
                class="fa fa-close clickable"
                @click="statsMode = false"
              ></i>
            </div>
            <g-chart
              type="AreaChart"
              @ready="drawChart"
            />
          </div>
          <div
            v-else
            class="col-md-8"
          >
            <div
              class="form-group"
              :class="{
                'has-error': errors.includes('user'),
              }"
            >
              <label>Auteur</label>
              <div v-if="editMode">
                <user-select
                  v-model="data.user"
                  :disabled="saving"
                />
              </div>
              <div v-else-if="data.user">
                <img
                  v-if="data.user.image.url"
                  :src="data.user.image.url"
                  class="author-image"
                />
                {{ data.user.first_name }} {{ data.user.last_name }} ({{
                  data.user.email
                }})
              </div>
              <div v-else>
                N / A
              </div>
            </div>
            <div
              class="form-group m-t-1"
              :class="{
                'has-error': errors.includes('display_date'),
              }"
            >
              <label>Contenu</label>
              <vue-mce
                v-if="editMode"
                v-model="data.content"
                :config="{
                  toolbar:
                    'styleselect | bold italic underline | undo redo | link | media | image | code',
                  plugins: 'link image media code',
                }"
              />
              <div
                class="content"
                v-else
                v-html="data.content"
              >Contenu</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageInput from '@back/components/ImageInput.vue'
import DateInput from '@back/components/DateInput.vue'
import UserSelect from '@back/components/UserSelect.vue'

export default {
  components: {
    ImageInput,
    DateInput,
    UserSelect
  },
  props: {
    post: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      statsMode: false,
      editMode: !this.post.id,
      saving: false,
      removeLoading: false,
      data: { ...this.post },
      file: null
    }
  },
  computed: {
    chartOptions() {
      return {
        isStacked: true,
        legend: { position: 'bottom' },
        height: 500,
        explorer: {
          actions: ['dragToZoom', 'rightClickToReset'],
          axis: 'horizontal',
          keepInBounds: true,
          maxZoomIn: 4.0
        },
        vAxis: { minValue: 0, viewWindow: { min: 0 }, format: '0' },
        hAxis: {
          format: 'dd-MM-y'
        }
      }
    },
    statsData() {
      let data = [['Date', 'Membres', 'Conseillers', 'Autres']]

      for (let key of Object.keys(this.data.likers_stats)) {
        data.push([
          new Date(key),
          this.data.likers_stats[key].members || 0,
          this.data.likers_stats[key].advisors || 0,
          this.data.likers_stats[key].others || 0
        ])
      }

      return data
    },
    inactive() {
      const today = moment().startOf('day')

      const displayable = today.isSameOrAfter(
        moment(this.data.display_date, 'DD-MM-YYYY')
      )

      const archived = today.isAfter(
        moment(this.data.expire_date, 'DD-MM-YYYY')
      )

      return !displayable || archived
    },
    errors() {
      let errors = []
      if (!this.data.display_date) {
        errors.push('display_date')
      }

      if (this.data.display_date && this.data.expire_date) {
        let displayDate = moment(this.data.display_date, 'DD-MM-YYYY')
        let expireDate = moment(this.data.expire_date, 'DD-MM-YYYY')

        if (displayDate.isSameOrAfter(expireDate)) {
          errors.push('expire_date')
        }
      }

      if (!this.data.content) {
        errors.push('content')
      }

      if (!this.data.user || _.isEmpty(this.data.user)) {
        errors.push('user')
      }

      return errors
    },
    displayImageUrl() {
      if (this.file) {
        return URL.createObjectURL(this.file)
      } else {
        return this.data.image.url
      }
    },
    totalLikersCount() {
      let count = this.data.likers_count
      return count.members + count.advisors + count.others
    },
    payload() {
      let payload = new FormData()
      payload.append('gdm_post[url]', this.data.url)
      payload.append('gdm_post[content]', this.data.content)
      payload.append('gdm_post[display_date]', this.data.display_date)
      payload.append('gdm_post[expire_date]', this.data.expire_date)
      payload.append('gdm_post[user_id]', this.data.user.id)

      if (this.file) {
        payload.append('gdm_post[image]', this.file)
      }

      return payload
    }
  },
  methods: {
    drawChart(chart, google) {
      if (google && Object.keys(this.data.likers_stats || {}).length > 0) {
        let formatter = new google.visualization.DateFormat({
          pattern: 'dd-MM-y'
        })

        const data = google.visualization.arrayToDataTable(this.statsData)

        formatter.format(data, 0)

        chart.draw(data, this.chartOptions)
      }
    },
    toggleEditMode() {
      if (this.editMode) {
        !!this.post.id ? this.updatePost() : this.createPost()
      } else {
        this.editMode = true
      }
    },
    updatePost() {
      this.saving = true
      this.$http
        .put(`/back/gdm_posts/${this.post.id}`, this.payload)
        .then(({ data }) => {
          this.data = data
          this.editMode = false
          this.saving = false
        })
        .catch(() => {
          this.cancelEdit()
        })
    },
    createPost() {
      this.saving = true
      this.$http
        .post('/back/gdm_posts', this.payload)
        .then(({ data }) => {
          this.$emit('created', { ...this.post, ...data })
          this.editMode = false
          this.saving = false
        })
        .catch(error => {
          this.saving = false
        })
    },
    removePost() {
      if (!confirm('Voulez-vous vraiment supprimer ce post ?')) {
        return
      }
      if (this.data.tmpId) {
        this.$emit('remove', this.post)
        return
      } else {
        this.$http.delete(`/back/gdm_posts/${this.post.id}`).then(response => {
          this.$emit('remove', this.post)
        })
      }
    },
    cancelEdit() {
      this.data = { ...this.post }
      this.file = null
      this.editMode = false
      this.saving = false
    },
    toggleActivity() {
      if (this.editMode) {
        this.data.expire_date = !this.data.expire_date
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.clickable
  cursor: pointer
.panel-heading
  display: flex
  justify-content: space-between
  align-items: center
  .url-input
    width: 80%
.body-content
  display: flex
  align-items: center
.author-image
  margin-right: 15px
  width: 30px
.content
  border: 1px solid gray
  border-radius: 5px
.toggle-icon
  font-size: 25px
  vertical-align: sub
  cursor: pointer
  &.fa-toggle-on
    color: #4cae4c
  &.fa-toggle-off
    color: lightgray
</style>
