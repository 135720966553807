<template>
  <div class="col-md-12">
    <div class="panel panel-default">
      <div class="panel-heading heading">
        <h4 class="box-title">
          Posts de Bienvenue
          <i v-if="loading" class="fa fa-fw fa-spinner fa-spin"></i>
        </h4>
        <button
          :disabled="additionDisabled"
          @click.prevent="addPost"
          class="btn btn-sm btn-success"
        >
          <i class="fa fa-fw fa-plus-square"></i> Ajouter
        </button>
      </div>
      <div class="panel-body">
        <draggable
          v-model="posts"
          @change="updateList"
          :disabled="additionDisabled"
        >
          <post
            v-for="post of posts"
            :key="post.id || post.tmpId"
            :post="post"
            @remove="removePost"
            @created="savePost"
          />
        </draggable>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import Post from './Post.vue'

export default {
  components: {
    draggable,
    Post,
  },
  data() {
    return {
      posts: $('#welcome-posts-data').data('records'),
      loading: false,
    }
  },
  computed: {
    additionDisabled() {
      const tmpPosts = this.posts.filter((post) => {
        return !!post.tmpId
      })

      return this.loading || tmpPosts.length > 0
    },
    orderedList() {
      return this.posts.map((post, index) => {
        return { ...post, order: this.posts.length - index }
      })
    },
  },
  methods: {
    updateList() {
      this.loading = true
      this.$http
        .put('/back/welcome_posts', { welcome_posts: this.orderedList })
        .then((response) => {
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
        })
    },
    savePost(record) {
      const index = this.posts.findIndex((post) => {
        return post.tmpId === record.tmpId
      })

      this.$set(this.posts, index, { ...record, tmpId: null })
    },
    addPost() {
      if (this.additionDisabled) {
        return
      }

      this.posts.unshift({
        id: null,
        url: '',
        content: '',
        image: { url: '' },
        duration: 7,
        active: false,
        user_id: null,
        tmpId: [...Array(16)].map(() => Math.random().toString(36)[2]).join(''),
      })
    },
    removePost(removedPost) {
      let newPosts = this.posts.filter((post) => {
        if (removedPost.id) {
          return post.id != removedPost.id
        } else {
          return post.tmpId != removedPost.tmpId
        }
      })

      this.posts = newPosts
    },
  },
}
</script>

<style lang="stylus" scoped>
.heading
  display: flex
  justify-content: space-between
  align-items: center
  width: 100%
</style>
