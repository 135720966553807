<template>
  <div id="recipes" class="tab-pane active">
    <div class="panel panel-default">
      <div class="panel-heading">
        <h4>
          Recettes
          <i v-if="saving" class="fa fa-fw fa-spinner fa-spin" />
        </h4>
      </div>
      <div class="panel-body">
        <draggable v-model="data" :disabled="saving" @update="updateRecipes">
          <recipe
            class="panel panel-default"
            v-for="recipe of data"
            :recipe="recipe"
            :key="recipe.id"
            :disabled="saving"
            @update="updateRecipe"
          />
        </draggable>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import Recipe from './Recipe.vue'

export default {
  components: {
    draggable,
    Recipe,
  },
  props: {
    page: {
      type: Object,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      data: [...this.page.recipes],
      saving: false,
      editMode: false,
    }
  },
  computed: {
    recipeIds() {
      return this.data.map((recipe) => {
        return recipe.id
      })
    },
  },
  methods: {
    reset() {
      this.data = [...this.page.recipes]
      this.editMode = false
    },
    updateRecipe({ oldRecipe, newRecipe }) {
      const recipeIndex = this.data.findIndex((recipe) => {
        return recipe.id == oldRecipe.id
      })
      this.data.splice(recipeIndex, 1, newRecipe)
      this.updateRecipes()
    },
    updateRecipes() {
      this.saving = true
      this.$http
        .put(`/back/club_home_pages/${this.page.id}`, {
          club_home_page: { recipe_ids: this.recipeIds },
        })
        .then(({ data }) => {
          this.saving = false
        })
        .catch((error) => {
          this.reset()
          this.saving = false
        })
    },
  },
}
</script>

<style lang="stylus" scoped></style>
