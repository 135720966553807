<template>
  <sui-modal
    v-model="open"
    size="small"
    class="transition custom-modal"
    @clickAwayModal="$emit('onClose')"
  >
    <i class="close icon" @click="$emit('onClose')"></i>

    <sui-modal-header class="no-border">
      {{ capitalize($i18n.t('elements.recipe.text.custom_ingredient.header')) }}
    </sui-modal-header>

    <sui-modal-content>
      <sui-message
        error
        style="position: relative; display: block"
        v-if="Object.keys(errors).length"
      >
        <ul>
          <li v-for="key in Object.keys(errors)" :key="key">
            {{
              capitalize(
                $i18n.t(`activerecord.attributes.custom_ingredient.${key}`)
              )
            }}
            {{ errors[key][0] }}
          </li>
        </ul>
      </sui-message>
      <span class="primary-color">
        {{ $i18n.t('elements.recipe.text.custom_ingredient.content') }}
      </span>
      <form class="ui form" @submit.prevent="createCustomIngredient">
        <div class="field m-t-2" :class="{ error: errors.label }">
          <label>
            {{
              capitalize(
                $i18n.t('activerecord.attributes.custom_ingredient.label')
              )
            }}
          </label>
          <input
            class="ui input custom-ingredient-label"
            type="text"
            v-model="label"
          />
        </div>
        <div class="field" :class="{ error: errors.prep }">
          <label>
            {{
              capitalize(
                $i18n.t('activerecord.attributes.custom_ingredient.prep')
              )
            }}
          </label>
          <SemanticSelect
            v-model="prep"
            :options="prepOptions"
            select-class="ui fluid dropdown custom-dropdown recipe-form__dropdown recipe-form__dropdown--modal custom-ingredient-prep selection"
          />
        </div>
      </form>
    </sui-modal-content>

    <sui-modal-actions class="no-border transparent">
      <sui-button
        deny
        class="custom-button custom-button--filaire"
        @click="$emit('onClose')"
      >
        {{ capitalize($i18n.t('elements.global.buttons.cancel')) }}
      </sui-button>
      <sui-button
        positive
        :loading="loading"
        class="custom-button"
        @click="createCustomIngredient"
      >
        {{ capitalize($i18n.t('elements.global.buttons.valid')) }}
      </sui-button>
    </sui-modal-actions>
  </sui-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import { inflection, api } from './mixins'
import SemanticSelect from './SemanticSelect.vue'

export default {
  props: ['open', 'stepEndpoint', 'ingredient'],
  mixins: [inflection, api],
  components: { SemanticSelect },
  data() {
    return {
      loading: false,
      label: '',
      prep: '',
      errors: {},
    }
  },
  computed: {
    ...mapGetters(['ingredientsData']),
    prepOptions() {
      return this.ingredientsData.preps.map((p) => {
        return { value: p, text: p }
      })
    },
  },
  methods: {
    createCustomIngredient() {
      this.loading = true
      this.sendRequest(
        'post',
        `${this.stepEndpoint}/create_custom_ingredient`,
        {
          membership_id: this.ingredient.id || '',
          membership: {
            qty: this.ingredient.qty,
            unit: this.ingredient.unit,
          },
          custom_ingredient: {
            label: this.label,
            prep: this.prep,
          },
        }
      )
        .then((response) => {
          this.$store.dispatch('updateRecipe', response.data.recipe)
          this.$store.dispatch(
            'addAvailableIngredient',
            response.data.ingredient
          )

          this.label = ''
          this.prep = ''
          this.loading = false
          this.$emit('onClose')
        })
        .catch((error) => {
          this.loading = false
          this.errors = error.response.data
        })
    },
  },
}
</script>

<style lang="stylus" scoped></style>
