<template>
  <div class="thematic-blocs-container m-t-5">
    <div class="thematic-blocs" v-for="(bloc, index) in blocs" :key="bloc.id">
      <h2 class="ui custom-title custom-title--h2 pl-1">{{ bloc.name }}</h2>
      <carousel
        :navigation-enabled="true"
        :per-page-custom="[
          [0, 1],
          [515, 3],
          [991, 4],
        ]"
        :scroll-per-page="false"
        navigation-next-label=">"
        navigation-prev-label="<"
      >
        <slide
          v-for="thematic in bloc.thematics"
          :key="`${bloc.id}-${thematic.id}`"
        >
          <a
            :href="`/recettes?lock=thematics&thematics=${thematic.label}`"
            class="ui centered card category-card thematic-card"
            target="_blank"
          >
            <div class="ui image">
              <img
                :src="thematic.image.url"
                :alt="thematic.label"
                class="thematic-image"
              />
            </div>
            <div class="content">
              <div class="header" :class="sizeClassFor(thematic.label)">
                {{ thematic.label }}
              </div>
            </div>
          </a>
        </slide>
        <slide v-if="bloc.name == 'Nos produits emblématiques'">
          <a
            href="/categories-produits"
            class="ui centered card category-card thematic-card"
            target="_blank"
          >
            <div class="ui image">
              <img
                :src="require('images/club/thematic-recettes-ohra.webp')"
                alt="Recettes par moule OHRA®"
                class="thematic-image"
              />
            </div>
            <div class="content">
              <div
                class="header"
                style="font-size: 0.99em; padding-top: 2px; padding-bottom: 1px"
              >
                Recettes par moule OHRA®
              </div>
            </div>
          </a>
        </slide>
      </carousel>
      <div
        class="ui divider custom-divider--small categories-divider"
        v-if="index < blocs.length - 1"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      blocs: $('#thematic-blocs-sliders-data').data('records'),
    }
  },
  methods: {
    truncate: _.truncate,
    sizeClassFor(str) {
      if (str.length < 20) {
        return 'text-md'
      } else if (str.length < 30) {
        return 'text-sm'
      } else {
        return 'text-xs'
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
.pl-1
  padding-left: 1em
.thematic-card
  margin-top: 20px!important
  margin-bottom: 30px!important
  width: 80%
  &:hover
    box-shadow: 0 2px 23px 0 rgba(0, 0, 0, 0.15) !important
    transform: none !important
.text-md
  font-size: 1.2em !important
  @media screen and (min-width: 515px) and (max-width: 991px)
    font-size: 1.1em !important
.text-sm
  font-size: 1em !important
  @media screen and (min-width: 515px) and (max-width: 991px)
    font-size: 0.9em !important
.text-xs
  font-size: 0.7em !important
.categories-divider
  margin: 20px auto !important
</style>

<style lang="scss">
.VueCarousel-navigation-button {
  background-color: #e83b47 !important;
  color: white !important;
  font-size: 19px !important;
  border: 0 !important;
  border-radius: 30px !important;
  padding: 9px 12px !important;
}

.VueCarousel-navigation--disabled {
  display: none;
}

.VueCarousel-navigation-next,
.VueCarousel-navigation-prev {
  @media screen and (max-width: 991px) {
    display: none !important;
  }
}

.VueCarousel-dot-container {
  @media screen and (min-width: 992px) {
    display: none !important;
  }
  margin-top: 0 !important;
  margin-bottom: 20px !important;
  .VueCarousel-dot {
    margin-top: 0 !important;
  }
}
</style>
