<template>
  <div class="row">
    <div class="ingredient-fields">
      <div class="ingredients-wrap recipe-form__dropdowns">
        <div class="ingredients-flex ingredient-qty">
          <input
            type="text"
            placeholder="0"
            v-model.lazy="ingredientFields.qty"
            :disabled="disabled"
          />
        </div>
        <div class="ingredients-flex">
          <SemanticSelect
            v-model="ingredientFields.unit"
            :options="unitOptions"
            :select-class="selectClass"
          />
        </div>
        <div class="ingredients-flex">
          <IngredientField
            v-model="ingredientFields.ingredientId"
            :stepEndpoint="stepEndpoint"
            :ingredient="{
              ...ingredient,
              qty: ingredientFields.qty,
              unit: ingredientFields.unit,
            }"
            :disabled="disabled"
          />
        </div>
        <div class="ingredients-flex ingredient-remove">
          <a
            class="custom-link--remove"
            @click.prevent="confirmDeletion"
            :class="{ disabled }"
          >
            <i class="gdm-supprimer icon"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { inflection, api } from './mixins'

import SemanticSelect from './SemanticSelect.vue'
import IngredientField from './IngredientField.vue'

export default {
  props: ['stepEndpoint', 'ingredient', 'group', 'step', 'disabled'],
  mixins: [inflection, api],
  components: { SemanticSelect, IngredientField },
  data() {
    return {
      updateLock: 0,
      lockVersion: 0,
      ingredientFields: {
        qty: this.ingredient.qty,
        unit: this.ingredient.unit || 'unit',
        ingredientId: (this.ingredient.ingredient || {}).id,
      },
    }
  },
  computed: {
    ...mapGetters(['ingredientsData']),
    unitOptions() {
      return this.ingredientsData.units.map((u) => {
        return {
          value: u,
          text: this.$i18n.t(
            `activerecord.enums.steps_ingredients_membership.units.long.${u}`
          ),
        }
      })
    },
    selectClass() {
      let sClass =
        'ui fluid search selection dropdown custom-dropdown recipe-form__dropdown recipe-form__dropdown--small unit-description'

      if (this.disabled) {
        sClass = sClass + ' disabled'
      }

      return sClass
    },
  },
  watch: {
    ingredientFields: {
      handler: 'saveIngredient',
      deep: true,
    },
  },
  methods: {
    saveIngredient() {
      if (!this.ingredientFields.ingredientId) {
        return
      }
      if (this.updateLock != this.lockVersion) {
        this.lockVersion = this.updateLock
        return
      }

      this.$emit('saving', true)
      const ingredientParams = {
        membership: {
          ingredient_id: this.ingredientFields.ingredientId,
          qty: this.ingredientFields.qty,
          unit: this.ingredientFields.unit,
        },
      }

      let endpoint, method

      if (this.ingredient.id) {
        endpoint = `${this.stepEndpoint}/steps_ingredients_memberships/${this.ingredient.id}`
        method = 'put'
      } else {
        endpoint = `${this.stepEndpoint}/steps_ingredients_memberships`
        method = 'post'
      }

      this.sendRequest(method, endpoint, ingredientParams)
        .then((response) => {
          this.$store.dispatch('updateRecipe', response.data)
          this.$emit('saving', false)
        })
        .catch(() => this.$emit('saving', false))
    },
    confirmDeletion() {
      if (this.disabled) {
        return
      }
      if (this.ingredient.id) {
        this.$store.dispatch('openDeleteConfirmation', {
          resource: 'ingredient',
          path: `${this.stepEndpoint}/steps_ingredients_memberships/${this.ingredient.id}`,
        })
      } else {
        this.$store.dispatch('deleteIngredient', {
          groupId: this.group.id,
          stepId: this.step.id,
          ingredientIdentifier: this.ingredient.tmpId,
        })
      }
    },
  },
  updated() {
    if (
      !this.ingredient.ingredient ||
      this.ingredientFields.ingredientId === this.ingredient.ingredient.id
    ) {
      return
    }

    this.updateLock = this.updateLock + 1
    this.ingredientFields.ingredientId = this.ingredient.ingredient.id
  },
}
</script>

<style lang="stylus" scoped>
a.disabled
  opacity: .5
  cursor: normal
input
  &:disabled
    opacity: .5
</style>
