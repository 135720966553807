<template>
  <div class="fields">
    <div class="five wide field">
      <label>
        <input
          type="text"
          v-model="data.device.serial_number"
          disabled
        />
      </label>
    </div>

    <div class="five wide field">
      <label>
        <input
          type="text"
          v-model="data.device.icookin_number"
          disabled
        />
      </label>
    </div>

    <div class="five wide field">
      <label>
        <input
          type="text"
          v-model="data.alias"
        />
      </label>
    </div>

    <div class="two wide field action-button">
      <label>
        <button
          v-if="touched"
          class="ui icon button custom-button custom-button--success"
          :disabled="loading"
          @click.prevent="update"
        >
          <i
            class="fa fa-fw"
            :class="loading ? 'fa-spinner fa-spin' : 'fa-check'"
          />
        </button>

        <button
          v-else
          class="ui icon button custom-button"
          :disabled="loading"
          @click.prevent="remove"
        >
          <i
            class="fa fa-fw"
            :class="loading ? 'fa-spinner fa-spin' : 'fa-trash'"
          />
        </button>
      </label>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      icookin: {
        type: Object,
        required: true
      },
    },
    data() {
      return {
        data: { ...this.icookin },
        loading: false
      }
    },
    computed: {
      touched() {
        return this.data.alias !== this.icookin.alias
      }
    },
    methods: {
      remove() {
        this.loading = true

        this.$http.delete(`/device_user_memberships/${this.data.id}`)
        .then( () => {
          this.$emit('removeDevice', this.data.id)
          window.globalFlashMessage("success", "Modifications enregistrées")
          this.loading = false
        })
        .catch( () => {
          this.loading = false
        })
      },
      update() {
        this.loading = true

        this.$http.put(`/device_user_memberships/${this.data.id}`, { device_user_membership: { alias: this.data.alias }})
        .then( () => {
          this.$emit('updateDevice', { ...this.data })
          window.globalFlashMessage("success", "Modifications enregistrées")
          this.loading = false
        })
        .catch( () => {
          this.loading = false
        })
      }
    },
  }
</script>

<style lang="stylus" scoped>
.action-button
  display: flex
  align-items: flex-end
</style>
