<template>
  <div class="accessories-wrap">
    <div class="accessories-flex">
      <i class="gdm-spatule icon"></i>
      <SemanticSelect
        v-model="accessories"
        :options="accessoriesOptions"
        :multiple="true"
        :disabled="locked"
        select-class="ui fluid search multiple selection dropdown custom-dropdown recipe-form__dropdown recipe-form__dropdown--accessories recipe-form__dropdown--big"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { inflection, api } from './mixins'
import SemanticSelect from './SemanticSelect.vue'

export default{
  props: ['stepEndpoint', 'step'],
  mixins: [inflection, api],
  components: { SemanticSelect },
  computed: {
    ...mapGetters(['accessoriesOptions'])
  },
  data () {
    return {
      accessories: this.step.accessories.map( (a) => a.id ),
      locked: false
    }
  },
  methods: {
    updateAccessories () {
      const params = { step: { accessories: this.accessories } }

      this.locked = true
      this.sendRequest('put', `${this.stepEndpoint}/update_accessories`, params)
      .then( () => this.locked = false )
      .catch( () => this.locked = false )
    }
  },
  watch: {
    accessories: {
      handler: 'updateAccessories',
      deep: true
    }
  }
}
</script>

<style lang="stylus" scoped>
</style>
