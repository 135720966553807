<template>
  <div class="ui container m-b-1">
    <div class="page-header m-b-1">
      <h1 class="custom-title custom-title--h1 primary-color">
        <i class="gd-fridge gdm-icon icon" />
        <br />
        Je vide mon Frigo
      </h1>
      <p class="waiting text except-mobile">
        En panne d’inspiration pour métamorphoser les restes en délicieuses
        recettes ? Faites appel à la fonctionnalité “Je vide mon frigo” ! 100%
        gratuite et simple d’utilisation, elle vous propose des suggestions
        adaptées aux ingrédients que vous avez à la maison. Fini le gaspillage
        alimentaire, place aux recettes vide-frigo ! Sélectionnez vos
        ingrédients et découvrez un éventail de possibilités parmi plus de 24
        000 plats disponibles !
      </p>
      <p class="waiting text mobile-only">
        En panne d'inspiration pour métamorphoser les restes en délicieuses recettes ? Faites appel à la fonctionnalité "Je vide mon frigo".
      </p>
    </div>
    <div
      v-if="resultsLoading"
      class="ui container text-center m-t-2"
      data-recos="on"
      id="recommendations_wrapper"
    >
      <img alt="" :src="require('images/club/trendings/trending-reco.gif')" />
      <p class="waiting">Quelques instants …</p>
    </div>
    <div v-else-if="resultsLoaded && resultsFound">
      <div class="ui column text-center m-b-2">
        <button @click.prevent="resetSearch" class="ui button custom-button">
          Nouvelle recherche
        </button>
      </div>
      <results-page :results="results" :filters="filtersList" />
    </div>
    <div v-else-if="resultsLoaded && !resultsFound">
      <div class="ui column text-center m-b-2">
        <button @click.prevent="resetSearch" class="ui button custom-button">
          Nouvelle recherche
        </button>
      </div>
      <div class="ui container text-center">
        <h2 class="custom-title">Aucune recette trouvée</h2>
      </div>
    </div>
    <div class="bg-top" v-else>
      <div class="bg-top__wrap">
        <div class="h3 text-center home-title">
          <div class="lighter">
            Ajoutez des ingrédients que vous souhaitez utiliser pour trouver une
            recette
          </div>
        </div>
        <div
          class="ui fluid category search general home custom-search home-search m-t-1 focus"
          v-closable="{
            handler: 'closeMenu',
            exclude: ['results'],
          }"
        >
          <div class="ui right icon input">
            <i class="gdm-search-logo icon"></i>
            <input
              type="search"
              placeholder="Rechercher un ingrédient"
              class="prompt general home"
              autocomplete="off"
              v-model="search"
              @click="toggleMenu"
              :disabled="selectedIngredients.length >= 3"
              ref="searchInput"
            />
          </div>
          <div
            class="results transition"
            :class="{ visible: menuOpen && selectedIngredients.length < 3 }"
          >
            <virtual-list
              v-if="sortedIngredientOptions.length"
              :style="menuHeight"
              :data-key="'id'"
              :data-sources="sortedIngredientOptions"
              :data-component="itemComponent"
              :extra-props="{ addIngredient }"
            />
            <div class="ui item" v-else @click="resetIngSearch">
              <span class="result">
                <div class="content">
                  <span>Aucun ingrédient trouvé</span>
                </div>
              </span>
            </div>
          </div>
        </div>
        <div class="icookin-filter">
          <div
            class="ui item checkbox m-t-2 classic_recipes filtering validate icookin custom-checkbox d-flex justify-center"
          >
            <div class="filter-field" @click="toggleFilter('classic')">
              <input
                type="checkbox"
                :checked="filters.classic"
                :disabled="filters.classic && !filters.icookin"
              />
              <label class="m-r-2 cursor-pointer"> Recettes classiques </label>
            </div>
            <div class="filter-field" @click="toggleFilter('icookin')">
              <input
                type="checkbox"
                :checked="filters.icookin"
                :disabled="!filters.classic && filters.icookin"
              />
              <label class="cursor-pointer">Recettes I-Cook'in</label>
            </div>
          </div>
        </div>
        <div
          class="ui three column stackable doubling doubling-xs tripling grid m-t-2 m-b-2 text-center"
        >
          <div
            class="ui column ingredient-btn"
            v-for="ing in selectedIngredients"
            :key="ing.id"
          >
            <div class="ui button custom-button labeled icon">
              <i class="right icon close" @click="removeIngredient(ing)" />
              {{ ing.label }}
            </div>
          </div>
        </div>
        <div id="validate_button" class="ui column text-center m-t-2">
          <button
            class="ui button custom-button"
            :disabled="!selectedIngredients.length"
            @click.prevent="loadResults"
          >
            Valider
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'lodash.permutations'
import _ from 'lodash'
import VirtualList from 'vue-virtual-scroll-list'

import ResultsPage from './ResultsPage.vue'
import IngredientItem from './IngredientItem.vue'

export default {
  components: {
    ResultsPage,
    VirtualList,
  },
  data() {
    return {
      ingredientsList: $('#ingredients-data').data('ingredients'),
      selectedIngredients: [],
      menuOpen: false,
      search: null,
      resultsLoading: false,
      resultsLoaded: false,
      results: {},
      itemComponent: IngredientItem,
      filters: {
        icookin: true,
        classic: true,
      },
    }
  },
  methods: {
    resetSearch() {
      this.menuOpen = false
      this.resultsLoaded = false
      this.selectedIngredients = []
      this.results = {}
    },
    toggleFilter(filter) {
      if (
        (this.filters.icookin && this.filters.classic) ||
        !this.filters[filter]
      ) {
        this.filters[filter] = !this.filters[filter]
      }
    },
    resetIngSearch() {
      this.search = null
      this.menuOpen = true
      this.$refs.searchInput.focus()
    },
    toggleMenu() {
      if (this.menuOpen) {
        this.closeMenu()
      } else {
        this.menuOpen = true
      }
    },
    closeMenu() {
      this.search = null
      this.menuOpen = false
      this.$refs.searchInput.blur()
    },
    addIngredient(ing) {
      if (this.selectedIngredients.length < 3) {
        this.selectedIngredients.push(ing)
        this.$refs.searchInput.focus()
        this.closeMenu()
      }
    },
    removeIngredient(ing) {
      this.selectedIngredients = this.selectedIngredients.filter(
        (i) => i.id !== ing.id
      )
    },
    async loadResults() {
      this.resultsLoading = true

      for (let ids of this.ingredientCombinations) {
        const labels = ids.map((id) => {
          return this.selectedIngredients.find((ing) => ing.id === id).label
        })
        const ingredientFilter = `ingredients=${labels.join(',')}`

        const recipes = await this.$http.get(
          `/recettes?embed=4&${[ingredientFilter, ...this.filtersList].join(
            '&'
          )}`,
          {
            headers: { Accept: 'text/html' },
          }
        )
        this.results[labels.join(', ')] = recipes.data
      }

      this.resultsLoading = false
      this.resultsLoaded = true
    },
  },
  computed: {
    resultsFound() {
      if (this.resultsLoaded) {
        return Object.values(this.results).filter((r) => r.total_count).length
      } else {
        return false
      }
    },
    icookableFilter() {
      if (!this.filters.icookin || !this.filters.classic) {
        return `is_icookable=${this.filters.icookin ? 'true' : 'false'}`
      } else {
        return null
      }
    },
    filtersList() {
      return ['order=created_at desc', this.icookableFilter].filter(Boolean)
    },
    menuHeight() {
      if (this.ingredientOptions.length <= 8) {
        const height = this.ingredientOptions.length * 40
        return `height: ${height}px; overflow-y: auto`
      } else {
        return 'height: 320px; overflow-y: auto'
      }
    },
    ingredientCombinations() {
      let permutations = _.flatMap(
        this.selectedIngredients.map((i) => i.id),
        (v, i, a) => _.permutations(a, i + 1)
      )
        .map((i) => i.sort())
        .map(JSON.stringify)
      let uniquePermutations = [...new Set(permutations)]
      return Array.from(uniquePermutations, JSON.parse).reverse()
    },
    ingredientOptions() {
      if (this.selectedIngredients.length >= 3) {
        return []
      }
      if (this.search) {
        const regex = new RegExp(
          this.search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'),
          'i'
        )
        return this.ingredientsList.filter((ing) => {
          return (
            (ing.label.match(regex) ||
              ing.label.replace(/\W/g, '').match(regex)) &&
            !this.selectedIngredients.map((i) => i.id).includes(ing.id)
          )
        })
      } else {
        return this.ingredientsList.filter((ing) => {
          return !this.selectedIngredients.map((i) => i.id).includes(ing.id)
        })
      }
    },
    sortedIngredientOptions() {
      return [
        ...this.ingredientOptions.filter((ing) => !ing.parent_id),
        ...this.ingredientOptions.filter((ing) => ing.parent_id),
      ]
    },
  },
  mounted() {
    let bannerIdMobile = $("#banner-data-mobile").data('banner-id')
    let bannerIdDesktop = $("#banner-data-desktop").data('banner-id')

    $(`#${bannerIdMobile}`).appendTo("#validate_button")
    $(`#${bannerIdDesktop}`).appendTo("#validate_button")
  }
}
</script>

<style lang="scss" scoped>
.bg-top {
  position: unset;
  max-height: none;
  min-height: none;
  .bg-top__wrap {
    position: unset;
    top: 0;
    left: 0;
    transform: unset;
    margin-top: 15px;
  }
}
.home-title {
  color: black;
  .lighter {
    font-size: 1.5em;
  }
}
.ingredient-btn {
  z-index: 100;
}
.results {
  overflow-y: hidden !important;
  max-height: none !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
</style>
